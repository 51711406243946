import { SchoolView } from "src/types/school.type";
import { Nullable } from "src/utils";
import { SchoolSeachQuery } from ".";

export enum SCHOOL_SEARCH {
    REQUEST = "SCHOOL_SEARCH_REQUEST",
    SUCCESS = "SCHOOL_SEARCH_SUCCESS",
    FAILURE = "SCHOOL_SEARCH_FAILURE",
    ERROR = "SCHOOL_SEARCH_ERROR",
}

export interface School_SearchState {
    loading: boolean;
    data?: SchoolView[];
    error?: Nullable<string>;
    query?: SchoolSeachQuery;
}

export interface School_SearchActionType {
    type: SCHOOL_SEARCH;
    payload: School_SearchState;
}
