import { Nullable } from "src/utils";

export enum SIGNUP {
    REQUEST = "SIGNUP_REQUEST",
    SUCCESS = "SIGNUP_SUCCESS",
    FAILURE = "SIGNUP_FAILURE",
    ERROR = "SIGNUP_ERROR",
    CLEAN = "SIGNUP_CLEAN",
}

export interface SignupState {
    loading: boolean;
    data?: any;
    error?: Nullable<string>;
    query?: Nullable<string>;
}

export interface SignupActionType {
    type: SIGNUP;
    payload: SignupState;
}
