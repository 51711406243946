import { LOGIN, LoginActionType } from "./types";

export const requestLogin = (query: any): LoginActionType => ({
    type: LOGIN.REQUEST,
    payload: {
        loading: true,
        query: query,
        error: null,
    },
});

export const receiveLoginSuccess = (login: any): LoginActionType => ({
    type: LOGIN.SUCCESS,
    payload: {
        loading: false,
        query: null,
        data: login,
        error: null,
    },
});

export const receiveLoginFailure = (error: any): LoginActionType => ({
    type: LOGIN.FAILURE,
    payload: {
        loading: false,
        query: null,
        data: error,
        error: error.error,
    },
});

export const cleanLogin = (): LoginActionType => ({
    type: LOGIN.CLEAN,
    payload: { loading: false, data: {} },
});
