import React from "react";
import styled from "styled-components";
import intl from "react-intl-universal";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { Row, Col } from "antd";
import { ModuleContainer } from "src/components/base/ModuleContainer";
import { HomeBackground1, ChatGPT4Banner, HomePageDot } from "src/static";
import { Spacer } from "src/components/base";
import { ListingMenuButtonFull, FullButtonIcon } from "src/components/Shared/ListingMenuButton";
import { FaSearch } from "react-icons/fa";

// to-do: add back later on
// enum SearchType {
//     Discover = "discover",
//     Sell = "sell",
//     School = "school",
//     NewDevelopment = "new-development",
//     Condo = "condo",
//     Assignment = "assignment",
// }

const Hero = () => {
    const bp = useBreakpoint();
    // to-do: add back later on
    // const history = useHistory();
    // const [searchType] = useState(SearchType.NewDevelopment);

    const handleClick = () => {
        // temporary for compliance
        // window.open("https://chat.openai.com/g/g-6ti6EdWay-manoragent", "_blank");
        window.open("/chatv2");
    };

    const handleAISearchClick = () => {
        window.open("/chatv2");
    };

    return (
        <>
            <ModuleContainer
                backgroundStyle={{
                    backgroundImage: `url(${HomeBackground1})`,
                    backgroundSize: "100% 100%",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: bp.lg ? `calc(432px - 50vw) 100%` : "",
                }}
                containerStyle={{ padding: `140px 16px ${bp.lg ? "140px" : "0px"} 16px` }}
            >
                <Row justify="space-between" align="middle">
                    <Col xs={24} sm={24} md={24} lg={10}>
                        <TitleContainer>
                            <Title bp={bp}>
                                {intl.get("homePage.hero.aisearch-title1")}
                                <br />
                                {intl.get("homePage.hero.aisearch-title2")}
                                <GoldText>{intl.get("homePage.hero.aisearch-title3")}</GoldText>
                            </Title>
                            <Spacer height={16} />
                            <Subtitle bp={bp}>
                                {intl.get("homePage.hero.aisearch-subtitle1")}
                                <b>{intl.get("homePage.hero.aisearch-subtitle2")}</b>
                                {intl.get("homePage.hero.aisearch-subtitle3")}
                            </Subtitle>
                            <Spacer height={16} />
                            {/* <ListingMenuButtonFull onClick={handleClick} style={{ height: "42px" }}>
                                {intl.get("homePage.hero.aisearch-button")}
                            </ListingMenuButtonFull> */}
                            <StyledFullButton onClick={handleAISearchClick}>
                                Explore with ManorAI
                                <FullButtonIcon>
                                    <FaSearch />
                                </FullButtonIcon>
                            </StyledFullButton>
                            <RegisteredText>
                                (Registered Users Only)
                            </RegisteredText>
                            {/* To-do: Add back if suitable */}
                            {/* <SearchBarTypeSelect
                                bp={bp}
                                active={searchType === SearchType.NewDevelopment}
                                onClick={() => setSearchType(SearchType.NewDevelopment)}
                            >
                            </SearchBarTypeSelect>
                            <SearchBarTypeSelect
                                bp={bp}
                                active={searchType === SearchType.Discover}
                                onClick={() => setSearchType(SearchType.Discover)}
                            >
                            </SearchBarTypeSelect>
                            <SearchBarTypeSelect
                                bp={bp}
                                active={searchType === SearchType.Discover}
                                onClick={() => setSearchType(SearchType.Discover)}
                            >
                                {intl.get("homePage.hero.homeSearch")}
                            </SearchBarTypeSelect>
                            <SearchBarTypeSelect
                                bp={bp}
                                active={searchType === SearchType.Condo}
                                onClick={() => setSearchType(SearchType.Condo)}
                            >
                                {intl.get("homePage.hero.condoSearch")}
                            </SearchBarTypeSelect>
                            <SearchBarTypeSelect
                                bp={bp}
                                active={searchType === SearchType.Assignment}
                                onClick={() => setSearchType(SearchType.Assignment)}
                            >
                                {intl.get("homePage.hero.assignmentSearch")}
                            </SearchBarTypeSelect>
                            <SearchBarTypeSelect
                                    bp={bp}
                                    active={searchType === SearchType.School}
                                    onClick={() => setSearchType(SearchType.School)}
                                >
                                    {intl.get("homePage.hero.schoolSearch")}
                            </SearchBarTypeSelect>
                            {searchType === SearchType.Discover && (
                                <StyledSearchBar renderType="listing" />
                            )}
                            {searchType === SearchType.Sell && <StyledSearchBar />}
                            {ENABLE_SCHOOL && searchType === SearchType.School && (
                                <SchoolSearchBar />
                            )}
                            {searchType === SearchType.NewDevelopment && (
                                <StyledSearchBar renderType="development" />
                            )}
                            {searchType === SearchType.Condo && (
                                <StyledSearchBar renderType="condo" />
                            )}
                            {searchType === SearchType.Assignment && (
                                <StyledSearchBar renderType="assignment" />
                            )} */}
                        </TitleContainer>
                    </Col>
                    <Col xs={0} sm={0} md={0} lg={12} style={{ zIndex: 1 }}>
                        <StyledImg
                            src={ChatGPT4Banner}
                            alt="gif of ManorGPT feature"
                            onClick={handleClick}
                        />
                        <StyledDot src={HomePageDot} alt="" />
                    </Col>
                    <Col xs={24} sm={24} lg={0}>
                        <MobileImg
                            src={ChatGPT4Banner}
                            alt="gif of ManorGPT feature"
                            onClick={handleClick}
                        />
                    </Col>
                </Row>
            </ModuleContainer>
        </>
    );
};

export default Hero;

// const StyledSearchBar = styled(SearchBar)`
//     max-width: 700px;
// `;

// const SearchBarTypeSelect = styled.div`
//     cursor: pointer;
//     font-family: ${({ theme }) => theme.fontFamily.homeBody};
//     font-size: ${(props: { active: boolean; bp; theme }) =>
//         props.bp.xs ? `${props.theme.fontSize.bodyS}px` : `${props.theme.fontSize.bodyM}px`};
//     color: ${(props: { active: boolean; theme }) =>
//         props.active ? `${props.theme.color.blackPrimaryL}` : `#8D9AAE`};
//     margin: ${(props: { bp }) => (props.bp.xs ? "24px 8px" : "24px 16px")};
//     display: inline-block;
//     position: relative;

//     ${(props: { active: boolean; theme }) =>
//         props.active
//             ? `:after {
//         content: "";
//         position: absolute;
//         width: 100%;
//         height: 3px;
//         top: 24px;
//         left: 0;
//         background: ${props.theme.color.manorleadGold};
//     }`
//             : ""};
// `;

const TitleContainer = styled.div`
    width: 100%;
`;

const Title = styled.div`
    font-family: ${({ theme }) => theme.fontFamily.homeHeading};
    ${({ theme }) => theme.styleGroup.heading}
    font-size: ${(props: { bp; theme }) =>
        props.bp.xs ? `${props.theme.fontSize.h2}px` : `${props.theme.fontSize.h1}px`};
    line-height: 108%;
    color: ${({ theme }) => theme.color.blackPrimary};
    text-align: inherit;
`;

const Subtitle = styled.p`
    font-family: ${({ theme }) => theme.fontFamily.homeBody};
    font-weight: 400;
    color: ${({ theme }) => theme.color.blackPrimary};
    font-size: ${(props: { bp }) => (props.bp.lg || props.bp.xl ? `15px` : `13px`)};
`;

const GoldText = styled.span`
    position: relative;
    color: ${({ theme }) => theme.color.manorleadGold};
`;

const StyledImg = styled.img`
    object-fit: contain;
    width: 100%;
    cursor: pointer;
`;

const MobileImg = styled(StyledImg)`
    padding-top: 64px;
    width: 110%;
    transform: translateX(-5%);
`;

const StyledDot = styled(StyledImg)`
    position: absolute;
    height: 168px;
    top: -72px;
    left: 20%;
    z-index: -1;
    cursor: auto;
`;

const StyledFullButton = styled(ListingMenuButtonFull)`
    background-color: ${({ theme }) => theme.color.manorleadGold};
    color: ${({ theme }) => theme.color.greySecondaryD};
    height: 42px;

    :hover,
    :focus {
        background-color: ${({ theme }) => theme.color.manorleadGold};
        color: ${({ theme }) => theme.color.greySecondaryD};
        filter: brightness(95%);
    }
`;

const RegisteredText = styled.div`
    font-style: italic;
    color: ${({ theme }) => theme.color.greySecondaryM};
    font-size: 14px;
    margin: 5px 0 0 10px;

`;