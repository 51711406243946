import { Col, Row } from "antd";
import React, { Fragment } from "react";
import styled from "styled-components";
import intl from "react-intl-universal";
import { Line } from "src/components/base/Line";
import { Spacer } from "src/components/base";
import { HomePageContentWrapper } from "../Shared";
import { getStats } from "./StatsSection";
import { GroupPic } from "src/static";

export const MobileStatsSection = () => {
    return (
        <HomePageContentWrapper>
            <Row align="middle" gutter={[0, 40]}>
                <Col span={24}>
                    <Line />
                    <Spacer height={16} />
                    <Row justify="space-between" align="middle">
                        <Title>{intl.get("homePage.stats.title")}</Title>
                        <Spacer height={16} />
                    </Row>
                    <Spacer height={16} />
                    <Row align="middle" justify="space-around">
                        {getStats().map((stat, i) => (
                            <Fragment key={stat}>
                                <div>
                                    <PointTitle>
                                        {intl.get(`homePage.stats.${stat}Stat`)}
                                    </PointTitle>
                                    <PointStat>
                                        {intl.get(`homePage.stats.${stat}Title1`)}
                                    </PointStat>
                                </div>
                                {i < 2 && <StyledVtlDivider />}
                            </Fragment>
                        ))}
                    </Row>
                </Col>
                <Col span={24}>
                    <StyledImg src={GroupPic} alt="" />
                </Col>
            </Row>
        </HomePageContentWrapper>
    );
};

const StyledVtlDivider = styled.div`
    border: 1px solid #dce0f3;
    height: 70px;
`;

const Title = styled.div`
    font-family: ${({ theme }) => theme.fontFamily.homeHeading};
    ${({ theme }) => theme.styleGroup.heading}
    line-height: 1;
    font-size: 48px;
`;

const PointTitle = styled.div`
    font-family: ${({ theme }) => theme.fontFamily.homeHeading};
    ${({ theme }) => theme.styleGroup.body}
    font-weight: 500;
    font-size: ${({ theme }) => theme.fontSize.h2}px;
`;

const PointStat = styled(PointTitle)`
    font-family: ${({ theme }) => theme.fontFamily.homeBody};
    font-size: ${({ theme }) => theme.fontSize.bodyS}px;
`;

const StyledImg = styled.img`
    object-fit: contain;
    max-height: 700px;
    width: 100%;
`;
