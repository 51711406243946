import React from "react";
import styled from "styled-components";
import { Flex } from "..";
import BasicButton from "./BasicButton";

export type TextButtonProps = { text: string; button: string; handlePress: (value: any) => void };

const TextButton: React.FC<TextButtonProps> = ({ text, button, handlePress }) => {
    return (
        <Flex wrap="wrap" align="center" justify="center">
            <Text> {text} </Text>
            <BasicButton type="link" text={button} handlePress={handlePress} />
        </Flex>
    );
};

export default TextButton;

const Text = styled.p`
    font-family: ${({ theme }) => theme.fontFamily.body};
    font-style: normal;
    ${({ theme }) => theme.styleGroup.button};
    font-size: ${({ theme }) => theme.fontSize.button}px;
`;
