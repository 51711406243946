import { client } from "src/services/client";
import { ContactUsQuery } from "./types";

export const fetchContactUs = async (query: ContactUsQuery) =>
    client({
        method: "post",
        url: `/contact/us`,
        headers: { "Content-Type": "application/json" },
        data: query,
    }).then((response) => response.data);
