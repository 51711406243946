import { COMMUNITY, CommunityActionType } from "./types";

export const requestCommunity = (): CommunityActionType => ({
    type: COMMUNITY.REQUEST,
    payload: {
        loading: true,
        error: null,
    },
});

export const receiveCommunitySuccess = (community: any): CommunityActionType => ({
    type: COMMUNITY.SUCCESS,
    payload: {
        loading: false,
        query: null,
        data: community,
        error: null,
    },
});

export const receiveCommunityFailure = (error: string): CommunityActionType => ({
    type: COMMUNITY.FAILURE,
    payload: {
        loading: false,
        query: null,
        data: null,
        error: error,
    },
});
