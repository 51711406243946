import { AUTH_TITLE } from "./constants";
import intl from "react-intl-universal";
import moment from "moment-timezone";
import { removeToken } from "src/services/client";
import { client, setToken } from "src/services/client";
import { loadState, saveState } from "src/redux/store/localStorage";
import { WHITELIST } from "./constants";
import { DevelopmentView } from "src/types/listing.type";
import { History, Location } from "history";
import { EST_TIME_ZONE, offsetDayLightSaving, FORMAT_DATE } from "./time";
import { HOME_PAGE_URL } from "./uri";
import { Breakpoint } from "antd/lib/_util/responsiveObserve";
import { returnProvinceShortForm } from "src/utils/canadianProvinces";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

const MS_IN_A_DAY = 86400000;
const COMMA_SEPARATOR_FOR_NUMBER_REGEX = /\B(?=(\d{3})+(?!\d))/g;
export const SQFT_TO_SQM_RATIO = 0.092903;

const COUNTY_MAP = {
    "AB": "Alberta",
    "BC": "British Columbia",
    "MB": "Manitoba",
    "NB": "New Brunswick",
    "NL": "Newfoundland and Labrador",
    "NT": "Northwest Territories",
    "NS": "Nova Scotia",
    "NU": "Nunavut",
    "ON": "Ontario",
    "PE": "Prince Edward Island",
    "QC": "Quebec",
    "SK": "Saskatchewan",
    "YT": "Yukon"
}

export const authTitle = (title: number) => {
    switch (title) {
        case AUTH_TITLE.LOGIN:
            return intl.get("authentication.shared.login");
        case AUTH_TITLE.SIGNUP:
            return intl.get("authentication.signup.title");
        case AUTH_TITLE.VERIFICATION:
            return intl.get("authentication.signup.signedUp");
        case AUTH_TITLE.FORGOT:
            return intl.get("authentication.shared.forgotPassword");
        case AUTH_TITLE.RESET:
            return intl.get("authentication.forgotPassword.resetPassword");
        case AUTH_TITLE.SIGNUPINFO:
            return intl.get("authentication.shared.almostDone");
        case AUTH_TITLE.SUCCESS_RESET:
            return intl.get("authentication.success.resetPW.title");
        case AUTH_TITLE.SUCCESS_SIGNUP:
            return intl.get("authentication.success.signup.title");
        case AUTH_TITLE.LOGIN_OR_REGISTER:
            return intl.get("authentication.shared.registerOrSignIn");
        default:
            return intl.get("authentication.shared.login");
    }
};

export const parsePrice = (price: string | number): string =>
    price ? `${price.toString().replace(COMMA_SEPARATOR_FOR_NUMBER_REGEX, ",")}` : "0";

export const parseShortenedPrice = (price: number): string => {
    const num = price / 1000;
    if (num >= 1000) {
        return `$${(num / 1000).toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            minimumSignificantDigits: 1,
            maximumSignificantDigits: 3,
        })}m`;
    } else if (num === 0) {
        return `-`;
    } else {
        return `$${num.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            minimumSignificantDigits: 1,
            maximumSignificantDigits: 3,
        })}k`;
    }
};

export const convertPriceDigitToX = (priceToFormat: string | number): string => {
    return parsePrice(priceToFormat).replace(new RegExp("[0-9]", "g"), "X");
};

export const displayAddress = (
    address: string,
    unitNumber: number | string,
    city: string,
    postalCode: string,
    province?: string,
) =>
    unitNumber
        ? `${unitNumber} - ${capitalize(address)}, ${city}, ${postalCode}`
        : `${address}, ${city}, ${
              province ? returnProvinceShortForm(province) : "ON"
          } ${postalCode}`; // temporary, delete once expanded to other provinces/states

export const capitalize = (str: any, lower = false) =>
    (lower ? str?.toLowerCase() : str)?.replace(/(?:^|\s|["'([{])+\S/g, (match) =>
        match.toUpperCase(),
    );

export const formatIconPrice = (p: string | number, isLeased = false) => {
    let num = Number(p);
    if (num >= 1000 && num < 1000000) {
        if (isLeased) return "$" + num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        const roundedNum = Math.round(num / 100) / 10;
        return "$" + roundedNum + "k";
    } else if (num >= 1000000) {
        const roundedNum = Math.round(num / 100000) / 10;
        return "$" + roundedNum + "m";
    } else {
        return num.toString();
    }
};

export const formatIconPriceLeaseOrSale = (p: string | number, leaseOrSale: string) => {
    let price = Number(p);

    const formatNumberAsCurrency = (num: number) => {
        const decimalPart = num % 1;
        const fractionDigits = decimalPart === 0 ? 0 : 2;
        const fixedNum = num.toFixed(fractionDigits);

        const parts = fixedNum.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
    };

    if (leaseOrSale === "Lease") {
        return `$${formatNumberAsCurrency(price)}`;
    } else {
        if (price < 1000000) {
            return `$${Math.trunc(Math.max(price / 1000, 1))}k`;
        } else {
            return `$${Math.round(Math.max(1, price / 1000000) * 100) / 100}M`;
        }
    }
};

export const displayTaxes = (taxes: number) =>
    taxes === (0 || null || undefined) ? "not available" : `$ ${(taxes / 12).toFixed(2)}`;

export const displayPrinicipalInterest = (taxes: number, monthlyPayment: number) =>
    taxes === (0 || null || undefined)
        ? `$ ${monthlyPayment}`
        : `$ ${(monthlyPayment - taxes / 12).toFixed(2)}`;

export const expired = (loginResponse: any, userInfoResponse: any) => {
    let isExpired =
        !loginResponse.cognitoJWT ||
        Date.now() > loginResponse.expiry ||
        userInfoResponse.message?.includes("Expired JWT") === true;
    if (isExpired) {
        removeToken();
    }

    const refreshToken = localStorage.getItem("refresh_token");
    // fetch a new access_token iff the current one is expired and has refresh_token
    if (isExpired && refreshToken) {
        client.post("/auth/refresh", { refreshToken: refreshToken }).then((res) => {
            let state = loadState();
            if (!state) {
                state = {};
            }
            if (!state.login) {
                state.login = {};
            }
            if (!state.settings) {
                state.settings = {};
                state.settings.login = {};
            }
            if (!state.settings.login) {
                state.settings.login = {};
            }
            state.settings.login.loginPopup = false;
            state.login.data = res.data;
            state.login.data.expiry = Date.now() + res.data.cognitoJWT.expires_in * 1000;
            saveState(state);
            setToken(res.data.cognitoJWT.access_token);
            isExpired = false;
            window.location.reload();
        });
    }
    return isExpired;
};

export const splitPhoneFromPrefix = (phone?: string): { phone: string; prefix: string } => {
    if (phone?.startsWith("+1")) return { phone: phone.slice(2), prefix: "+1" };
    else if (phone?.startsWith("+86")) return { phone: phone.slice(3), prefix: "+86" };
    else return { phone: phone || "", prefix: "+1" };
};

export const addPostfixToNumber = (
    num: Number | undefined,
    postfix: string,
    countable: boolean = false,
    zeroString: string = "-",
    nullString: string = "N/A",
): string => {
    if (num === 0) {
        return zeroString;
    } else if (!num) {
        return nullString;
    } else if (!countable || num === 1) {
        return `${num} ${postfix}`;
    }
    return `${num} ${postfix}s`;
};

export const displayNewDevBeds = (beds?: number) => {
    return addPostfixToNumber(beds, " Bed", true, "Studio");
};

export const displayBedsForDevCard = (beds?: number) => {
    if (beds === 0) {
        return "Studio";
    } else if (!beds) {
        return "N/A";
    } else if (beds > 0) {
        if (beds > 1) return `${beds} Beds`;
        return `${beds} Bed`;
    }
    return "N/A";
};

export const displayBedsSuffixBR = (
    beds: number,
    suffix: string = "BR",
    plural: boolean = false,
) => {
    return addPostfixToNumber(beds, suffix, plural, "Studio");
};

export const calculatePerSqft = (totalSqft?: number, totalPrice?: number): string | undefined => {
    if (totalPrice && totalSqft) {
        return `$${parsePrice((totalPrice / totalSqft).toFixed())}/sqft`;
    }

    return;
};

/*
 sqft to sqm is SQFT_TO_SQM_RATIO
*/
export const calculatePerSqM = (totalSqft?: number, totalPrice?: number): string | undefined => {
    if (totalPrice && totalSqft) {
        return `$${parsePrice((totalPrice / SQFT_TO_SQM_RATIO / totalSqft).toFixed(2))}/`;
    }
    return;
};

export const pathInBlockerWhiteList = (pathname: string) => {
    const includeInURL = WHITELIST.find((list) => pathname.includes(list));
    if (includeInURL || pathname === HOME_PAGE_URL) {
        return true;
    }
    return false;
};

export const buildDevUrl = (card: DevelopmentView) => {
    const municipality = card?.municipality ? `${card.municipality}-` : "";
    const province = card?.province ? `${card.province}-` : "";
    let url = `/development/${card.id}/${card.name}-${card.address}-${municipality}${province}`;
    url = url.slice(0, -1);
    return url.split(" ").join("-");
};

export const parseBedsRange = (minBed?: number, maxBed?: number) => {
    const minBedString = intl.get("listing.banner.bedrooms", { num: minBed });
    const maxBedString = intl.get("listing.banner.bedrooms", { num: maxBed });

    if (minBedString === "N/A" && maxBedString === "N/A") {
        return intl.get("development.shared.plans") + intl.get("development.shared.comingSoon");
    } else if (minBedString === "N/A") {
        return `${intl.get("development.shared.upTo")} ${maxBedString}`;
    } else if (maxBedString === "N/A") {
        return `${intl.get("development.shared.from1")} ${minBedString} ${intl.get(
            "development.shared.from2",
        )}`;
    } else if (maxBedString === minBedString) {
        return maxBedString;
    } else {
        return `${minBedString} ${intl.get("development.shared.to")} ${maxBedString}`;
    }
};

/**
 *  - URL RELATED HELPERS
 *     - paramToSearch: produce formatted url from params dict, no url change
 *     - addStateToUrl: keep old url while add new state to queue (will not work for duplicate keys)
 *     - overrideUrlFromState: re-build and override entire URL by appending states to url from stateQueue
 *     - getParamValueFromKey: return list of values for a spceific key in url
 *     - removeSearchParam: remove all keys from url
 *     - UpdateBoundaryFromUrl: update lat lng from url (cannot be replaced by addStateToUrl)
 *     - mapCoordToBound: produce bbox generated from url
 *
 *  Why do we need both overrideUrlFromState and addStateToUrl?
 *  - addStateToUrl use URLSearchParams.set method will will remove all keys if present. This
 *    will not work in case of multi-selection where we might have key=v1&key=v2. Since stateQueue
 *    stores all the possible states including duplicate keys, in case of a queue change (remove/add)
 *    we will simply rebuild the entire url from queue while preserving boundary related information
 *
 *    TODO: we can remove addStateToUrl once residential map stats using stateQueue instead of appstate
 */

export const paramToSearch = (params: { [key: string]: any }) => {
    const query = new URLSearchParams();
    Object.keys(params).forEach((key) => {
        query.set(key, params[key]);
    });
    return query.toString();
};

export const addStateToUrl = (stateQueue: string[], history: History, location: Location): void => {
    const searchquery = new URLSearchParams(window.location.search);
    stateQueue.forEach((state) => {
        const statePair = state.split("=", 2);
        if (searchquery.get(statePair[0]) !== statePair[1]) {
            searchquery.set(statePair[0], statePair[1]);
        }
    });
    history.replace({ pathname: location.pathname, search: searchquery.toString() });
};

export const appendStateToUrl = (
    stateQueue: string[],
    history: History,
    location: Location,
): void => {
    const searchquery = new URLSearchParams(window.location.search);
    stateQueue.forEach((state) => {
        const statePair = state.split("=", 2);
        if (searchquery.get(statePair[0]) !== statePair[1]) {
            searchquery.append(statePair[0], statePair[1]);
        }
    });
    history.replace({ pathname: location.pathname, search: searchquery.toString() });
};

// Do not override for map boundary related information
export const overrideUrlFromState = (stateQueue: string[]): string => {
    const searchquery = new URLSearchParams(window.location.search);
    const newSearchquery = new URLSearchParams("");
    // Preserve boundary related query, delete if key is not present in stateQueue
    searchquery.forEach((value, key) => {
        if (
            [
                "latitude",
                "longitude",
                "centerX",
                "centerY",
                "clusters",
                "zoom",
                "orderBy",
                "offset",
                "leaseOrSale",
            ].includes(key)
        ) {
            newSearchquery.append(key, value);
        }
    });
    stateQueue.forEach((state) => {
        const stateInList = state.split("=", 2);
        newSearchquery.append(stateInList[0], stateInList[1]);
    });
    return newSearchquery.toString();
};

export const getValuefromUrlKey = (key: string): string[] => {
    return new URLSearchParams(window.location.search).getAll(key);
};

export const removeSearchParam = (keys: string[], history: History, location: Location) => {
    const searchquery = new URLSearchParams(window.location.search);
    keys.forEach((key) => searchquery.delete(key));
    history.replace({ pathname: location.pathname, search: searchquery.toString() });
};

/*
 * Works when updating map boundary together with additional keys
 */
export const UpdateBoundaryFromUrl = (
    stateList: string[],
    history: History,
    location: Location,
    keepCluster: boolean = false,
) => {
    const searchquery = new URLSearchParams(window.location.search);
    // Remove boundary related query
    // searchquery.delete("centerX");
    // searchquery.delete("centerY");

    /* 
        by default cluster would be deleted from state 
        but can choose to keep it via passing boolean keepCluster param
    */
    if (!keepCluster) searchquery.delete("clusters");
    searchquery.delete("latitude");
    searchquery.delete("longitude");
    stateList.forEach((b) => searchquery.append(b.split("=")[0], b.split("=")[1]));

    history.replace({ pathname: location.pathname, search: searchquery.toString() });
};

/**
 * Accepted Filters must be in pattern "key=value"
 */
export const parseFilterSplitByColon = (target: unknown): string[] | null => {
    if (typeof target === "string") {
        return target.split("=", 2);
    }
    return null;
};

export const mapCoordToBound = (): [[number, number], [number, number]] => {
    let bbox: [[number, number], [number, number]] = [
        [-79.63633634179439, 43.59189870666589],
        [-79.17113401025142, 43.805612524533046],
    ];
    const url = new URLSearchParams(window.location.search);
    if (url.has("latitude") && url.has("longitude")) {
        url.forEach((value, key) => {
            const v: number = parseFloat(value.split(":")[1]);
            if (key === "longitude" && value.includes("gte:")) bbox[0][0] = v;
            if (key === "longitude" && value.includes("lte:")) bbox[1][0] = v;
            if (key === "latitude" && value.includes("gte:")) bbox[0][1] = v;
            if (key === "latitude" && value.includes("lte:")) bbox[1][1] = v;
        });
    }
    return bbox;
};

/**
 * Queue Related Helpers: queue is list of "key=value" strings
 */

export const isExistInQueue = (queue: string[], target: string): boolean => {
    return queue.filter((v) => v === target).length > 0;
};

export const enqueue = (queue: string[], item: string) => {
    if (isExistInQueue(queue, item)) return queue;
    return queue.concat(item);
};

export const removeByValue = (queue: string[], target: string) => {
    return queue.filter((v) => v !== target);
};

export const removeByKeyOnly = (queue: string[], target: string) => {
    return queue.filter((v) => v.split("=")[0] !== target);
};

export const parseDate = (timestamp: number) => {
    return moment(timestamp).format("MMM DD, YYYY");
};

export const daysTillNow = (time: string) => {
    if (time === "X") {
        return time;
    }
    // make browser treat time as EST time
    const pastTime = new Date(time + " EST");
    return Math.round((Date.now() - pastTime.getTime()) / MS_IN_A_DAY);
};

export const displaySqft = (min: number, max?: number): string => {
    let ret = "";
    if (!min && !max) {
        return intl.get("listing.banner.na");
    }
    if (min >= 5000) {
        return intl.get("listing.banner.sqftStr", { num: min });
    }
    if (min > 1000) {
        ret = ret + (min / 1000).toFixed(0) + "K";
    } else {
        ret = ret + min;
    }

    if (max) {
        ret += "-";
        if (max > 1000) {
            ret = ret + (max / 1000).toFixed(0) + "K";
        } else {
            ret = ret + max;
        }
    }

    return intl.get("listing.banner.sqftStr", { num: ret });
};

export const displayFullSqft = (min: number, max?: number): string => {
    let ret = "";
    if (!min && !max) {
        return intl.get("listing.banner.na");
    }
    if (min >= 5000) {
        return intl.get("listing.banner.sqftStr", { num: min });
    }
    if (min > 1000) {
        ret += min.toLocaleString();
    } else {
        ret += min;
    }

    if (max) {
        ret += "-";
        if (max > 1000) {
            ret += max.toLocaleString();
        } else {
            ret += max;
        }
    }

    return intl.get("listing.banner.sqftStr", { num: ret });
};

export const parseBeds = (
    beds: number,
    extraBeds: number,
    bp?: Partial<Record<Breakpoint, boolean>>,
): string => {
    if (bp && !bp.md) {
        const extraBedsString = extraBeds > 0 ? "+" + extraBeds : "";
        return `${beds}${extraBedsString}`;
    }

    if (beds === 0) {
        // no beds
        return "Studio";
    } else if (!beds) {
        return "N/A";
    } else if (extraBeds > 0) {
        // beds > 0 and extraBeds > 0
        return beds + "+" + extraBeds + " ";
    } else if (beds === 1) {
        // beds == 1 and extraBeds == 0
        return beds + " ";
    } else {
        // beds > 1 and extraBeds == 0
        return beds + " ";
    }
};

export const parseBaths = (baths: number): string => {
    return addPostfixToNumber(baths, " ", true, "N/A");
};

export const formatTargetDate = (days: number) => {
    const utcStartingTime = Date.now() + MS_IN_A_DAY * days;
    return offsetDayLightSaving(moment(utcStartingTime)).tz(EST_TIME_ZONE).format(FORMAT_DATE);
};

export const StringtoPureNumber = (value: string, toInteger: boolean = false): string => {
    if (toInteger) {
        return value.replace(/[^0-9]/g, "");
    }
    return value.replace(/[^0-9.]/g, "");
};

export const autoGeneratedDescription = (
    development: DevelopmentView,
    nearbyDevelopments: DevelopmentView[],
    similarDevelopments: DevelopmentView[],
) => {
    let generatedDescription = "";
    if (development.name) {
        generatedDescription += `${development.name} `;
    }
    generatedDescription += "is a new pre-construction";
    if (development.developmentTypes.length >= 0) {
        generatedDescription += ` ${development.developmentTypes.join(", ").toLowerCase()}`;
    }
    if (development.developers.length >= 0) {
        generatedDescription += ` by ${development.developers.join(", ")}`;
    }
    if (development.address) {
        generatedDescription += ` located at ${development.address}`;
    }

    if (development.completionYear) {
        generatedDescription += ". It is scheduled to be completed in";
        if (development.completionSeason && development.completionSeason !== "null") {
            generatedDescription += ` ${development.completionSeason.toLowerCase()}`;
        }
        generatedDescription += ` ${development.completionYear}. `;
    } else {
        generatedDescription += ". Its completion year is coming soon. ";
    }

    if (development.minSqft && development.maxSqft) {
        generatedDescription += `Floorplan sizes range from ${development.minSqft} Sqft to ${development.maxSqft} Sqft. `;
    } else if (development.minSqft) {
        generatedDescription += `Floorplan sizes start from ${development.minSqft} Sqft. `;
    } else if (development.maxSqft) {
        generatedDescription += `Floorplan sizes range up to ${development.maxSqft} Sqft. `;
    } else {
        generatedDescription += "Floorplan data is coming soon. ";
    }

    if (development.minPrice && development.maxPrice) {
        generatedDescription += `Unit prices range from $${parsePrice(
            development.minPrice,
        )} to $${parsePrice(development.maxPrice)}. `;
    } else if (development.minPrice) {
        generatedDescription += `Unit prices start from $${parsePrice(development.minPrice)}. `;
    } else if (development.maxPrice) {
        generatedDescription += `Unit prices range up to $${parsePrice(development.maxPrice)}. `;
    } else {
        generatedDescription += "Unit price data is coming soon. ";
    }

    if (nearbyDevelopments.length >= 3) {
        generatedDescription += `Nearby pre-constructions include ${nearbyDevelopments[0].name}, ${nearbyDevelopments[1].name} and ${nearbyDevelopments[2].name}. `;
    }
    if (similarDevelopments.length >= 3) {
        generatedDescription += `Similar pre-constructions include ${similarDevelopments[0].name}, ${similarDevelopments[1].name} and ${similarDevelopments[2].name}.`;
    }
    return generatedDescription;
};

export const gotoGoogleLogin = (state: string) => {
    const URL = `${process.env.REACT_APP_GOOGLE_LOGIN_URL}&state=${state}`;
    window.location.href = URL;
};

export const formatListingPrice = (price: number, isSoldPrice: boolean = false): string => {
    if (price < 0 && isSoldPrice) {
        return `$XXX,XXX`;
    }
    if (!price) {
        return "-";
    } else {
        return "$" + parsePrice(price);
    }
};

export const formatAssignmentPrice = (price: number, isSoldPrice: boolean = false): string => {
    if (price === 0) {
        return intl.get("assignment.map.noPriceTag");
    }
    return formatListingPrice(price, isSoldPrice);
};

export const parseCounty = (county: string) => {
    if (county in COUNTY_MAP) {
        return COUNTY_MAP[county]
    }
    return "";
};

export const ClientOs = {
    IOS: "IOS",
    ANDROID: "ANDROID",
    NOT_IOS_ANDROID: "NOT_IOS_ANDROID", // Desktop or other phone OSs
};

export const getClientOs = () => {
    const userAgent = navigator.userAgent;
    // e.g. Opera/9.80 (Android 4.1.2; Linux; Opera Mobi/ADR-1305251841) Presto/2.11.355 Version/12.10
    if (userAgent && /Android/.test(userAgent)) return ClientOs.ANDROID;
    // e.g. Mozilla/5.0 (iPhone; CPU iPhone OS 7_1_2 like Mac OS X) AppleWebKit/537.51.2 (KHTML, like Gecko) OPiOS/10.2.0.93022 Mobile/11D257 Safari/9537.53
    if (userAgent && /iPhone|iPad|iPod/.test(userAgent)) return ClientOs.IOS;
    return ClientOs.NOT_IOS_ANDROID;
};

const POSTAL_REGEX = new RegExp(/([ABCEGHJKLMNPRSTVXY]\d)([ABCEGHJKLMNPRSTVWXYZ]\d){2}/i);

export const postalFilter = (postalCode) => {
    if (!postalCode){
        return ""
    }
    if (POSTAL_REGEX.test(postalCode.replace(/\s/g, ""))) {
        return postalCode.replace(/^(.*)(.{3})$/, "$1 $2");
    }
    return null;
};

export const getIntlOrRaw = (intlPath, raw) => {
    if (!raw) {
        return intl.get("listing.banner.na");
    }
    const fullPath = `${intlPath}.${raw.toLowerCase()}`;
    return intl.get(fullPath).d(raw);
};

export const exportToPDF = async (id: string, fileName: string) => {
    // Capture the chart using html2canvas
    const chartElement = document.getElementById(id);
    if (!chartElement) {
        console.error("Chart element not found");
        return;
    }
    const canvas = await html2canvas(chartElement);

    // Convert canvas to image data
    const imgData = canvas.toDataURL("image/png");

    // Create a new instance of jsPDF
    const pdf = new jsPDF("l", "mm", [canvas.height, canvas.width]);

    // Add image data to PDF
    pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);

    // Save the PDF
    pdf.save(`${fileName}.pdf`);
};

export function convertTimestampToDate(timestamp) {
    const date = new Date(timestamp * 1000);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
}

export const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
};

/**
 * calculate distance in KM between two lat long point
 * @param {number[]} start [latitude, Longitude]
 * @param {number[]} dest [latitude, Longitude]
 */
export const calculateLatLongToDistance = (start, dest) => {
    const yDiff = (dest[0] - start[0]) * 110.574;
    const xDiff = (dest[1] - start[1]) * 111.32 * Math.cos(start[0]);

    return Math.sqrt(yDiff ** 2 + xDiff ** 2);
};

/**
 * get the direction from start to dest
 * @param {number[]} start [latitude, Longitude]
 * @param {number[]} dest [latitude, Longitude]
 */
export const getDirection = (start, dest) => {
    const latDiff = dest[0] - start[0];
    const longDiff = dest[1] - start[1];

    if (Math.abs(latDiff) > Math.abs(longDiff)) {
        if (latDiff > 0) {
            return "North";
        } else {
            return "South";
        }
    } else {
        if (longDiff > 0) {
            return "East";
        } else {
            return "West";
        }
    }
};

export const graphNormalize = (priceData: number[]) => {
    if (priceData.filter((value) => value !== 0).length === 0) {
        return false;
    }
    return priceData.map((value, index) => {
        if (value === 0) {
            // if a point has no value, assign it the value of the nearest point that has value
            let i = 1;
            while (i < priceData.length) {
                if (index + i < priceData.length) {
                    if (priceData[index + i] !== 0) {
                        return priceData[index + i];
                    }
                } else {
                    break;
                }
                i += 1;
            }
            i = 1;
            while (i < priceData.length) {
                if (index - i >= 0) {
                    if (priceData[index - i] !== 0) {
                        return priceData[index - i];
                    }
                } else {
                    break;
                }
                i += 1;
            }
            return 0;
        } else {
            return value;
        }
    });
};

export const isJson = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};
