import { useEffect } from "react";
import { useHistory } from "react-router-dom";

function RedirectToWWW() {
  const history = useHistory();

  useEffect(() => {
    const { hostname, pathname, search } = window.location;
    if (hostname === 'manorlead.com') {
      const redirectUrl = `https://www.manorlead.com${pathname}${search}`;
      window.location.replace(redirectUrl);
    }
  }, [history]);

  return null;
}

export default RedirectToWWW;
