import React from "react";
import { Flex, Link } from "src/components";
import { FullManorleadLogo } from "src/static";
const FullLogo = () => {
    return (
        <Flex align="center">
            <Link href="/">
                <img style={{ height: "48px" }} src={FullManorleadLogo} alt="manorlead-logo" />
            </Link>
        </Flex>
    );
};

export default FullLogo;
