import React, { Component } from "react";
import FallbackPage from "./FallbackPage";

export type ErrorBoundaryState = {
    error: Error | undefined | null;
    errorStack: ErrorStackType | undefined | null;
};

export type ErrorStackType = {
    componentStack: string;
};

type onErrorTriggerProps = (error: Error, errorStack: ErrorStackType) => void;

export type ErrorBoundaryProps = {
    /**
     * A React component (i.e. 404 page) which will be displayed as a
     * fallback if error occured when rendering the wrapped children
     *
     */
    FallbackPage?: any;
    /**
     * A callback function which will be invoked if there is an error while
     * rendering the wrapped React component.
     *
     * **Note:** Errors thrown from within `onError` will be swallowed silently.
     *
     * @param {Error} error `Error` object that has been thrown
     * @param {ErrorStackType} errorStack Information about the component stack, when the error occured
     * @returns {void}
     *
     * @see **See** https://reactjs.org/docs/error-boundaries.html#componentdidcatch-parameters
     * @see **See** https://developer.mozilla.org/docs/Web/JavaScript/Reference/Global_Objects/Error
     */
    onErrorTrigger?: onErrorTriggerProps;
    /**
     * Your React component, to be wrapped with `<ErrorBoundary>` inside
     */
    children: any;
};

/**
 * Note: React team has not introduced a Hook relevant ErrorBoundary and the only possible way
 * will be through a React Component
 *
 * The `<ErrorBoundary>` component is a wrapper you will add to your component that could display
 * a fallback page in situation of an error happens ...
 *  * during rendering
 *  * in the lifycycle methods or api call
 *  * in the constructor
 *
 * ... of the whole child DOM tree
 *
 * Think `<ErrorBoundary>` like a try method and `onErrorTrigger/<FallbackPage>` like catch but for
 * a React component DOM tree. If `<FallbackPage>` further throws an error, it will not propogate
 * but will be swallowed silently.
 *
 * @see **See** https://reactjs.org/docs/error-boundaries.html
 */
export default class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    static defaultProps = {
        FallbackPage: FallbackPage,
        onErrorTrigger: () => {},
    };

    state = {
        error: null,
        errorStack: null,
    };

    componentDidCatch(error: Error, errorStack: ErrorStackType) {
        const { onErrorTrigger } = this.props;
        try {
            onErrorTrigger!.call(this, error, errorStack);
        } catch (ignoredError) {
            // Ignore caught error to prevent the ErrorBoundary component itself from throwing errors!
        }
        this.setState({ error, errorStack });
    }

    render() {
        const { error } = this.state;
        const { FallbackPage, children } = this.props;

        if (error !== null && FallbackPage !== null) {
            return <FallbackPage />;
        }

        return children;
    }
}
