import { SchoolView } from "src/types/school.type";
import { SchoolSeachQuery } from ".";
import { SCHOOL_SEARCH, School_SearchActionType } from "./types";

export const requestSchoolSearch = (query: SchoolSeachQuery): School_SearchActionType => ({
    type: SCHOOL_SEARCH.REQUEST,
    payload: {
        loading: true,
        query: query,
        error: null,
    },
});

export const receiveSchoolSearchSuccess = (listings: SchoolView[], query?: SchoolSeachQuery): School_SearchActionType => ({
    type: SCHOOL_SEARCH.SUCCESS,
    payload: {
        loading: false,
        query: query,
        data: listings,
        error: null,
    },
});

export const receiveSchoolSearchFailure = (error: string): School_SearchActionType => ({
    type: SCHOOL_SEARCH.FAILURE,
    payload: {
        loading: false,
        query: undefined,
        data: undefined,
        error: error,
    },
});
