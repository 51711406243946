import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import App from "./App";
import ReactGA from "react-ga4";
import { Provider } from "react-redux";
import { store } from "src/redux/store";
import { GlobalStyle, theme } from "src/theme";
import "./index.css";
import { ThemeProvider } from "styled-components";
import ScrollToTop from "src/components/Shared/ScrollToTop";

export const history = createBrowserHistory();

history.listen((location) => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
});

ReactDOM.render(
    <Provider store={store}>
        <GlobalStyle />
        <ThemeProvider theme={theme}>
            <Router history={history}>
                <ScrollToTop>
                    <App />
                </ScrollToTop>
            </Router>
        </ThemeProvider>
    </Provider>,
    document.getElementById("root"),
);
