import React, { useEffect } from "react";
import styled from "styled-components";
import intl from "react-intl-universal";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { ModuleContainer } from "src/components/base/ModuleContainer";
import { PageNotFoundImg } from "src/static";
import { Spacer } from "src/components";
import { Button } from "antd";
import { useHistory } from "react-router-dom";
import { SEO_IMAGE } from "src/static";
import MetaData from "src/components/Listing/MetaData";
import { setBlocker } from "src/redux/modules/Setting";
import { BLOCKER_TYPES } from "src/utils";
import { useDispatch } from "react-redux";
export type FallbackPageDefaultProps = {
    errorCode?: "404" | "5XX" | null;
};

/**
 * The `<FallbackPageDefault>` is used as a default fallback component
 * by the [`<ErrorBoundary>`](#errorboundary) component.
 *
 * @param {'404' | '5XX'} errorCode 404 or 5XX indicating error type
 */
const FallbackPage = ({ errorCode = null }: FallbackPageDefaultProps) => {
    const bp = useBreakpoint();
    const history = useHistory();
    const dispatch = useDispatch();

    let errorTitle = intl.get("errorPage.5xx.title");
    let errorText = intl.get("errorPage.5xx.description");
    if (errorCode === "404") {
        errorTitle = intl.get("errorPage.404.title");
        errorText = intl.get("errorPage.404.description");
    }

    useEffect(() => {
        dispatch(setBlocker(BLOCKER_TYPES.CLOSE));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <MetaData
                title={intl.get("title.pageNotFound")}
                description={intl.get("helmetDescription.home")}
                image={SEO_IMAGE.HOMEPAGE}
            />
            <ModuleContainer
                backgroundStyle={{
                    backgroundPosition: bp.lg ? `calc(432px - 50vw) 100%` : "auto",
                    height: "88vh",
                }}
                containerStyle={{
                    padding: `${bp.lg ? "160px" : "54px"} 16px`,
                    textAlign: "center",
                    maxWidth: "660px",
                }}
            >
                <StyledImg src={PageNotFoundImg} alt="errorImage" />
                <Spacer height={40} />
                <ErrorTitle bp={bp}>{errorTitle}</ErrorTitle>
                <Spacer height={16} />
                <ErrorDescription bp={bp}>{errorText}</ErrorDescription>
                <Spacer height={40} />
                <StyledButton type="primary" onClick={() => history.goBack()}>
                    <ErrorDescription bp={bp}>{intl.get("errorPage.goBack")}</ErrorDescription>
                </StyledButton>
            </ModuleContainer>
        </>
    );
};

const ErrorTitle = styled.div`
    font-family: ${({ theme }) => theme.fontFamily.homeHeading};
    ${({ theme }) => theme.styleGroup.heading}
    font-size: ${(props: { bp; theme }) =>
        props.bp.xs ? `${props.theme.fontSize.subtitle}px` : `${props.theme.fontSize.h1}px`};
    color: ${({ theme }) => theme.color.navy};
    text-align: center;
`;

const StyledImg = styled.img`
    max-width: 400px;
    width: 100%;
    position: relative;
    object-fit: cover;
`;

const StyledButton = styled(Button)`
    width: 260px;
    height: 64px;
`;

const ErrorDescription = styled(ErrorTitle)`
    font-size: ${({ theme }) => theme.fontSize.bodyL}px;
    font-family: ${({ theme }) => theme.fontFamily.homeBody};
    font-weight: 400;
`;

export default FallbackPage;
