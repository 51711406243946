import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { useLocation } from "react-router-dom";

import { useDeviceSize, expired } from "src/utils";
import { shallowEqual } from "react-redux";
import useSelector from "src/redux/store/useSelector";

import DesktopMenu from "./DesktopMenu";
import MobileMenu from "./MobileMenu";

export type NavbarProps = {
    $isImageAsBackground?: boolean;
    children?: React.ReactNode;
    lang?: string;
    setLang?: React.Dispatch<React.SetStateAction<string>>;
    dispatchAuth: (title: string, popup: boolean) => void;
};

const transparentSectionStyles = css``;

const mobileOpenStyles = css``;

const SectionWrapper = styled.nav<{
    $isImageAsBackground: boolean;
    isMobileMenuOpen: boolean;
}>`
    width: 100%;
    background: transparent;
    ${({ $isImageAsBackground }) => $isImageAsBackground && transparentSectionStyles}
    ${({ isMobileMenuOpen, $isImageAsBackground }) =>
        isMobileMenuOpen &&
        ($isImageAsBackground ? mobileOpenStyles : transparentSectionStyles)}
`;

const Navbar: React.FC<NavbarProps> = ({
    children,
    $isImageAsBackground = false,
    dispatchAuth,
}) => {
    const isTabletOrSmaller = useDeviceSize("tablet");
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
    const { pathname } = useLocation();
    const [logged, setLogged] = useState(false);

    const openMobileMenu = () => setMobileMenuOpen(true);
    const closeMobileMenu = () => setMobileMenuOpen(false);

    const loginResponse = useSelector((state) => state.login?.data ?? [], shallowEqual);
    const userInfoResponse = useSelector((state) => state.userInfo?.data ?? false, shallowEqual);

    useEffect(() => {
        setLogged(expired(loginResponse, userInfoResponse) ? false : true);
    }, [isTabletOrSmaller, pathname, loginResponse, userInfoResponse]);

    return (
        <SectionWrapper
            $isImageAsBackground={$isImageAsBackground}
            isMobileMenuOpen={isMobileMenuOpen}
        >
            {isTabletOrSmaller ? (
                <MobileMenu
                    $isImageAsBackground={$isImageAsBackground}
                    isOpen={isMobileMenuOpen}
                    openMenu={openMobileMenu}
                    closeMenu={closeMobileMenu}
                    logged={logged}
                    dispatchAuth={dispatchAuth}
                >
                    {children}
                </MobileMenu>
            ) : (
                <DesktopMenu
                    $isImageAsBackground={$isImageAsBackground}
                    logged={logged}
                    dispatchAuth={dispatchAuth}
                >
                    {children}
                </DesktopMenu>
            )}
        </SectionWrapper>
    );
};

export default Navbar;
