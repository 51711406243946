import { client } from "src/services/client";
import { ListingClustersQuery } from "./types";
import { formatTargetDate } from "src/utils/helpers";

export const fetchListingClusters = async (query: ListingClustersQuery) => {
    const data = await client({
        method: "get",
        url: `/listings/residential/map?${query.filters ?? ""}${
            query.isSold ? `&isSold=true&closingDate=gte:${formatTargetDate(-180)}` : ""
        }`,
    })
        .then((response) => response.data);
    return data;
};
