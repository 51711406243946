import moment from "moment-timezone";

export const EST_TIME_ZONE = "America/Toronto";
const FORMAT_DATETIME = "YYYY-MM-DD hh:mm:ss";
export const FORMAT_DATE = "YYYY-MM-DD";

/*
      Adjusts the target date based on daylight saving time. 
      If today is a daylight saving date but the target date is not, 
      it adds 1 hour to the target date. If today is not a daylight 
      saving date but the target date is, it subtracts 1 hour from 
      the target date. Otherwise, it returns the target date without 
      any modification.
*/
export const offsetDayLightSaving = (targetDate: moment.Moment) => {
    const isTodayDayLightSaving = moment.tz(moment.utc(), EST_TIME_ZONE).isDST();
    const isTargetDayLightSaving = moment.tz(targetDate, EST_TIME_ZONE).isDST();
    if (isTodayDayLightSaving && !isTargetDayLightSaving) {
        return targetDate.add(1, "hour");
    } else if (!isTodayDayLightSaving && isTargetDayLightSaving) {
        return targetDate.subtract(1, "hour");
    }
    return targetDate;
};

// // Return integer or epoch indicating timestamp
export const getCurrentTimestamp = () => {
    return Date.now();
};

// // return yyyy-mm-dd
export const getCurrentDate = (timeZone = EST_TIME_ZONE) => {
    return moment(getCurrentTimestamp()).tz(timeZone).format(FORMAT_DATE);
};

// // return yyyy-mm-dd hh:mm:ss
export const getCurrentDatetime = (timeZone = EST_TIME_ZONE) => {
    return moment(getCurrentTimestamp()).tz(timeZone).format(FORMAT_DATETIME);
};
