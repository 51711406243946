import { all } from "redux-saga/effects";
import { watchGetListingsRequest } from "src/redux/modules/Listings";
import { watchGetListingClustersRequest } from "src/redux/modules/Clusters";
import { watchgetCalculationResultRequest } from "src/redux/modules/MortgageCalculator";
import { watchGetContactUsRequest } from "src/redux/modules/ContactUs";
import { watchGetContactAgentRequest } from "src/redux/modules/ContactAgent";
import { watchGetSignupRequest } from "src/redux/modules/Signup";
import { watchGetLoginRequest } from "src/redux/modules/Login";
import { watchGetUserInfoRequest } from "src/redux/modules/UserInfo";
import { watchLocaleRequest } from "src/redux/modules/Locale";
import { watchGetSearchRequest } from "src/redux/modules/Search";
import { watchGetSimilarHomesRequest } from "../modules/SimilarHomes";
import { watchGetCommunityRequest } from "../modules/Community/sagas";
import { watchGetDevelopmentRequest } from "../modules/Development/sagas";
import { watchGetDevelopmentSearchRequest } from "../modules/DevelopmentSearch";
import { watchGetSchoolSearchRequest } from "../modules/SchoolSearch";
import { watchGetSchoolListingsRequest } from "../modules/SchoolFetch";
import { watchSetLoginPopupState } from "../modules/Setting/sagas";
import { watchGetDevelopmentClustersRequest } from "../modules/DevelopmentClusters/sagas";
import { watchGetCondoRequest } from "../modules/Condo/sagas";
import { watchGetAssignmentRequest } from "../modules/Assignment/sagas";

export default function* rootSaga() {
    yield all([
        watchGetSchoolSearchRequest(),
        watchGetSchoolListingsRequest(),
        watchGetListingsRequest(),
        watchGetListingClustersRequest(),
        watchgetCalculationResultRequest(),
        watchGetContactUsRequest(),
        watchGetContactAgentRequest(),
        watchGetSignupRequest(),
        watchGetLoginRequest(),
        watchGetUserInfoRequest(),
        watchGetSearchRequest(),
        watchGetSimilarHomesRequest(),
        watchLocaleRequest(),
        watchGetCommunityRequest(),
        watchGetDevelopmentClustersRequest(),
        watchGetDevelopmentRequest(),
        watchGetDevelopmentSearchRequest(),
        watchSetLoginPopupState(),
        watchGetCondoRequest(),
        watchGetAssignmentRequest(),
    ]);
}
