import { put, takeLatest } from "redux-saga/effects";
import * as Effects from "redux-saga/effects";

import { ASSIGNMENT, AssignmentActionType, AssignmentDataType } from "./types";
import { fetchAssignment } from "./services";
import get from "lodash.get";
import { receiveAssignmentSuccess, receiveAssignmentFailure } from "./actions";
import { SagaIterator } from "redux-saga";

const call: any = Effects.call;

function* getAssignment(action: AssignmentActionType): SagaIterator {
    try {
        const data: AssignmentDataType = yield call(fetchAssignment, action.payload.query);
        if (data) {
            yield put(receiveAssignmentSuccess(data, action.payload.query));
        } else {
            yield put(receiveAssignmentFailure("ERROR: Failed to fetch assignment"));
        }
    } catch (error) {
        yield put(receiveAssignmentFailure(get(error, "message", "Network Error!")));
    }
}

export function* watchGetAssignmentRequest(): SagaIterator {
    yield takeLatest(ASSIGNMENT.REQUEST, getAssignment);
}
