import { CALCULATORS, CalculationActionType } from './types';

export const mortgageCalculatorReducer = (state = {}, action: CalculationActionType) => {
    switch (action.type) {
        case CALCULATORS.REQUEST:
        case CALCULATORS.SUCCESS:
        case CALCULATORS.FAILURE:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
