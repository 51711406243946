import { ComponentPropsWithoutRef } from "react";
import styled from "styled-components";

export type SpacerProps = ComponentPropsWithoutRef<"div"> & {
  /**
   * horizontal space the spacer takes up. Defaults to `100%` when `width` is set, otherwise 0
   */
  width?: number | string | boolean;
  /**
   * vertical space the spacer takes up. Defaults to `100%` when `height` is set, otherwise 0
   */
  height?: number | string | boolean;
};

const getSpacerSize = (size: number | string | boolean | undefined) => {
  if (typeof size === "number") {
    return `${size}px`;
  } else if (typeof size === "string") {
    return size;
  } else if (size) {
    return "100%";
  } else {
    return "0";
  }
};

const Spacer = styled.div<SpacerProps>`
  width: ${({ width }) => getSpacerSize(width)};
  height: ${({ height }) => getSpacerSize(height)};
`;

Spacer.displayName = "Spacer";

export default Spacer;
