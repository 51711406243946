import { Nullable } from "src/utils";
import { ASSIGNMENT, AssignmentActionType } from "./types";

export const requestAssignment = (query: { [key: string]: string }): AssignmentActionType => ({
    type: ASSIGNMENT.REQUEST,
    payload: {
        loading: true,
        query: query,
        error: null,
    },
});

export const receiveAssignmentSuccess = (
    data: any,
    query: Nullable<{ [key: string]: string }>,
): AssignmentActionType => ({
    type: ASSIGNMENT.SUCCESS,
    payload: {
        loading: false,
        query: query,
        data: data.assignmentListings,
        page: data.page,
        error: null,
    },
});

export const receiveAssignmentFailure = (error: string): AssignmentActionType => ({
    type: ASSIGNMENT.FAILURE,
    payload: {
        loading: false,
        query: null,
        data: null,
        error: error,
    },
});
