import { Button, Form, Input, Select } from "antd";
import React from "react";
import { shallowEqual, useDispatch } from "react-redux";
import { requestUserInfo } from "src/redux/modules/UserInfo/actions";
import { useSelector } from "src/redux/store";
import { numberOnlyCheck } from "src/utils/regCheck";
import styled from "styled-components";
import intl from "react-intl-universal";
import Spacer from "src/components/base/Spacer";
import { authTitle } from "src/utils/helpers";
import { AUTH_TITLE } from "src/utils/constants";
import { Flex } from "src/components";

type SignupInfoProps = {
    setTitle: React.Dispatch<React.SetStateAction<string>>;
};

const SignupInfo: React.FC<SignupInfoProps> = ({ setTitle }) => {
    const [form] = Form.useForm();
    const loginResponse = useSelector((state) => state.login?.data ?? [], shallowEqual);
    const dispatch = useDispatch();
    const { Option } = Select;
    const loading = useSelector(
        (state) => state.login?.loading || state.signup?.loading || state.userInfo?.loading,
    );

    const handleInfoSubmit = async () => {
        let token: string = loginResponse.cognitoJWT.access_token;
        const formValues = await form.validateFields();
        const { firstName, lastName, phone, prefix } = formValues;
        dispatch(
            requestUserInfo({
                Authorization: `Bearer ${token}`,
                endpoint: "update",
                method: "PATCH",
                body: { firstName: firstName, lastName: lastName, phone: prefix + phone },
            }),
        );
        setTitle(authTitle(AUTH_TITLE.SUCCESS_SIGNUP));
    };

    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
            <Select style={{ width: 70 }}>
                <Option value="+1">+1</Option>
                <Option value="+86">+86</Option>
            </Select>
        </Form.Item>
    );

    return (
        <Flex column align="stretch" justify="center">
            <Spacer height={8} />
            <SubTitle> {intl.get("authentication.signupInfo.title")} </SubTitle>
            <Spacer height={24} />
            <Form
                layout={"vertical"}
                form={form}
                style={{ width: "100%" }}
                initialValues={{ prefix: "+1" }}
            >
                <Form.Item
                    name="firstName"
                    rules={[
                        {
                            required: true,
                            message: intl.get("authentication.warning.firstName"),
                        },
                    ]}
                >
                    <Input size="large" placeholder={intl.get("authentication.shared.firstName")} />
                </Form.Item>
                <Form.Item
                    name="lastName"
                    rules={[
                        {
                            required: true,
                            message: intl.get("authentication.warning.lastName"),
                        },
                    ]}
                >
                    <Input size="large" placeholder={intl.get("authentication.shared.lastName")} />
                </Form.Item>
                <Form.Item
                    name="phone"
                    rules={[
                        { required: true, message: intl.get("authentication.warning.phone") },
                        {
                            pattern: numberOnlyCheck,
                            message: intl.get("authentication.warning.validPhone"),
                        },
                    ]}
                >
                    <Input
                        addonBefore={prefixSelector}
                        style={{ width: "100%" }}
                        placeholder={intl.get("authentication.shared.phone")}
                    />
                </Form.Item>

                <Form.Item shouldUpdate>
                    {() => (
                        <Button
                            loading={loading}
                            htmlType="submit"
                            block
                            type="primary"
                            size="large"
                            onClick={handleInfoSubmit}
                            disabled={
                                !form.isFieldsTouched(["firstName", "lastName", "phone"], true) ||
                                !!form.getFieldsError().filter(({ errors }) => errors.length).length
                            }
                        >
                            {intl.get("authentication.shared.done")}
                        </Button>
                    )}
                </Form.Item>
            </Form>
        </Flex>
    );
};

const SubTitle = styled.p`
    font-family: Rubik;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    display: flex;
    text-transform: none;
    color: ${({ theme }) => theme.color.blackPrimary};
`;
export default SignupInfo;
