import { SEARCH, SearchActionType } from "./types";

export const searchReducer = (
  state = {},
  action: SearchActionType
) => {
  switch (action.type) {
    case SEARCH.REQUEST:
    case SEARCH.SUCCESS:
    case SEARCH.FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
