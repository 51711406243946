import { client } from "src/services/client";
import { formatTargetDate } from "src/utils/helpers";
import { CondoQuery } from "./types";

export const fetchCondo = async (query: CondoQuery) =>
    client({
        method: "get",
        url: `/condo?${query.filters}${
            query.isSold ? `&active=false&closingDate=gte:${formatTargetDate(-180)}` : ""
        }`,
        headers: { "Content-Type": "application/json" },
    }).then((response) => response.data);
