import { useState, useEffect } from "react";
import { FrontendConfig } from "./frontendConfig";

/* checks if website is either in production or development mode 
   process.env.NODE_ENV cannot be overriden manually.
   env var is undefined or 'development' in dev and 'production' in prod
*/

export const isFromProdEnv = () => {
    return process.env.NODE_ENV === "production";
};

/* custom hook which fetches the homepage config stored in s3,
 then stores it in localStorage. */

export const useFrontendConfigFetch = () => {
    const [
        savedConfigToLocalStorageSuccess,
        setSavedConfigToLocalStorageSuccess,
    ] = useState<boolean>(false);

    useEffect(() => {
        const AWSconfigLink: string = isFromProdEnv()
            ? `${process.env.REACT_APP_AWS_PROD_CONFIG}/frontendConfig.json`
            : `${process.env.REACT_APP_AWS_DEV_CONFIG}/frontendConfig.json`;

        fetch(AWSconfigLink, {
            headers: {
                "Cache-Control": "max-age=259200",
            },
        })
            .then((response) => {
                return response.json();
            })
            .then((responseToSet: FrontendConfig) => {
                if (responseToSet) {
                    localStorage.setItem("frontendConfig", JSON.stringify(responseToSet));
                    setSavedConfigToLocalStorageSuccess(true);
                }
            });
    }, []);

    return { savedConfigToLocalStorageSuccess };
};
