import React, { Suspense } from "react";
import { Button, Modal } from "antd";
import styled from "styled-components";
import Load from "src/components/HomePage/Hero/SearchBar/Load";

export interface DialogProps {
    Html: any;
    open: boolean;
    title: string;
    toggle: (tog: boolean) => any;
}

const Dialogs: React.FC<DialogProps> = ({ title, Html, open, toggle }) => {
    return (
        <StyledModal
            visible={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            footer={null}
            closable={false}
        >
            <Suspense fallback={<Load />}>
                <b>{title}</b>
                <></>
                <p>Last Updated: December 11th, 2020</p>
                <Html />
            </Suspense>
            <StyledButton onClick={() => toggle(open)} color="primary">
                Back
            </StyledButton>
        </StyledModal>
    );
};

const StyledModal = styled(Modal)`
    .ant-modal-content {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
    }

    && {
        position: fixed;
        top: 0;
        left: 0;
    }
`;

const StyledButton = styled(Button)`
    font-family: ${({ theme }) => theme.fontFamily.homeBody};
    font-size: ${({ theme }) => theme.fontSize.bodyL}px;
    ${({ theme }) => theme.styleGroup.button}
    font-weight: 700;
    background-color: ${({ theme }) => theme.color.manorleadGold};
    color: ${({ theme }) => theme.color.blackPrimary};
    text-transform: none;
    margin: 11px;
    border-radius: 0px;
    position: absolute;
    bottom: 32px; 
    right: 64px;
    min-width: 350px;
    height: 56px;
    border: 0;
} 
`;

export default Dialogs;
