import { Button, Form, Input } from "antd";
import React, { Dispatch, SetStateAction } from "react";
import intl from "react-intl-universal";
import { useDispatch } from "react-redux";
import { Flex, Spacer } from "src/components";
import { TextButton } from "src/components/AuthenticationPage";
import { requestLogin } from "src/redux/modules/Login";
import useSelector from "src/redux/store/useSelector";
import { dataType } from "src/types/authentication.type";
import { AUTH_TITLE } from "src/utils/constants";
import { authTitle } from "src/utils/helpers";
import { eRequirement } from "src/utils/regCheck";
import styled from "styled-components";
import useGoogleAnalytics, { Category } from "src/utils/GoogleAnalytics";

type ForgotProps = {
    setTitle: React.Dispatch<React.SetStateAction<string>>;
    setData: Dispatch<SetStateAction<dataType>>;
    data: dataType;
};

const ForgetPassword: React.FC<ForgotProps> = ({ setTitle, data, setData }) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const loading = useSelector(
        (state) => state.login?.loading || state.signup?.loading || state.userInfo?.loading,
    );
    const { sendEvent } = useGoogleAnalytics();

    const handleForgotForm = async () => {
        const formValues = await form.validateFields();
        let { email } = formValues;
        email = email.toLowerCase();

        sendEvent(Category.EDIT, `user interacts with forget pwd form: ${email}`, "ForgetPassword");

        setData({
            ...data,
            email: email,
        });
        dispatch(
            requestLogin({
                endpoint: "forgotpassword",
                body: { email: email },
                method: "POST",
            }),
        );
    };

    const handleSignup = () => {
        sendEvent(Category.BUTTON, `user clicks on signup on forget pwd modal`, "ForgetPassword");

        setTitle(authTitle(AUTH_TITLE.SIGNUP));
    };
    const handleLogin = () => {
        sendEvent(Category.BUTTON, `user clicks on login on forget pwd modal`, "ForgetPassword");

        setTitle(authTitle(AUTH_TITLE.LOGIN));
    };

    return (
        <Flex column align="center" justify="center">
            <Spacer height={8} />
            <SubTitle> {intl.get("authentication.forgotPassword.title")}</SubTitle>
            <Spacer height={24} />
            <Form layout={"vertical"} form={form} style={{ width: "100%" }}>
                <Form.Item
                    name="email"
                    initialValue={data.email}
                    rules={[
                        {
                            required: true,
                            message: intl.get("authentication.warning.inputEmail"),
                        },
                        {
                            pattern: eRequirement,
                            message: intl.get("authentication.warning.inputValidEmail"),
                        },
                    ]}
                >
                    <Input
                        size="large"
                        placeholder={intl.get("authentication.shared.email")}
                        value={data.email}
                    />
                </Form.Item>

                <Form.Item>
                    <Form.Item shouldUpdate>
                        <Button
                            loading={loading}
                            htmlType="submit"
                            block
                            type="primary"
                            size="large"
                            onClick={handleForgotForm}
                        >
                            {intl.get("authentication.forgotPassword.resetPassword")}
                        </Button>
                    </Form.Item>
                </Form.Item>
            </Form>

            <Spacer height={20} />
            <TextButton
                text={intl.get("authentication.forgotPassword.neverMind")}
                button={intl.get("authentication.shared.login")}
                handlePress={handleLogin}
            />
            <TextButton
                text={intl.get("authentication.forgotPassword.needAnAccount")}
                button={intl.get("navbar.register")}
                handlePress={handleSignup}
            />
        </Flex>
    );
};

const SubTitle = styled.p`
    font-family: Rubik;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    align-items: center;
    text-align: center;
    color: ${({ theme }) => theme.color.blackPrimary};
`;

export default ForgetPassword;
