import { Nullable } from "src/utils";

export enum USERINFO {
    REQUEST = "USERINFO_REQUEST",
    SUCCESS = "USERINFO_SUCCESS",
    FAILURE = "USERINFO_FAILURE",
    ERROR = "USERINFO_ERROR",
    CLEAN = "USERINFO_CLEAN",
}

export interface UserInfoState {
    loading: boolean;
    data?: any;
    error?: Nullable<string>;
    query?: Nullable<string>;
}

export interface UserInfoActionType {
    type: USERINFO;
    payload: UserInfoState;
}
