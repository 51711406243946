import { css } from "styled-components";
import { PlainGrey } from "src/static";

export const inputStyle = css`
    background-color: ${({ theme }) => theme.color.white};
    height: 36px;
    width: 100%;
    border-radius: 8px;
    border: 1px solid lightgrey;
`;

export const hoverStyles = css`
    transition: opacity 200ms;
    &:hover:not(:disabled) {
        opacity: 0.85;
    }
`;

export const focusStyles = css`
    transition: opacity 200ms;
    &:focus:not(:disabled) {
        opacity: 0.85;
    }
`;

export const disabledStyles = css`
    transition: filter 200ms;
    &:disabled {
        cursor: not-allowed;
        filter: grayscale(1);
    }
`;

export const setBackgroundImageStylesBottom = (img: string) => css`
    background-image: url(${img});
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
`;

export const setBackgroundImageStyles = (img: string) => css`
    background-image: url(${img});
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
`;

export const asanohaBackgroundImageStyles = css`
    background-image: url(${PlainGrey});
    background-position: center;
`;


export const BoxShadowOne = css`
box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04),
0px 0px 1px rgba(0, 0, 0, 0.04);
`
