import { SETTINGS, SettingsActionType } from "./types";

export const settingsReducer = (state = {}, action: SettingsActionType) => {
    switch (action.type) {
        case SETTINGS.LOGIN_POPUP_OPEN_STATE:
        case SETTINGS.LOGIN_POPUP_CLOSE_STATE:
        case SETTINGS.LOGIN_POPUP_SET_STATE:
        case SETTINGS.BLOCKER_SET_STATE:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
