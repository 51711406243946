import React from "react";
import styled from "styled-components";
import { Text } from "src/components";

const Title = styled(Text).attrs({ as: "h2" })`
    font-family: ${({ theme }) => theme.fontFamily.body};
    ${({ theme }) => theme.styleGroup.body}
    font-style: normal;
    font-weight: 500;
    font-size: ${({ theme }) => theme.fontSize.subtitle};
    display: flex;
    text-align: center;
    color: ${({ theme }) => theme.color.blackPrimary};
`;

export type StyledTitleProps = { text: string };

const StyledTitle: React.FC<StyledTitleProps> = ({ text }) => <Title>{text}</Title>;

export default StyledTitle;
