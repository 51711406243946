export enum SETTINGS {
    LOGIN_POPUP_OPEN_STATE = "LOGIN_POPUP_OPEN_STATE",
    LOGIN_POPUP_CLOSE_STATE = "LOGIN_POPUP_CLOSE_STATE",
    LOGIN_POPUP_SET_STATE = "LOGIN_POPUP_SET_STATE",
    BLOCKER_SET_STATE = "BLOCKER_SET_STATE",
}

export interface SettingsState {
    login?: any;
    blocker?: any;
}

export interface SettingsActionType {
    type: SETTINGS;
    payload: SettingsState;
}
