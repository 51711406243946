import { LOCALE, LocaleActionType } from "./types";

export const localeReducer = (state = {}, action: LocaleActionType) => {
    switch (action.type) {
        case LOCALE.SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
