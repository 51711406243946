import { CONTACT_US, ContactUsActionType } from "./types";

export const contactUsReducer = (
    state = {},
    action: ContactUsActionType
) => {
    switch (action.type) {
        case CONTACT_US.REQUEST:
        case CONTACT_US.SUCCESS:
        case CONTACT_US.FAILURE:
        case CONTACT_US.INITIAL:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
