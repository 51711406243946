import { LISTING_CLUSTERS, ListingClustersActionType } from "./types";

export const listingClustersReducer = (
  state = {},
  action: ListingClustersActionType
) => {
  switch (action.type) {
    case LISTING_CLUSTERS.REQUEST:
    case LISTING_CLUSTERS.SUCCESS:
    case LISTING_CLUSTERS.FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
