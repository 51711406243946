import styled from "styled-components";

export type GridProps = {
  /**
   * See @https://css-tricks.com/snippets/css/complete-guide-grid/ for more information
   */
  display?: "grid" | "inline-grid";
  rows?: number;
  columns?: number;
  rowGap?: number;
  columnGap?: number;
  justify?: "start" | "end" | "center" | "stretch";
  align?: "start" | "end" | "center" | "stretch";
};

const Grid = styled.div<GridProps>`
  display: ${({ display }) => display || "grid"};
  grid-template-rows: ${({ rows }) => `repeat(${rows || 2}, 1fr)`};
  grid-template-columns: ${({ columns }) => `repeat(${columns || 2}, 1fr)`};
  grid-gap: ${({ rowGap, columnGap }) =>
    `${rowGap || 10}px ${columnGap || 10}px`};
  box-sizing: border-box;
  justify-items: ${({ justify }) => justify || "stretch"};
  align-items: ${({ align }) => align || "stretch"};
`;

Grid.displayName = "Grid";

export default Grid;
