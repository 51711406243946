import { ASSIGNMENT, AssignmentActionType } from "./types";

export const assignmentReducer = (state = {}, action: AssignmentActionType) => {
    switch (action.type) {
        case ASSIGNMENT.REQUEST:
        case ASSIGNMENT.SUCCESS:
        case ASSIGNMENT.FAILURE:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
