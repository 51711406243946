import { LOGIN, LoginActionType } from "./types";

export const loginReducer = (state = {}, action: LoginActionType) => {
    switch (action.type) {
        case LOGIN.REQUEST:
        case LOGIN.SUCCESS:
        case LOGIN.FAILURE:
        case LOGIN.CLEAN:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
