import { put, takeLatest } from "redux-saga/effects";
import * as Effects from "redux-saga/effects";
import { CONTACT_US, ContactUsActionType } from "./types";
import { fetchContactUs } from "./services";
import get from "lodash.get";
import {
    receiveContactUsSuccess,
    receiveContactUsFailure,
    receiveContactUsInitialValues,
} from "./actions";

const call: any = Effects.call;

function* getContactUs(action: ContactUsActionType) {
    try {
        const response = yield call(fetchContactUs, action.payload.query);
        if (response) {
            yield put(receiveContactUsSuccess(response));
        } else {
            yield put(receiveContactUsFailure("ERROR: Failed to fetch Contact Us response"));
        }
        yield put(receiveContactUsInitialValues());
    } catch (error) {
        yield put(receiveContactUsFailure(get(error, "message", "Network Error!")));
    }
}

export function* watchGetContactUsRequest() {
    yield takeLatest(CONTACT_US.REQUEST, getContactUs);
}
