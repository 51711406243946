import { put, takeLatest } from "redux-saga/effects";
import { CALCULATORS, CalculationActionType } from "./types";
import { fetchCalculation } from "./services";
import get from "lodash.get";
import { receiveMortgageCalculationsSuccess, receiveMortgageCalculationsFailure } from "./actions";
import * as Effects from "redux-saga/effects";

const call: any = Effects.call;

function* getCalculationResult(action: CalculationActionType) {
    try {
        const data = yield call(fetchCalculation, action.payload.query);
        const results = get(data, "monthlyPayment", null);
        if (results) {
            yield put(receiveMortgageCalculationsSuccess(results));
        } else {
            yield put(
                receiveMortgageCalculationsFailure(
                    "ERROR: Failed to fetch mortgage calculator result",
                ),
            );
        }
    } catch (error) {
        yield put(receiveMortgageCalculationsFailure(get(error, "message", "Network Error!")));
    }
}

export function* watchgetCalculationResultRequest() {
    yield takeLatest(CALCULATORS.REQUEST, getCalculationResult);
}
