import { CONTACT_US, ContactUsActionType, ContactUsQuery } from "./types";

export const requestContactUs = (query: ContactUsQuery): ContactUsActionType => ({
    type: CONTACT_US.REQUEST,
    payload: {
        loading: true,
        query: query,
        error: null,
    },
});

export const receiveContactUsSuccess = (contactUs: any): ContactUsActionType => ({
    type: CONTACT_US.SUCCESS,
    payload: {
        loading: false,
        query: null,
        data: contactUs,
        error: null,
    },
});

export const receiveContactUsFailure = (error: string): ContactUsActionType => ({
    type: CONTACT_US.FAILURE,
    payload: {
        loading: false,
        query: null,
        data: null,
        error: error,
    },
});

export const receiveContactUsInitialValues = (): ContactUsActionType => ({
    type: CONTACT_US.INITIAL,
    payload: {
        loading: false,
        query: null,
        data: null,
        error: null,
    },
});
