import { Nullable } from "src/utils";

export enum CONTACT_AGENT {
    REQUEST = "CONTACT_AGENT_REQUEST",
    SUCCESS = "CONTACT_AGENT_SUCCESS",
    FAILURE = "CONTACT_AGENT_FAILURE",
    ERROR = "CONTACT_AGENT_ERROR",
}

export interface ContactAgentState {
    loading: boolean;
    data?: any;
    error?: Nullable<string>;
    query?: any;
}

export interface ContactAgentActionType {
    type: CONTACT_AGENT;
    payload: ContactAgentState;
}
