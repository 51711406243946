import { Col, Row } from "antd";
import React from "react";
import styled from "styled-components";
import intl from "react-intl-universal";

import { Line } from "src/components/base/Line";
import { Spacer } from "src/components/base";
import { HomePageContentWrapper } from "../Shared";
import { GroupPic } from "src/static";

export const getStats = () => {
    return ["save", "response", "satisfaction"];
};

export const StatsSection = () => {
    return (
        <HomePageContentWrapper>
            <Row align="middle" gutter={[40, 0]}>
                <Col span={12}>
                    <StyledLine />
                    <Row justify="space-between" align="middle">
                        <Title>{intl.get("homePage.stats.title")}</Title>
                        <Spacer height={16} />
                    </Row>
                    {getStats().map((stat) => (
                        <div key={stat}>
                            <Spacer height={24} />
                            <PointTitle>
                                {intl.get(`homePage.stats.${stat}Title1`)}
                            </PointTitle>
                            <Row align="middle" justify="space-between">
                                <Col span={6}>
                                    <PointLeft>{intl.get(`homePage.stats.${stat}Stat`)}</PointLeft>
                                </Col>
                                <Col>
                                    <StyledVtlDivider />
                                </Col>
                                <Col span={10}>
                                    <PointRight>
                                        {intl.get(`homePage.stats.${stat}Desc1`)}
                                        <BoldText>
                                            {intl.get(`homePage.stats.${stat}Desc2`)}
                                        </BoldText>
                                        {intl.get(`homePage.stats.${stat}Desc3`)}
                                    </PointRight>
                                </Col>
                            </Row>
                        </div>
                    ))}
                </Col>
                <Col span={12}>
                    <StyledImg src={GroupPic} alt="" />
                </Col>
            </Row>
        </HomePageContentWrapper>
    );
};

const StyledVtlDivider = styled.div`
    border: 1px solid #dce0f3;
    height: 64px;
`;

const StyledLine = styled(Line)`
    margin-bottom: 16px;
`;

const Title = styled.div`
    font-family: ${({ theme }) => theme.fontFamily.homeHeading};
    ${({ theme }) => theme.styleGroup.heading}
    line-height: 1;
    font-size: 48px;
`;

const PointTitle = styled.div`
    font-family: ${({ theme }) => theme.fontFamily.homeBody};
    ${({ theme }) => theme.styleGroup.heading}
    font-size: ${({ theme }) => theme.fontSize.bodyL}px;
    color: ${({ theme }) => theme.color.manorleadGold};
`;

const PointLeft = styled.div`
    font-family: ${({ theme }) => theme.fontFamily.homeHeading};
    ${({ theme }) => theme.styleGroup.heading}
    font-size: 60px;
    line-height: 1;
`;

const PointRight = styled.div`
    font-family: ${({ theme }) => theme.fontFamily.homeBody};
    ${({ theme }) => theme.styleGroup.body}
    font-size: ${({ theme }) => theme.fontSize.bodyL}px;
    font-weight: 400;
`;

const BoldText = styled.span`
    font-weight: 700;
`;

const StyledImg = styled.img`
    object-fit: contain;
    width: 100%;
`;
