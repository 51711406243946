import { put, takeLatest, call } from "redux-saga/effects";
import { SIMILARHOMES, SimilarHomesActionType } from "./types";
import { fetchSimilarHomes } from "./services";
import get from "lodash.get";
import {
  receiveSimilarHomesSuccess,
  receiveSimilarHomesFailure,
} from "./actions";

function* getSimilarHomes(action: SimilarHomesActionType) {
  try {
    const data = yield call(fetchSimilarHomes, action.payload.query);
    if (data) {
      yield put(receiveSimilarHomesSuccess(data));
    } else {
      yield put(
        receiveSimilarHomesFailure(
          "ERROR: Failed to fetch residential similarHomes"
        )
      );
    }
  } catch (error) {
    yield put(
      receiveSimilarHomesFailure(get(error, "message", "Network Error!"))
    );
  }
}

export function* watchGetSimilarHomesRequest() {
  yield takeLatest(SIMILARHOMES.REQUEST, getSimilarHomes);
}
