import { Nullable } from "src/utils";
import { PageResponse } from "src/types/listing.type";
import { SimpleAssignmentView } from "src/types/assignment.type";

export enum ASSIGNMENT {
    REQUEST = "ASSIGNMENT_REQUEST",
    SUCCESS = "ASSIGNMENT_SUCCESS",
    FAILURE = "ASSIGNMENT_FAILURE",
    ERROR = "ASSIGNMENT_ERROR",
}

export interface AssignmentState {
    loading: boolean;
    data?: SimpleAssignmentView[] | null;
    page?: PageResponse;
    error?: Nullable<string>;
    query?: Nullable<{ [key: string]: string }>;
}

export interface AssignmentActionType {
    type: ASSIGNMENT;
    payload: AssignmentState;
}

export interface AssignmentDataType {
    assignmentListings: SimpleAssignmentView[];
    page: {
        pageCount: string;
        offset: string;
    };
}
