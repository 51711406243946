import { SchoolView } from "src/types/school.type";
import { SCHOOL_LISTINGS, School_ListingsActionType } from "./types";
import { Nullable } from "src/utils";

export const requestSchoolListings = (query: string): School_ListingsActionType => ({
    type: SCHOOL_LISTINGS.REQUEST,
    payload: {
        loading: true,
        query: query,
        error: null,
    },
});

export const receiveSchoolListingsSuccess = (
    listings: SchoolView[],
    query?: Nullable<string>,
): School_ListingsActionType => ({
    type: SCHOOL_LISTINGS.SUCCESS,
    payload: {
        loading: false,
        query: query,
        data: listings,
        error: null,
    },
});

export const receiveSchoolListingsFailure = (error: string): School_ListingsActionType => ({
    type: SCHOOL_LISTINGS.FAILURE,
    payload: {
        loading: false,
        query: undefined,
        data: undefined,
        error: error,
    },
});
