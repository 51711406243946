import { SchoolView } from "src/types/school.type";
import { Nullable } from "src/utils";

export enum SCHOOL_LISTINGS {
    REQUEST = "SCHOOL_LISTINGS_REQUEST",
    SUCCESS = "SCHOOL_LISTINGS_SUCCESS",
    FAILURE = "SCHOOL_LISTINGS_FAILURE",
    ERROR = "SCHOOL_LISTINGS_ERROR",
}

export interface School_ListingsState {
    loading: boolean;
    data?: SchoolView[];
    error?: Nullable<string>;
    query?: Nullable<string>;
}

export interface School_ListingsActionType {
    type: SCHOOL_LISTINGS;
    payload: School_ListingsState;
}
