import { put, takeLatest } from "redux-saga/effects";
import { receiveLocaleSuccess } from "./actions";
import { LOCALE, LocaleActionType } from "./types";

function getLocaleInfo(action: LocaleActionType) {
    put(receiveLocaleSuccess(action.payload.lang));
}

export function* watchLocaleRequest() {
    yield takeLatest(LOCALE.SUCCESS, getLocaleInfo);
}
