import React, { SyntheticEvent } from "react";
import { Button } from "antd";
import styled from "styled-components";

export type BasicButtonProps = {
    text: string;
    type?: "text" | "link" | "default" | "ghost" | "primary" | "dashed";
    handlePress: (value: SyntheticEvent) => void;
};

const BasicButton: React.FC<BasicButtonProps> = ({ text, handlePress, type = "text" }) => {
    return (
        <ButtonStyled
            type={type}
            onClick={(value) => {
                handlePress(value);
            }}
        >
            <StyledText>{text}</StyledText>
        </ButtonStyled>
    );
};

export const BasicButton2: React.FC = (props) => {
    const { children, ...rest } = props;
    return <ButtonStyled {...rest}>{children}</ButtonStyled>;
};

export default BasicButton;

const StyledText = styled.span`
    text-decoration: underline;
`;

const ButtonStyled = styled(Button)`
    font-size: ${({ theme }) => theme.fontSize.button}px;
    color: ${({ theme }) => theme.color.navy};
    text-transform: none;
    font-family: ${({ theme }) => theme.fontFamily.body};
    ${({ theme }) => theme.styleGroup.button}
    font-style: normal;
    padding: 0px 8px;
`;
