import React, { useEffect, useState } from "react";
import { CrossIcon, TrumpetImage } from "src/static";
import styled from "styled-components";
import { Flex, Spacer } from "..";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { Button } from "antd";
import { PromotionData } from "src/utils/frontendConfig";
import { useLocation } from "react-router";

const PromotionHeader = () => {
    const bp = useBreakpoint();
    const [displayPromotion, setDisplayPromotion] = useState(false);
    const [promotionData, setPromotionData] = useState<PromotionData | null>(
        JSON.parse(localStorage.getItem("frontendConfig") || "{}")?.homepage?.promotion_banner,
    );

    const { pathname } = useLocation();

    useEffect(() => {
        setDisplayPromotion((promotionData?.is_display_promotion && pathname === "/") || false);
    }, [promotionData, pathname]); // eslint-disable-line react-hooks/exhaustive-deps

    const navigateToCTA = () => {
        if (promotionData?.cta_button_link) {
            window.location.href = promotionData.cta_button_link;
        }
    };

    const openPromotionHeader = (openOrClose: boolean) => {
        if (promotionData) {
            setPromotionData({ ...promotionData, is_display_promotion: openOrClose });
        }
    };

    if (!displayPromotion || !promotionData?.promotion_text) return null;

    return (
        <>
            <PromotionContainer bp={bp} justify={bp.xs ? "start" : "center"} align="center">
                <PromotionText bp={bp}>
                    <StyledImage src={TrumpetImage} alt="trumpet_icon" />
                    {promotionData.promotion_text}
                    {promotionData?.cta_button_text && (
                        <BannerCTAButton bp={bp} onClick={navigateToCTA}>
                            {promotionData.cta_button_text}
                        </BannerCTAButton>
                    )}
                </PromotionText>
                <IconButton bp={bp} type="link" onClick={() => openPromotionHeader(false)}>
                    <CrossIcon />
                </IconButton>
            </PromotionContainer>
            <Spacer height={bp.xs ? 64 : 48} />
        </>
    );
};

export default PromotionHeader;

const PromotionContainer = styled(Flex)`
    height: ${(props: { bp }) => (props.bp.xs ? `64px` : `48px`)};
    width: 100%;
    background-color: #1b1642;
    position: fixed;
    z-index: 1001;
    color: ${({ theme }) => theme.color.white};
`;

const PromotionText = styled.span`
    ${({ theme }) => theme.styleGroup.body};
    font-size: ${(props: { theme; bp }) =>
        props.bp.lg ? props.theme.fontSize.h4 : props.theme.fontSize.bodyXs}px;
    font-family: ${({ theme }) => theme.fontFamily.homeBody};
    font-weight: 300;
    margin: 0px 16px;
    max-width: 88%;
    text-align: center;
`;

const BannerCTAButton = styled(PromotionText)`
    cursor: pointer;
    margin-left: 8px;
    text-decoration: underline;
`;

const StyledImage = styled.img`
    height: 16px;
    padding: 0px 4px;
    margin: 8px;
`;

const IconButton = styled(Button)`
    position: absolute;
    right: ${(props: { bp }) => (props.bp.lg ? "24px" : "0px")};
`;
