import React from "react";
import styled from "styled-components";
import { Spacer, Flex, Text } from "src/components";
import { LuxuryHome1Img, LuxuryHome2Img } from "src/static";
import { asanohaBackgroundImageStyles } from "src/theme";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

import { ContentWrapper, ButtonLink, SectionWrapper } from "src/components";
import { Section } from "../constants";
import { useWindowSize } from "src/utils/useWindowSize";
import { Col, Row } from "antd";
import { ServiceHuman } from "src/static";
import Fade from "react-reveal/Fade";
import intl from "react-intl-universal";
import useGoogleAnalytics, { Category } from "src/utils/GoogleAnalytics";

const Reasons = () => {
    const { windowWidth } = useWindowSize();
    const bp = useBreakpoint();
    const { sendEvent } = useGoogleAnalytics();

    const handleDiscoverButtonClick = () => {
        sendEvent(
            Category.REDIRECTION,
            "click on discover button on home page reason section",
            "Reasons",
        );
        window.open(`/residential`);
    };

    const ImageSectionPart = (
        <ImageSection windowWidth={windowWidth}>
            <Fade bottom={true}>
                <Spacer height={windowWidth < 1024 && windowWidth > 762 ? 65 : 0} />
                <Row justify="end" gutter={[0, 55]}>
                    {windowWidth < 1024 ? (
                        <img
                            src={LuxuryHome1Img}
                            alt=""
                            width={"100%"}
                            style={{ maxHeight: "442px", float: "right" }}
                        />
                    ) : (
                        <img
                            src={LuxuryHome1Img}
                            alt=""
                            height={"100%"}
                            style={{ maxHeight: "300px", float: "none" }}
                        />
                    )}
                </Row>
                <Spacer height={bp.xs ? 55 : 0} />
                <Row justify="space-around">
                    <Col xs={12} md={12} lg={12}>
                        {windowWidth < 1024 ? (
                            <img
                                src={ServiceHuman}
                                alt=""
                                width={"90%"}
                                style={{ maxHeight: "160px", float: "left" }}
                            />
                        ) : (
                            <img
                                src={ServiceHuman}
                                alt=""
                                style={{ maxHeight: "160px", float: "none" }}
                            />
                        )}
                    </Col>
                    <Col xs={12} md={11} lg={12}>
                        {windowWidth < 1024 ? (
                            <img
                                src={LuxuryHome2Img}
                                alt=""
                                width={"100%"}
                                style={{ maxHeight: "280px", float: "right" }}
                            />
                        ) : (
                            <img
                                src={LuxuryHome2Img}
                                alt=""
                                style={{ maxHeight: "280px", float: "right" }}
                            />
                        )}
                    </Col>
                </Row>
            </Fade>
        </ImageSection>
    );

    const TextSectionPart = (
        <TextContentContainer column style={{ maxWidth: bp.xs ? "338px" : "500px" }}>
            <Spacer height={bp.xs ? 16 : 120} />
            <div className="line" />
            <Title bp={bp}>{intl.get("homePage.reason.title")}</Title>
            <Spacer height={bp.xs ? 16 : 36} />
            <Fade right={true}>
                <Description bp={bp}>{intl.get("homePage.reason.description")}</Description>
            </Fade>
            <Spacer height={bp.xs ? 16 : 36} />
            <ButtonLink
                text={intl.get("homePage.reason.discover")}
                onClick={handleDiscoverButtonClick}
            />
            <Spacer height={windowWidth < 600 ? 30 : 0} />
        </TextContentContainer>
    );

    return (
        <StyledSectionWrapper
            id={Section.BUY_SELL}
            style={{ marginTop: windowWidth < 600 ? "40px" : "5px" }}
        >
            <ContentWrapper style={{ padding: bp.xs ? "0px 10px" : "0px 20px" }}>
                <Spacer height={windowWidth < 600 ? 30 : 76} />
                <Row justify="center" gutter={[{ md: 40, lg: 68, xl: 120 }, 40]}>
                    <Col xs={24} md={11} lg={12}>
                        {bp.xs ? TextSectionPart : ImageSectionPart}
                    </Col>
                    <Col xs={24} md={12} lg={11}>
                        {bp.xs ? ImageSectionPart : TextSectionPart}
                    </Col>
                </Row>

                <Spacer height={windowWidth < 600 ? 40 : 86} />
            </ContentWrapper>
        </StyledSectionWrapper>
    );
};

export default Reasons;

const ImageSection = styled.div`
    min-width: ${(props: { windowWidth: any }) =>
        props.windowWidth < 514 ? "0px" : props.windowWidth < 1024 ? "40%" : "510px"};
    float: ${(props: { windowWidth: any }) => (props.windowWidth <= 991 ? "none" : "right")};
    max-height: 700px;
    max-width: ${(props: { windowWidth: any }) =>
        props.windowWidth < 514 ? "343px" : props.windowWidth < 1024 ? "110px" : "900px"}
    overflow: hidden;
    padding-right:6px;
    display: block;
`;

const StyledSectionWrapper = styled(SectionWrapper)`
    background-color: ${({ theme }) => theme.color.greySecondaryL};
    ${asanohaBackgroundImageStyles}
`;

const Title = styled(Text).attrs({ as: "h2" })`
    font-family: ${({ theme }) => theme.fontFamily.heading};
    ${({ theme }) => theme.styleGroup.heading}
    font-size: ${(props: { bp; theme }) =>
        props.bp.xs ? `${props.theme.fontSize.subtitle}px` : `${props.theme.fontSize.subtitle}px`};
`;

const Description = styled(Text)`
    font-family: ${({ theme }) => theme.fontFamily.body};
    font-weight: normal;
    ${({ theme }) => theme.styleGroup.body}
    font-size: ${(props: { bp; theme }) =>
        props.bp.xs ? `${props.theme.fontSize.bodyM}px` : `${props.theme.fontSize.h4}px`};
`;

const TextContentContainer = styled(Flex)`
    margin-left: auto;
    margin-right: auto;
`;
