import { createGlobalStyle } from "styled-components";

import {
    FontMerriweatherRegularWOFF,
    FontMerriweatherRegularWOFF2,
    FontMerriweatherBoldWOFF,
    FontMerriweatherBoldWOFF2,
    FontRubikLightWOFF,
    FontRubikLightWOFF2,
    FontRubikRegularWOFF,
    FontRubikRegularWOFF2,
    FontRubikMediumWOFF,
    FontRubikMediumWOFF2,
} from "src/static";

export const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=DM+Serif+Display&display=swap');

.ant-popover-inner-content {
    padding: 0px;
}

.ant-checkbox > .ant-checkbox-inner {
    border-width: 1px;
    border-radius: 2px;
}

::selection {
  color: inherit;
  background-color: #ACCEF790;
}

@font-face {
  font-family: "Lora";
  font-style: normal;
  font-weight: normal;
  src: url(${FontMerriweatherRegularWOFF2}) format("woff2"),
    url(${FontMerriweatherRegularWOFF}) format("woff");
}

@font-face {
  font-family: "Lora";
  font-style: normal;
  font-weight: bold;
  src: url(${FontMerriweatherBoldWOFF2}) format("woff2"),
    url(${FontMerriweatherBoldWOFF}) format("woff");
}

@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  src: url(${FontRubikLightWOFF2}) format("woff2"),
    url(${FontRubikLightWOFF}) format("woff");
}

@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  src: url(${FontRubikRegularWOFF2}) format("woff2"),
    url(${FontRubikRegularWOFF}) format("woff");
}

@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  src: url(${FontRubikMediumWOFF2}) format("woff2"),
    url(${FontRubikMediumWOFF}) format("woff");
}

@font-face{
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  src: url(${FontMerriweatherRegularWOFF2}) format("woff2"),
  url(${FontMerriweatherRegularWOFF}) format("woff");
}`;
