import styled from "styled-components";
import React from "react";

export const LoadingDot = () => (
    <LoadingDotContainer>
        <LoadingDotAnimation />
    </LoadingDotContainer>
);

const LoadingDotContainer = styled.div`
    background-color: ${({ theme }) => theme.color.white}a0;
    padding: 16px 32px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100000000;
`;

const LoadingDotAnimation = styled.div`
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.color.manorleadGold};
    color: ${({ theme }) => theme.color.manorleadGold};
    animation: dotFlashing 0.5s infinite linear alternate;
    animation-delay: 0.25s;

    ::before,
    ::after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 0;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: ${({ theme }) => theme.color.manorleadGold};
        color: ${({ theme }) => theme.color.manorleadGold};
        animation: dotFlashing 0.5s infinite alternate;
    }

    ::before {
        left: -15px;
        animation-delay: 0s;
    }

    ::after {
        left: 15px;
        animation-delay: 0.5s;
    }

    @keyframes dotFlashing {
        0% {
            background-color: ${({ theme }) => theme.color.manorleadGold};
        }
        50%,
        100% {
            background-color: ${({ theme }) => theme.color.yellowPrimaryM};
        }
    }
`;
