import { Nullable } from "src/utils";

export enum COMMUNITY {
    REQUEST = "COMMUNITY_REQUEST",
    SUCCESS = "COMMUNITY_SUCCESS",
    FAILURE = "COMMUNITY_FAILURE",
    ERROR = "COMMUNITY_ERROR",
}

export interface CommunityState {
    loading: boolean;
    data?: any;
    error?: Nullable<string>;
    query?: Nullable<string>;
}

export interface CommunityActionType {
    type: COMMUNITY;
    payload: CommunityState;
}
