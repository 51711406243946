import { put, takeLatest, call } from "redux-saga/effects";
import { SEARCH, SearchActionType } from "./types";
import { fetchSearch } from "./services";
import get from "lodash.get";
import {
  receiveSearchSuccess,
  receiveSearchFailure,
} from "./actions";

function* getSearch(action: SearchActionType) {
  try {
    const data = yield call(fetchSearch, action.payload.query);
    if (data) {
      yield put(receiveSearchSuccess(data));
    } else {
      yield put(
        receiveSearchFailure(
          "ERROR: Failed to fetch residential listings"
        )
      );
    }
  } catch (error) {
    yield put(
      receiveSearchFailure(get(error, "message", "Network Error!"))
    );
  }
}

export function* watchGetSearchRequest() {
  yield takeLatest(SEARCH.REQUEST, getSearch);
}
