import { Button, Form, Input, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import intl from "react-intl-universal";
import { RootStateOrAny, shallowEqual, useDispatch, useSelector } from "react-redux";

import { Line } from "src/components/base/Line";
import { HomePageContentWrapper } from "../Shared";
import { Flex, Spacer } from "src/components/base";
import { requestContactUs, ContactUsQuery } from "src/redux/modules/ContactUs";
import { nameRequirement, eRequirement, numberOnlyCheck } from "src/utils/regCheck";

export const ContactAgentSection = () => {
    const bp = useBreakpoint();
    const dispatch = useDispatch();
    const [messageSent, setMessageSent] = useState(false);

    const [form] = useForm();
    const contactUs = useSelector((state: RootStateOrAny) => state?.contactUs ?? [], shallowEqual);

    const onSubmit = () => {
        const { name, email, phone, question } = form.getFieldsValue();
        const nameArray = name.split(" ");
        const firstName =
            nameArray.length > 1
                ? nameArray.slice(0, nameArray.length - 1).join(" ")
                : nameArray[0];
        const lastName = nameArray.length > 1 ? nameArray[nameArray.length - 1] : "";
        const requestValues: ContactUsQuery = {
            firstName: firstName,
            lastName: lastName,
            phone: phone,
            email: email,
            message: question,
        };
        setMessageSent(true);
        dispatch(requestContactUs(requestValues));
    };

    useEffect(() => {
        if (
            contactUs.data?.message === "Sent Success" &&
            !contactUs?.query &&
            !contactUs?.loading &&
            messageSent
        ) {
            message.success(intl.get("homePage.contact.success"));
            setMessageSent(false);
        }
    }, [contactUs, messageSent]);

    return (
        <ContactContentWrapper>
            <LineContainer justify="center" align="center">
                <Line />
            </LineContainer>
            <Title>{intl.get("homePage.contact.title")}</Title>

            <Description bp={bp}>{intl.get("homePage.contact.description")}</Description>
            <Spacer height={40} />

            <StyledForm form={form} onFinish={onSubmit}>
                <Flex>
                    <Form.Item
                        name="name"
                        style={{ width: "50%", marginRight: "8px" }}
                        rules={[
                            {
                                required: true,
                                message: intl.get("development.CTA.requireName"),
                            },
                            {
                                pattern: nameRequirement,
                                message: intl.get("development.CTA.validName"),
                            },
                        ]}
                    >
                        <Input placeholder={intl.get("homePage.contact.name")} />
                    </Form.Item>
                    <Form.Item
                        name="phone"
                        style={{ width: "50%" }}
                        rules={[
                            {
                                pattern: numberOnlyCheck,
                                message: intl.get("authentication.warning.validPhone"),
                            },
                        ]}
                    >
                        <Input placeholder={intl.get("development.CTA.phone")} />
                    </Form.Item>
                </Flex>
                <Form.Item
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: intl.get("authentication.warning.inputEmail"),
                        },
                        {
                            pattern: eRequirement,
                            message: intl.get("authentication.warning.inputValidEmail"),
                        },
                    ]}
                >
                    <Input placeholder={intl.get("homePage.contact.email")} />
                </Form.Item>
                <Form.Item
                    name="question"
                    initialValue={intl.get("homePage.contact.initialMessage")}
                >
                    <Input.TextArea placeholder={intl.get("homePage.contact.input")} rows={6} />
                </Form.Item>
                <Form.Item shouldUpdate>
                    {() => (
                        <StyledButton
                            type="primary"
                            block
                            size="large"
                            htmlType="submit"
                            disabled={
                                form.getFieldsError().filter(({ errors }) => errors.length).length >
                                0
                            }
                        >
                            {intl.get("homePage.contact.contactUs")}
                        </StyledButton>
                    )}
                </Form.Item>
            </StyledForm>
        </ContactContentWrapper>
    );
};

message.config({
    top: 72,
});

const LineContainer = styled(Flex)`
    margin: 40px 0;
`;

const Title = styled.div`
    font-family: ${({ theme }) => theme.fontFamily.homeHeading};
    ${({ theme }) => theme.styleGroup.heading}
    font-size: ${({ theme }) => theme.fontSize.h1}px;
    line-height: 1;
    margin-bottom: 0.5em;
    text-align: center;
`;

const Description = styled.div`
    font-family: ${({ theme }) => theme.fontFamily.homeBody};
    ${({ theme }) => theme.styleGroup.body}
    font-size: ${(props: { bp; theme }) =>
        props.bp.xs ? `${props.theme.fontSize.bodyM}px` : `${props.theme.fontSize.bodyL}px`};
    line-height: 28px;
    text-align: center;
`;

const StyledForm = styled(Form)`
    .ant-input,
    .ant-form-item-explain {
        font-family: ${({ theme }) => theme.fontFamily.homeBody};
        text-align: left;
        background-color: ${({ theme }) => theme.color.greySecondaryL};
    }
`;

const StyledButton = styled(Button)`
    border-radius: 8px;
    color: ${({ theme }) => theme.color.greySecondaryD};
    background-color: ${({ theme }) => theme.color.manorleadGold};
    font-family: ${({ theme }) => theme.fontFamily.homeBody};

    :hover {
        background-color: ${({ theme }) => theme.color.manorleadGold};
        color: ${({ theme }) => theme.color.greySecondaryD};
        filter: brightness(95%);
    }
`;

const ContactContentWrapper = styled(HomePageContentWrapper)`
    max-width: 504px;
`;
