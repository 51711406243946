export enum AUTH_TITLE {
    LOGIN,
    SIGNUP,
    VERIFICATION,
    FORGOT,
    RESET,
    SIGNUPINFO,
    SUCCESS_RESET,
    SUCCESS_SIGNUP,
    LOGIN_OR_REGISTER,
}

export enum MARKETING_PAGES {
    HOME = "Home",
    RESIDENTIAL = "Residential",
    DEVELOPMENT = "New Development",
    COMMUNITY = "Guides",
    ABOUT = "Learn More",
    PORTFOLIO = "AI Price Predictions",
    CONDO = "Condo",
    ASSIGN = "Search Assignments",
    ABOUTASSIGN = "Assign With Us",
    CONTACT = "Contact Us",
    PURCHASED = "Purchased Precon",
    SAVED = "Saved Home",
    MANORPORTFOLIO = "manorPortfolio",
    CHAT = "AI Search",
    CHATV2 = "ManorAI",
    AGENT = "Agents",
    LAWYER = "Lawyers",
    RESIDENTIALLEASE = "Leasing Residential Property",
    PROPOSED = "Nearby Proposed Buildings",
    MANORAGENT = "ManorAgent",
}

export const MARKETING_PAGE_ROUTES = {
    [MARKETING_PAGES.HOME]: "/",
    [MARKETING_PAGES.DEVELOPMENT]: "/development",
    [MARKETING_PAGES.RESIDENTIAL]: "/residential",
    [MARKETING_PAGES.ABOUT]: "https://www.about.manorlead.com/",
    [MARKETING_PAGES.CONTACT]: "https://www.about.manorlead.com/contact",
    [MARKETING_PAGES.COMMUNITY]: "/community",
    [MARKETING_PAGES.PORTFOLIO]: "/ai",
    [MARKETING_PAGES.CONDO]: "/condo",
    [MARKETING_PAGES.ASSIGN]: "/assign",
    [MARKETING_PAGES.AGENT]: "/agent",
    [MARKETING_PAGES.LAWYER]: "/lawyer",
    [MARKETING_PAGES.ABOUTASSIGN]: "https://www.manorassign.com/",
    [MARKETING_PAGES.PURCHASED]: "/portfolio-personal",
    [MARKETING_PAGES.SAVED]: "/savedhome",
    [MARKETING_PAGES.MANORPORTFOLIO]: "/portfolio-development",
    [MARKETING_PAGES.CHAT]: "/chat",
    [MARKETING_PAGES.CHATV2]: "/chatv2",
    [MARKETING_PAGES.RESIDENTIALLEASE]: "/residential?isLease=true&isExclusive=false",
    [MARKETING_PAGES.PROPOSED]: "/proposed",
    [MARKETING_PAGES.MANORAGENT]: "https://chat.openai.com/g/g-6ti6EdWay-manoragent",
};

export enum MARKETING_PAGES_TREB {
    HOME = "Home",
    BUY = "Buy",
    SELL = "Sell",
    DEVELOPMENT = "New Development",
}

export enum SOCIAL_MEDIA_PLATFORMS {
    FACEBOOK = "Facebook",
    INSTAGRAM = "Instagram",
    TIKTOK = "TikTok",
    TWITTER = "Twitter",
    YOUTUBE = "Youtube",
}

export const SOCIAL_MEDIA_URLS = {
    [SOCIAL_MEDIA_PLATFORMS.FACEBOOK]: "https://www.facebook.com/manorlead",
    [SOCIAL_MEDIA_PLATFORMS.INSTAGRAM]: "https://www.instagram.com/manorlead/",
    [SOCIAL_MEDIA_PLATFORMS.TIKTOK]: "https://www.tiktok.com/@manorlead?lang=en",
    [SOCIAL_MEDIA_PLATFORMS.TWITTER]: "https://www.twitter.com/manorlead",
    [SOCIAL_MEDIA_PLATFORMS.YOUTUBE]: "https://www.youtube.com/channel/UCeu8ax0kuZ6QqMyQJcH_TEA",
};

export const IMAGE_AS_BACKGROUND_ROUTES = [
    MARKETING_PAGE_ROUTES[MARKETING_PAGES.HOME],
    MARKETING_PAGE_ROUTES[MARKETING_PAGES.ABOUT],
    MARKETING_PAGE_ROUTES[MARKETING_PAGES.COMMUNITY],
];

export const DEFAULT_PROFILE_AVATAR =
    "https://frontend-user-profile.s3.amazonaws.com/DefaultTKH6VPWUU-F01FCLKH8UCAvatar.png";

export const WHITELIST = [
    "/development",
    "/about",
    "/sell",
    "/login",
    "/access",
    "/signup",
    "/signup/info",
    "/forget",
    "/verification",
    "/reset",
    "/community",
    "/residential",
    "/listing",
    "/page-not-found",
    "/app",
    "/condo",
    "/redirect",
    "/portfolio-residential",
    "/portfolio-development",
    "/privacy",
    "/assign",
    "/ai",
    "/listOfListings",
    "/listOfDevelopments",
    "/agent",
    "/lawyer",
    "/proposed",
];

export enum BLOCKER_TYPES {
    CLOSE = "close",
    PAGE = "buy",
    ACTION = "action",
}

export enum BLOCKER_TITLES {
    DETAIL = "detail",
    FAVOURITE = "favourite",
    VIEWMORE = "viewMore",
}

export const RESIDENTIAL_PROPERTY_TYPE = [
    "All",
    "Att/Row/Twnhouse",
    "Cottage",
    "Detached",
    "Duplex",
    "Farm",
    "Multiplex",
    "Semi-Detached",
    "Triplex",
    "Vacant Land",
    "Other",
];

export const ENABLE_SCHOOL = false;
export const ENABLE_FULL_PORTFOLIO =
    JSON.parse(localStorage.getItem("frontendConfig") || "{}").enable_full_portfolio ?? false;
export const ENABLE_SOLD_CONDO_MAP = true;
export const ENABLE_SOLD_ASSIGNMENT_MAP = false;
export const ENABLE_ASSIGNMENT_SAVE = false;
export const ENABLE_BHPP = true;

export enum DevelopmentAmenity {
    FITNESS,
    CONCIERGE,
    TERRACE,
    questsuite,
    STUDY,
    LOBBY,
    PARTY,
    PET,
    POOL,
}

export type LocationMapType = "condo" | "home";
export const DIRECTIONS_OPTIONS = ["N", "NE", "E", "SE", "S", "SW", "W", "NW"];

export const MONTHS_OPTIONS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export const ONTARIO_MUNICIPALITIES = [
    "Elizabethtown-Kitley",
    "Norfolk",
    "South Bruce Peninsula",
    "North Perth",
    "Arnprior",
    "Kawartha Lakes",
    "Whitby",
    "Tehkummah",
    "Aylmer",
    "Dominican",
    "Bancroft",
    "St. Clair",
    "Kearney",
    "St. Joseph",
    "Billings",
    "North Middlesex",
    "Smiths Falls",
    "Middlesex Centre",
    "East Zorra-Tavistock",
    "The Archipelago",
    "Carlow/Mayo",
    "Portugal",
    "St. Catharines",
    "North Stormont",
    "Beckwith",
    "Ajax",
    "Southgate",
    "Port Hope",
    "Cramahe",
    "Tobago",
    "Montreal",
    "Markham",
    "Owen Sound",
    "Mono",
    "Fort Erie",
    "Frontenac Islands",
    "Greater Napanee",
    "Trent Hills",
    "Pelee",
    "Br.W.Indie",
    "Carleton Place",
    "Central Frontenac",
    "Greater Sudbury",
    "Sioux Narrows-Nestor Falls",
    "Deep River",
    "Centre Wellington",
    "Mulmur",
    "Terrace Bay",
    "Leeds & the Thousand Island",
    "Richmond Hill",
    "Cochrane Remote Area",
    "South Stormont",
    "West Elgin",
    "Tweed",
    "Drummond/North Elmsley",
    "Mississauga",
    "Lambton Shores",
    "Erin",
    "Hilton Beach",
    "Carling",
    "Greater Madawaska",
    "Prince Edward County",
    "South Algonquin",
    "Quinte West",
    "Oliver Paipoonge",
    "Ryerson",
    "McMurrich/Monteith",
    "Uxbridge",
    "Adelaide Metcalfe",
    "Oakville",
    "Northeastern Manitoulin and",
    "Blandford-Blenheim",
    "Montague",
    "Hearst",
    "Oro-Medonte",
    "St. Thomas",
    "Ingersoll",
    "Oshawa",
    "East Garafraxa",
    "Springwater",
    "Coleman",
    "West Perth",
    "Hamilton",
    "Seguin",
    "Highlands East",
    "Strathroy-Caradoc",
    "Callander",
    "Killarney",
    "Bracebridge",
    "South River",
    "Thunder Bay Remote Area",
    "Dysart et al",
    "Chatham-Kent",
    "Wollaston",
    "Latchford",
    "Cambridge",
    "Gauthier",
    "Miami Beach",
    "Huntsville",
    "Evanturel",
    "Black River-Matheson",
    "Petrolia",
    "Perth",
    "Central Elgin",
    "Wellington North",
    "Alnwick/Haldimand",
    "Augusta",
    "Casey",
    "East Ferris",
    "Kerns",
    "Georgina Islands",
    "Minden Hills",
    "Marmora and Lake",
    "Lakeshore",
    "Tay Valley",
    "Laurentian Valley",
    "Pembroke",
    "Addington Highlands",
    "Chisholm",
    "Cochrane",
    "Cavan Monaghan",
    "West Lincoln",
    "Windsor",
    "Baldwin",
    "South-West Oxford",
    "Barbados",
    "King",
    "Clarence-Rockland",
    "Deseronto",
    "Port Colborne",
    "Brudenell, Lyndoch and Ragl",
    "Minto",
    "Amaranth",
    "Dawn-Euphemia",
    "Perry",
    "Wasaga Beach",
    "Howick",
    "Southwest Middlesex",
    "Peterborough",
    "Greece",
    "South Frontenac",
    "Whitchurch-Stouffville",
    "Greenstone",
    "Lake of the Woods",
    "Lanark Highlands",
    "Kapuskasing",
    "Powassan",
    "Hamilton Township",
    "Stratford",
    "Central Manitoulin",
    "Innisfil",
    "Jamaica",
    "North Bay",
    "Guelph",
    "Englehart",
    "Val Rita-Harty",
    "Christian Island 30",
    "Niagara Falls",
    "Cobalt",
    "Shuniah",
    "Asphodel-Norwood",
    "Kingsville",
    "London",
    "Port Charlotte Fl",
    "McKellar",
    "Milton",
    "Central Huron",
    "Midland",
    "Pelham",
    "Parry Sound Remote Area",
    "Athens",
    "Muskoka Lakes",
    "Punjab",
    "Bonfield",
    "Enniskillen",
    "Elliot Lake",
    "Florida  Usa",
    "North Dumfries",
    "The North Shore",
    "Whitestone",
    "Nipissing",
    "Panama",
    "Niagara-on-the-Lake",
    "Manitoulin Remote Area",
    "Gordon/Barrie Island",
    "Joly",
    "Essa",
    "Pickle Lake",
    "Georgian Bay",
    "Westport",
    "Brockville",
    "Wellesley",
    "Jocelyn",
    "Markstay-Warren",
    "Bonnechere Valley",
    "Dryden",
    "Burpee & Mills",
    "Machin",
    "Mexico",
    "Prince",
    "Norwich",
    "Prince Edward Island",
    "Front of Yonge",
    "South Dundas",
    "Magnetawan",
    "Woodstock",
    "Brampton",
    "Hilton",
    "North Kawartha",
    "Welland",
    "Leamington",
    "Newmarket",
    "Otonabee-South Monaghan",
    "Kelowna",
    "Pickering",
    "Sundridge",
    "Meaford",
    "Strong",
    "Parry Sound",
    "Amherstburg",
    "Moosonee",
    "Huron-Kinloss",
    "Sault Ste Marie",
    "Faraday",
    "Grey Highlands",
    "Kirkland Lake",
    "Papineau-Cameron",
    "Cayman Islands",
    "Armour",
    "Plummer Additional",
    "Roma/Italy",
    "Kincardine",
    "Puslinch",
    "Cobourg",
    "Georgina",
    "Smooth Rock Falls",
    "Hanover",
    "Waterloo",
    "France",
    "Scugog",
    "Schreiber",
    "Macdonald, Meredith & Aberd",
    "Opasatika",
    "Mattice-Val Cote",
    "Hiawatha First Nation",
    "Belleville",
    "Lincoln",
    "McGarry",
    "Chilliwack",
    "Alfred & Plantagenet",
    "Kitchener",
    "Vaughan",
    "Red Rock",
    "Timmins",
    "Shelburne",
    "Mattawan",
    "Iroquois Falls",
    "Malahide",
    "Algonquin Highlands",
    "Bluewater",
    "Stirling-Rawdon",
    "Douro-Dummer",
    "North Frontenac",
    "Whitewater Region",
    "Plympton-Wyoming",
    "Lake of Bays",
    "Thunder Bay",
    "Tillsonburg",
    "Wainfleet",
    "St. Charles",
    "Grimsby",
    "Thorold",
    "Brock",
    "Orillia",
    "Sables-Spanish Rivers",
    "Temiskaming Shores",
    "Brighton",
    "McDougall",
    "Nairn & Hyman",
    "West Grey",
    "Stone Mills",
    "Sarnia",
    "Orangeville",
    "North Huron",
    "Goderich",
    "Gravenhurst",
    "East Gwillimbury",
    "Ramara",
    "Thessalon",
    "Charlton and Dack",
    "Huron East",
    "Sudbury Remote Area",
    "Tiny",
    "Machar",
    "Out of Area",
    "Burlington",
    "South Bruce",
    "Haldimand",
    "Halton Hills",
    "Thames Centre",
    "Cornwall",
    "Chapleau",
    "Temagami",
    "Prescott",
    "Laurentian Hills",
    "Hastings Highlands",
    "Warwick",
    "Blue Mountains",
    "Kenora Remote Area",
    "Blind River",
    "Casselman",
    "Arran-Elderslie",
    "Spanish",
    "Champlain",
    "Clearview",
    "Toronto C12",
    "Kingston",
    "North Algona Wilberforce",
    "St. Marys",
    "Ottawa",
    "Mapleton",
    "Nipissing Remote Area",
    "Bayham",
    "Wilmot",
    "Loyalist",
    "Tecumseh",
    "Algoma Remote Area",
    "Zorra",
    "New Brunswick",
    "Renfrew",
    "Centre Hastings",
    "North Glengarry",
    "Havelock-Belmont-Methuen",
    "Assiginack",
    "Mississippi Mills",
    "Limerick",
    "Armstrong",
    "Huron Shores",
    "Rainy River",
    "Burks Falls",
    "Essex",
    "Brantford",
    "Admaston/Bromley",
    "Killaloe, Hagarty & Richard",
    "Lucan Biddulph",
    "Johnson",
    "Espanola",
    "Perth East",
    "Laird",
    "Galway-Cavendish and Harvey",
    "Sioux Lookout",
    "South Glengarry",
    "Brockton",
    "Head, Clara & Maria",
    "Guelph/Eramosa",
    "Calvin",
    "Chatsworth",
    "Rideau Lakes",
    "Madoc",
    "Tudor & Cashel",
    "Moonbeam",
    "Saugeen Shores",
    "Perth South",
    "Woolwich",
    "Mattawa",
    "South Huron",
    "Quebec",
    "Marathon",
    "Gore Bay",
    "North Grenville",
    "Smith-Ennismore-Lakefield",
    "Bradford West Gwillimbury",
    "Morris-Turnberry",
    "Southwold",
    "Melancthon",
    "McNab/Braeside",
    "Adjala-Tosorontio",
    "Brooke-Alvinston",
    "Timiskaming Remote Area",
    "Severn",
    "Fort Frances",
    "West Nipissing",
    "Aurora",
    "Barrie",
    "Alma",
    "Petawawa",
    "Merrickville-Wolford",
    "North Dundas",
    "Ashfield-Colborne-Wawanosh",
    "Brant",
    "Clarington",
    "East Hawkesbury",
    "Wawa",
    "Madawaska Valley",
    "Hornepayne",
    "Larder Lake",
    "Gananoque",
    "Edwardsburgh/Cardinal",
    "Horton",
    "Dutton/Dunwich",
    "Collingwood",
    "First Nations",
    "Curve Lake First Nation 35",
    "Northern Bruce Peninsula",
    "French River",
    "LaSalle",
    "Penetanguishene",
    "Toronto",
    "Caledon",
    "Nipigon",
    "Tyendinaga",
    "East Luther Grand Valley",
    "Tay",
    "Newbury",
    "Fauquier-Strickland",
    "Costa Rica",
    "Nation",
    "New Tecumseth",
    "Georgian Bluffs",
    "Russell",
    "Scugog 34",
];

export enum CHATRATING {
    THUMBSUP = "thumbsUp",
    THUMBSDOWN = "thumbsDown",
}

export const GTA_COMMUNITIES = [
    "Headford Business Park",
    "Stonegate-Queensway",
    "Lynde Creek",
    "Bendale",
    "Aileen-Willowbrook",
    "Norfolk",
    "Junction Area",
    "Palmerston",
    "North End",
    "Onward Willow",
    "Rural Glanbrook",
    "Rural Guelph/Eramosa",
    "Pine Valley Business Park",
    "Norwood",
    "St. Clair",
    "Rural Springwater",
    "Cameron",
    "Eringate-Centennial-West Deane",
    "Hillcrest Village",
    "Rural East Garafraxa",
    "Rural Aurora",
    "Maple Leaf",
    "Delta",
    "Glen Shields",
    "Rockcliffe-Smythe",
    "Heart Lake West",
    "Humbermede",
    "Cookstown",
    "Southgate",
    "Remote Area",
    "Chappel",
    "Port Hope",
    "Uptown",
    "Ridgetown",
    "Kinmount",
    "Tottenham",
    "Rockwood",
    "Bradford",
    "Georgetown",
    "Rural Puslinch",
    "Rustic",
    "Sanford",
    "Taunton North",
    "The Islands",
    "Trafalgar",
    "Kennedy",
    "Blackstock",
    "Cumberland",
    "Brampton South",
    "Woburn",
    "Rural Flamborough",
    "Northglen",
    "Rural Galway-Cavendish and Harvey",
    "Yeoville",
    "Colgan",
    "Berrisfield",
    "Rural Chatsworth",
    "Aurora Estates",
    "Gershome",
    "Rathwood",
    "Dempsey",
    "Rural Oakville",
    "Bruleville",
    "Dorset Park",
    "Clarke",
    "Yonge-St. Clair",
    "Corktown",
    "Beverley Glen",
    "Bayshore",
    "Dundurn",
    "Hillsdale",
    "Limehouse",
    "Simcoe",
    "Centremount",
    "Rural Innisfil",
    "Alderwood",
    "Victoria Square",
    "Danforth",
    "Wallaceburg",
    "Rosebank",
    "Cabbagetown-South St. James Town",
    "Rural King",
    "Dixie",
    "Coates",
    "Ardagh",
    "Bothwell",
    "Rouge Fairways",
    "Gilbert",
    "Palgrave",
    "Glenway Estates",
    "Prices Corners",
    "Mount Pleasant West",
    "Rural Southgate",
    "Northwest Ajax",
    "Hanlon Industrial",
    "Beaverton",
    "Rosedale-Moore Park",
    "Kleinburg",
    "King's Forest Lower",
    "Trenholme",
    "Wheatley",
    "West Willow Woods",
    "Dufferin Grove",
    "Eden Mills",
    "Bayview Wellington",
    "Blue Mountain Resort Area",
    "Birchcliffe-Cliffside",
    "Caledon Village",
    "Eastmount",
    "Hurontario",
    "Neustadt",
    "Caledon East",
    "Gilford",
    "Roseland",
    "Keelesdale-Eglinton West",
    "Drayton",
    "Ryckmans",
    "Moffat",
    "Centre Vespra",
    "Bolton North",
    "Stroud",
    "Old Oakville",
    "Humewood-Cedarvale",
    "Sunninghill",
    "Brampton 407 Corridor",
    "Rural Smith-Ennismore-Lakefield",
    "Fletcher's Meadow",
    "Sudbury",
    "Iroquoia Heights",
    "Horseshoe Valley",
    "Blenheim",
    "Kentley",
    "Lakeshore",
    "Walden",
    "Glen Williams",
    "Glenfield-Jane Heights",
    "Niagara",
    "Bayview",
    "Baldwin",
    "Templemead",
    "Burford",
    "Cliffcrest",
    "Heart Lake East",
    "Devonsleigh",
    "Rural New Tecumseth",
    "New Lowell",
    "Stevenson",
    "Snelgrove",
    "O'Connor-Parkview",
    "Marchmont",
    "Kanata",
    "Rural Severn",
    "Samac",
    "Wasaga Beach",
    "Goreway Drive Corridor",
    "Stripley",
    "Blakeley",
    "Donevan",
    "Quinndale",
    "Hillsburgh",
    "Thornbury",
    "Sherwood",
    "Little Britain",
    "Brampton East",
    "Fessenden",
    "Moonstone",
    "Edgar",
    "Innis-Shore",
    "Thamesville",
    "Nickel Centre",
    "Hastings",
    "June Avenue",
    "Rural Asphodel-Norwood",
    "Wismer",
    "Castleton",
    "Rosedale",
    "Mountainside",
    "Stewarttown",
    "Rose",
    "Shanty Bay",
    "Brooklin",
    "Scugog First Nations",
    "Greensville",
    "Bonnington",
    "Curve Lake First Nation",
    "Midland",
    "Humberlea-Pelmo Park W4",
    "Erindale",
    "Bethany",
    "Mount Pleasant East",
    "Airport Road/ Highway 7 Business Centre",
    "Downtown Whitby",
    "Stayner",
    "Vincent",
    "Markland Wood",
    "Hanlon Creek",
    "South Shore",
    "Milton Heights",
    "Williamsburg",
    "Nashdale",
    "Grindstone",
    "Rural Whitby",
    "Georgina Island",
    "Durand",
    "Tansley",
    "Sophiasburgh",
    "Northwood Park",
    "Westbrook",
    "Holland Landing",
    "Kirkfield",
    "Hill Park",
    "West Hill",
    "Rural Georgian Bluffs",
    "Thornlea",
    "Aurora Village",
    "Rolling Acres",
    "Vales of Castlemore North",
    "Cedarwood",
    "College",
    "Rural Blue Mountains",
    "Port McNicoll",
    "Buttonville",
    "Tam O'Shanter-Sullivan",
    "Brechin",
    "Phelpston",
    "Willowdale East",
    "Malvern",
    "Briar Hill-Belgravia",
    "Sharon",
    "Sandy Hollow",
    "Alton",
    "Rural Barrie Southwest",
    "Rural Burlington",
    "Bolton East",
    "Brookhaven-Amesbury",
    "Bedford Park-Nortown",
    "Ashburnham",
    "Rural Trent Hills",
    "Brampton West",
    "Casa Loma",
    "Meadowlands",
    "Clarkson",
    "Port Perry",
    "Commerce Valley",
    "High Park North",
    "Two Rivers",
    "Crestwood-Springfarm-Yorkhill",
    "Bramalea West Industrial",
    "West Bayfield",
    "Bronte East",
    "Claireville Conservation",
    "Mohawk",
    "Devil's Glen",
    "Rural Whitchurch-Stouffville",
    "Picton",
    "Erin Mills",
    "Snow Valley",
    "Cobourg",
    "Forest Hill North",
    "Taunton",
    "Orchard",
    "Albion Falls",
    "Rural Brock",
    "Strathcona",
    "Bayview Fairway-Bayview Country Club Estates",
    "Andrew-Windfields",
    "Observatory",
    "Gore Industrial North",
    "Orkney",
    "Ballantrae",
    "Hiawatha First Nation",
    "Willmont",
    "East Credit",
    "Bloomfield",
    "Lakeview Estates",
    "Village East",
    "Vanier",
    "Rural Tiny",
    "Howard",
    "Sherwood-Amberglen",
    "Glen Abbey",
    "Newtonbrook West",
    "Mill Pond",
    "Dundalk",
    "Malton",
    "Fletcher's Creek South",
    "L'Amoreaux",
    "South Richvale",
    "Shoreacres",
    "Bayview Southeast",
    "Camden",
    "Roncesvalles",
    "Tilbury East",
    "Hampton Heights",
    "New Toronto",
    "Clifford",
    "Victoria Village",
    "Eleanor",
    "Raleigh",
    "400 North",
    "Brock Ridge",
    "Devil's Elbow",
    "Orangeville",
    "Rural Erin",
    "Sonoma Heights",
    "Bayview Glen",
    "Schomberg",
    "Rural Vaughan",
    "Vinegar Hill",
    "Royal Orchard",
    "Flesherton",
    "Agincourt North",
    "Keswick North",
    "Aurora Highlands",
    "Airport Corporate",
    "Broughton",
    "Meadowvale",
    "Rayside-Balfour",
    "Rural Markham",
    "Sheldon",
    "Raymerville",
    "Nepean",
    "Rural Caledon",
    "Grayside",
    "Rockton",
    "Rural East Gwillimbury",
    "Humber Summit",
    "Aurora Heights",
    "Rolston",
    "Grove East",
    "Bullock",
    "Avondale",
    "Village",
    "Thistletown-Beaumonde Heights",
    "High Park-Swansea",
    "Milliken Mills East",
    "East Woodbridge",
    "Uptown Core",
    "Clearview",
    "Markville",
    "Rural Alnwick/Haldimand",
    "Pinecrest",
    "Allandale Centre",
    "Rexdale-Kipling",
    "Bramalea Road South Gateway",
    "Harrison",
    "Cannington",
    "City Centre",
    "Rural Manvers",
    "Warminister",
    "Nobleton",
    "Colwell",
    "Dresden",
    "Derry Green Business Park",
    "South East",
    "Greenford",
    "401 Business Park",
    "Bobcaygeon",
    "Westdale",
    "Wellington",
    "Northcrest",
    "Pine Ridge",
    "Rural Ramara",
    "Bridle Path-Sunnybrook-York Mills",
    "Thornhill",
    "Rural Dalton",
    "Rural Stoney Creek",
    "Chatsworth",
    "Southam",
    "Burkholme",
    "Leaside",
    "Everett",
    "Rideau",
    "Glencairn",
    "Madoc",
    "Brownridge",
    "Westminster-Branson",
    "Kingsview Village-The Westway",
    "Central",
    "Ancaster",
    "Moss Park",
    "Bay Street Corridor",
    "Bayview Northeast",
    "Palmerston-Little Italy",
    "Regent Park",
    "Bowmanville",
    "Etobicoke West Mall",
    "Armitage",
    "Mt Albert",
    "Rural Adjala-Tosorontio",
    "Downtown",
    "Nelson",
    "Colborne",
    "Rural Cavan Monaghan",
    "Oak Ridges",
    "Rural Oshawa",
    "Ionview",
    "Rural Bexley",
    "Rural Fenelon",
    "Brampton North",
    "Yonge-Eglinton",
    "Winona",
    "Rouge E10",
    "York University Heights",
    "Landsdale",
    "Baltimore",
    "Beeton",
    "Vaughan Grove",
    "Capreol",
    "Lisle",
    "Crescent Town",
    "Hillier",
    "Islington Woods",
    "Thorner",
    "Rouge River Estates",
    "Rural Scugog",
    "Rural Clarington",
    "Milliken Mills West",
    "Bilbert",
    "Edgehill Drive",
    "Vales of Castlemore",
    "Grandview",
    "Bay Ridges",
    "Winona Park",
    "Crosby",
    "Doncrest",
    "Bayview Village",
    "Georgian Drive",
    "Ayton",
    "Northwood",
    "Painswick North",
    "Anten Mills",
    "Janetville",
    "Broadview North",
    "Mineola",
    "Henry Farm",
    "Watson",
    "Port Severn",
    "Highbush",
    "Little Portugal",
    "Jerseyville",
    "Cundles East",
    "Toronto Gore Rural Estate",
    "Weston-Pellam Park",
    "Angus Glen",
    "Midhurst",
    "Aberfoyle",
    "Arthur",
    "Jefferson",
    "Burnt River",
    "South Parkdale",
    "Woodbine-Lumsden",
    "West Carleton",
    "Sugarbush",
    "Waterfront Communities C1",
    "Lynden",
    "Ford",
    "Markdale",
    "Guildwood",
    "South Marysburgh",
    "Morningside",
    "Wyevale",
    "Langstaff",
    "Rouge Woods",
    "Winston Park",
    "Rural Pickering",
    "Walker",
    "Old Milton",
    "Stinson",
    "Industrial Sector",
    "Port Whitby",
    "Woodland Hill",
    "Morriston",
    "Centennial",
    "Old University",
    "Highway 427",
    "Exhibition Park",
    "Churchill",
    "Angus",
    "Barnstown",
    "Moorefield",
    "Town Centre",
    "Macassa",
    "Bayfield",
    "Steeles",
    "Hannon",
    "Amberlea",
    "Rural Centre Wellington",
    "Beasley",
    "Perkinsfield",
    "Rural Bradford West Gwillimbury",
    "Lindsay",
    "Rural Emily",
    "College Park",
    "Owen Sound",
    "Stipley",
    "Woodville",
    "Rural Mapleton",
    "Rural Uxbridge",
    "Lawrence Park South",
    "Rouge E11",
    "Otonabee",
    "Humber Heights",
    "Bond Head",
    "North Riverdale",
    "Uplands",
    "Huron Heights-Leslie Valley",
    "Markham Village",
    "Little Lake",
    "Mewburn",
    "Airport Employment Area",
    "Butler",
    "Durham",
    "Sandringham-Wellington North",
    "Grange Hill East",
    "Grafton",
    "Creemore",
    "Bathurst Manor",
    "North Shore",
    "Riverdale",
    "Elder Mills",
    "Woodburn",
    "Concord",
    "Fruitland",
    "Clairfields",
    "Rural North Kawartha",
    "Pontypool",
    "German Mills",
    "Appleby",
    "Rural Oro-Medonte",
    "Rougemount",
    "Lansing-Westgate",
    "Erin",
    "Rural Grey Highlands",
    "Don Valley Village",
    "Iroquois Ridge South",
    "East Bayfield",
    "Loretto",
    "Allison",
    "Parkwood Gardens",
    "Playter Estates-Danforth",
    "Gorham-College Manor",
    "Valley East",
    "Cornell",
    "Dover",
    "Downsview-Roding-CFB",
    "Rural Verulam",
    "Gurnett",
    "Uxbridge",
    "King City",
    "Brookville",
    "Waterfront Communities C8",
    "Northgate",
    "Gloucester",
    "Weston",
    "Fairview",
    "Palermo West",
    "North Marysburgh",
    "Homeside",
    "Victoria Harbour",
    "Pottageville",
    "South West",
    "Waverley",
    "Oakridge",
    "South Dumfries",
    "West Woodbridge Industrial Area",
    "Yorkdale-Glen Park",
    "Cedar Grove",
    "Garden Hill",
    "Windfields",
    "Stoney Creek",
    "Dundas",
    "Agincourt South-Malvern West",
    "Nanticoke",
    "Lawrence Park North",
    "Esquesing",
    "Danforth Village-East York",
    "Tilbury",
    "Havelock",
    "Rural Barrie Southeast",
    "Allandale Heights",
    "Rural West Grey",
    "Mimico",
    "Oakwood-Vaughan",
    "Cootes Paradise",
    "Painswick South",
    "Alliston",
    "Kernighan",
    "Newcastle",
    "Ainslie Wood",
    "Lorne Park",
    "Central Erin Mills",
    "Priceville",
    "400 East",
    "Maple",
    "Bristol-London",
    "Greeningdon",
    "Rural Ops",
    "Rural Meaford",
    "Red Hill",
    "Binbrook",
    "Highland Creek",
    "Tyandaga",
    "Ameliasburgh",
    "Hamilton Beach",
    "Vellore Village",
    "Scarborough Village",
    "Orono",
    "Milliken",
    "Bronte Meadows",
    "Bolton West",
    "Allandale",
    "Balfour",
    "Unionville",
    "Rural Halton Hills",
    "Lisgar",
    "Kensington-Chinatown",
    "Wexford-Maryvale",
    "Bayview Woods-Steeles",
    "Lefroy",
    "Headon",
    "Village Green-South Unionville",
    "West Humber-Clairville",
    "Downtown Brampton",
    "Stonehaven-Wyndham",
    "Pringle Creek",
    "Dunnville",
    "Rural Richmond Hill",
    "Port Credit",
    "Lafontaine",
    "Rural Hamilton",
    "Rural East Luther Grand Valley",
    "Mount Hope",
    "Rural Somerville",
    "Kortright Hills",
    "Vaughan Corporate Centre",
    "Rural Wellington North",
    "Aurora Grove",
    "Bayview Hill",
    "Kirkendall",
    "Huttonville",
    "Central Park",
    "Huntington",
    "Manilla",
    "Virginia",
    "Monaghan",
    "Greenwood-Coxwell",
    "Rural Brighton",
    "Westcliffe",
    "Eastdale",
    "Inch Park",
    "Bass Lake",
    "Grand Valley",
    "Sandringham-Wellington",
    "Keswick South",
    "Bronte Creek",
    "Banbury-Don Mills",
    "Brentwood",
    "Rural Cramahe",
    "Craighurst",
    "Letitia Heights",
    "Woodbine Corridor",
    "Liverpool",
    "Cachet",
    "Beausoleil First Nation",
    "Bartonville",
    "Heart Lake",
    "Thornton",
    "Berczy",
    "Elora/Salem",
    "East York",
    "Mono Mills",
    "Gibson",
    "Rural Havelock-Belmont-Methuen",
    "Belhaven",
    "Victoria Manor-Jennings Gate",
    "Rural Mono",
    "Buchanan",
    "Oakwood",
    "Sunnidale",
    "Inglewood",
    "Mountview",
    "Meaford",
    "400 West",
    "Alcona",
    "Corman",
    "Oakwood Village",
    "Rural Eldon",
    "Southdown",
    "Old Markham Village",
    "Patterson",
    "Wyebridge",
    "Brantford Twp",
    "Atherley",
    "Harwich",
    "Kennedy Park",
    "Bram East",
    "Osgoode",
    "Farewell",
    "Clanton Park",
    "Pleasant View",
    "University",
    "Hawkestone",
    "Bronte West",
    "Rymal",
    "Norland",
    "Lakeview",
    "Minesing",
    "Islington-City Centre West",
    "Harding",
    "Hanover",
    "Central East",
    "Highgate",
    "Stoney Creek Industrial",
    "Westgate",
    "Queensville",
    "Creditview",
    "Northeast Ajax",
    "Palmer",
    "McLaughlin",
    "Columbus",
    "Rural Mulmur",
    "West Woodbridge",
    "Fenelon Falls",
    "Rural Dundas",
    "Rural Ancaster",
    "Crown Point",
    "Humberlea-Pelmo Park W5",
    "Nottawa",
    "Acton",
    "Credit Valley",
    "Bramalea North Industrial",
    "Rural Clearview",
    "Freelton",
    "St. Andrew-Windfields",
    "Guthrie",
    "Rural Carden",
    "Omemee",
    "Hockley",
    "Fergus",
    "Meadowvale Village",
    "Bram West",
    "Shelburne",
    "Hills of St Andrew",
    "Goulbourn",
    "Rural Otonabee-South Monaghan",
    "Bewdley",
    "Rural Mariposa",
    "Runnymede-Bloor West Village",
    "Church-Yonge Corridor",
    "Campbellville",
    "West Oak Trails",
    "Elms-Old Rexdale",
    "Waubaushene",
    "Coldwater",
    "Greensborough",
    "Queen Street Corridor",
    "Timberlea",
    "Annex",
    "Clairlea-Birchmount",
    "Parkwoods-Donalda",
    "Orillia",
    "Dovercourt-Wallace Emerson-Junction",
    "Langstaff South",
    "Athol",
    "Brighton",
    "East End-Danforth",
    "Rural Port Hope",
    "Bowes",
    "Freeman",
    "Carlisle",
    "Falkirk",
    "Alliance",
    "McQuesten",
    "Blue Grass Meadows",
    "Englemount-Lawrence",
    "Corso Italia-Davenport",
    "North St. James Town",
    "Hallowell",
    "Fletcher's West",
    "Carpenter",
    "Duffin Heights",
    "Willowdale West",
    "Villages of Glancaster",
    "Mount Dennis",
    "Kedron",
    "Middlefield",
    "Long Branch",
    "Rural Douro-Dummer",
    "Pefferlaw",
    "Belwood",
    "Cooksville",
    "Haldimand",
    "Beaty",
    "Stoney Creek Mountain",
    "Rouge Park",
    "Singhampton",
    "Sheridan Park",
    "Paris",
    "Snake Island",
    "Box Grove",
    "Sutton & Jackson's Point",
    "South Riverdale",
    "Whitby Industrial",
    "Flemingdon Park",
    "Gourley",
    "Ardtrea",
    "Eastlake",
    "Rural Tay",
    "Rockcliffe Park",
    "Kingsway South",
    "Fletcher's Creek Village",
    "Brant Hills",
    "Confederation Park",
    "Rosemont",
    "Churchill Meadows",
    "Trinity-Bellwoods",
    "Ottawa",
    "Black Creek",
    "Mountain View",
    "Parkview",
    "Caledonia-Fairbank",
    "Elmvale",
    "Sunderland",
    "Delhi",
    "Northwest Sandalwood Parkway",
    "Eglinton East",
    "Mavis-Erindale",
    "Dunbarton",
    "Brock Industrial",
    "Willowridge-Martingrove-Richview",
    "North Richvale",
    "Millbrook",
    "Cheltenham",
    "Rural Laxton",
    "Sheridan",
    "Batteaux",
    "Normanhurst",
    "Iroquois Ridge North",
    "Beechborough-Greenbrook",
    "Legacy",
    "Edenbridge-Humber Valley",
    "Northwest Brampton",
    "Raglan",
    "Baxter",
    "Scott",
    "O'Neill",
    "Rural Essa",
    "Newmarket Industrial Park",
    "Washago",
    "River Oaks",
    "Holly",
    "Chatham",
    "Lakefield",
    "Oak Ridges Lake Wilcox",
    "Central West",
    "Fesserton",
    "Coboconk",
    "Queen's Park",
    "Nassagaweya",
    "Rural Amaranth",
    "Mississauga Valleys",
    "Northwest",
    "Cathedraltown",
    "Streetsville",
    "The Beaches",
    "Warkworth",
    "Central Newmarket",
    "Waterdown",
    "Jerome",
    "Twenty Place",
    "Dunsford",
    "Gilkson",
    "Princess-Rosethorn",
    "Historic Lakeshore Communities",
    "Randall",
    "Newtonbrook East",
    "Alma",
    "Oakland",
    "Stouffville",
    "Codrington",
    "Rural Melancthon",
    "Brant",
    "Blake-Jones",
    "Harriston",
    "West Shore",
    "Rushdale",
    "Cobban",
    "Willmott",
    "Woodlands",
    "Courtice",
    "Mount Forest",
    "Wychwood",
    "Collingwood",
    "Orford",
    "Lawfield",
    "Summerhill Estates",
    "Crerar",
    "Glenview",
    "LaSalle",
    "Penetanguishene",
    "Rural Minto",
    "Forest Hill South",
    "Campbellford",
    "Centennial Scarborough",
    "Lambton Baby Point",
    "Guelph South",
    "Mount Olive-Silverstone-Jamestown",
    "Applewood",
];

export const AGENT_LIST = [
    {
        firstName: "Richard",
        fullName: "Richard Xie",
        position: "Broker of Record",
        phoneNumber: "647-613-8678",
    },
    {
        firstName: "William",
        fullName: "William Xie",
        position: "Broker, FRI, CLHMS",
        phoneNumber: "416-806-7768",
    },
    {
        firstName: "Edward",
        fullName: "Edward Sun",
        position: "Salesperson",
        phoneNumber: "416-561-2625",
    },
    {
        firstName: "Grace",
        fullName: "Grace Liu",
        position: "Salesperson, MSc",
        phoneNumber: "647-986-6815",
    },
    {
        firstName: "Gabriel",
        fullName: "Gabriel Gu",
        position: "Rebel Owner",
        phoneNumber: "647-123-4567",
    },
];
