import React, { ComponentPropsWithoutRef, useMemo } from "react";
import styled, { css } from "styled-components";
import { Link as ReactRouterLink } from "react-router-dom";

import { hoverStyles, disabledStyles } from "src/theme";

export type LinkProps = ComponentPropsWithoutRef<"a"> & {
    newTab?: boolean;
    defaultstyles?: string;
};

type CustomLinkProps = LinkProps & {
    useBaseLink?: boolean;
};

const isRelativeHref = (href: string) => {
    try {
        new URL(href);
        return false;
    } catch (err) {
        return true;
    }
};

const linkDefaultStyles = css`
    color: inherit;
    text-decoration: none;
    cursor: pointer;
    ${hoverStyles}
    ${disabledStyles}
`;

const RawLink = styled.a<{
    defaultStyles: string;
}>`
    ${({ defaultStyles }) => defaultStyles && linkDefaultStyles}
`;

const StyledReactRouterLink = styled(ReactRouterLink)<{
    defaultstyles: string;
}>`
    ${({ defaultstyles }) => defaultstyles && linkDefaultStyles}
`;

export const BaseLink: React.FC<LinkProps> = ({ children, newTab = false, defaultstyles = "true", ...rest }) => {
    const target = newTab ? "_blank" : undefined;
    const rel = newTab ? "noopener noreferrer" : "";
    return (
        <RawLink target={target} rel={rel} defaultStyles={defaultstyles} {...rest}>
            {children}
        </RawLink>
    );
};

const Link: React.FC<CustomLinkProps> = ({ href = "", defaultstyles = "true", useBaseLink=false, ...rest }) => {
    const isInternal: boolean = useMemo(() => isRelativeHref(href), [href]);

    return (isInternal && !useBaseLink) ? (
        <StyledReactRouterLink to={href} defaultstyles={defaultstyles} {...rest}></StyledReactRouterLink>
    ) : (
        <BaseLink href={href} defaultstyles={defaultstyles} {...rest}></BaseLink>
    );
};

export default Link;
