import { Button } from "antd";
import React, { Dispatch, SetStateAction } from "react";
import ReactCodeInput from "react-code-input";
import intl from "react-intl-universal";
import { Flex, Spacer } from "src/components";
import useSelector from "src/redux/store/useSelector";
import { dataType } from "src/types/authentication.type";
import styled from "styled-components";
import { TextButton } from "../../../components/AuthenticationPage";

type VerificationProps = {
    verifyDispatch: () => void;
    resendVerifyDispatch: () => void;
    setData: Dispatch<SetStateAction<dataType>>;
};

const Verification: React.FC<VerificationProps> = ({
    verifyDispatch,
    resendVerifyDispatch,
    setData,
}) => {
    const [code, setCode] = React.useState<string>("");
    const loading = useSelector(
        (state) => state.login?.loading || state.signup?.loading || state.userInfo?.loading,
    );
    const handleChange = (value: any) => {
        setCode(value);
        setData((data) => {
            return { ...data, code: value };
        });
    };

    return (
        <Flex column align="center" justify="center">
            <Spacer height={8} />
            <SubTitle> {intl.get("authentication.verification.codeSent")} </SubTitle>
            <SubTitle> {intl.get("authentication.verification.enterCode")} </SubTitle>
            <Spacer height={24} />
            <ReactCodeInput
                name="verification"
                inputMode="tel"
                type="tel"
                fields={6}
                value={code}
                onChange={handleChange}
                inputStyle={{
                    border: "1px solid lightgrey",
                    margin: "2px",
                    padding: "16px 8px",
                    width: "15%",
                    fontSize: "20px",
                    textAlign: "center",
                }}
            />
            <Spacer height={16} />
            <Button
                loading={loading}
                htmlType="submit"
                block
                type="primary"
                size="large"
                onClick={() => verifyDispatch()}
            >
                {intl.get("authentication.verification.verifyEmail")}
            </Button>
            <Spacer height={16} />
            <TextButton
                text={intl.get("authentication.verification.didntGetEmail")}
                button={intl.get("authentication.verification.resendCode")}
                handlePress={resendVerifyDispatch}
            />
        </Flex>
    );
};

const SubTitle = styled.p`
    font-family: Rubik;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: none;
    color: ${({ theme }) => theme.color.blackPrimary};
`;

export default Verification;
