import { combineReducers } from "redux";
import { listingsReducer } from "src/redux/modules/Listings";
import { listingClustersReducer } from "src/redux/modules/Clusters";
import { mortgageCalculatorReducer } from "src/redux/modules/MortgageCalculator";
import { contactUsReducer } from "src/redux/modules/ContactUs";
import { contactAgentReducer } from "src/redux/modules/ContactAgent";
import { signupReducer } from "src/redux/modules/Signup";
import { loginReducer } from "src/redux/modules/Login";
import { userInfoReducer } from "src/redux/modules/UserInfo";
import { localeReducer } from "src/redux/modules/Locale";
import { searchReducer } from "src/redux/modules/Search";
import { similarHomesReducer } from "../modules/SimilarHomes";
import { communityReducer } from "../modules/Community/reducers";
import { developmentReducer } from "../modules/Development/reducers";
import { developmentSearchReducer } from "../modules/DevelopmentSearch";
import { schoolSearchReducer } from "../modules/SchoolSearch";
import { schoolListingsReducer } from "../modules/SchoolFetch";
import { settingsReducer } from "../modules/Setting/reducers";
import { DevelopmentClustersReducer } from "../modules/DevelopmentClusters";
import { condoReducer } from "../modules/Condo";
import { assignmentReducer } from "../modules/Assignment";

const appReducer = combineReducers({
    listings: listingsReducer,
    schoolListings: schoolListingsReducer,
    schoolSearch: schoolSearchReducer,
    clusters: listingClustersReducer,
    calculation: mortgageCalculatorReducer,
    contactUs: contactUsReducer,
    contactAgent: contactAgentReducer,
    locale: localeReducer,
    signup: signupReducer,
    login: loginReducer,
    userInfo: userInfoReducer,
    search: searchReducer,
    similarHomes: similarHomesReducer,
    community: communityReducer,
    development: developmentReducer,
    developmentClusters: DevelopmentClustersReducer,
    developmentSearch: developmentSearchReducer,
    settings: settingsReducer,
    condo: condoReducer,
    assignment: assignmentReducer,
});

export const rootReducer = (state, action) => {
    return appReducer(state, action);
};
