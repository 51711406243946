import { DevelopmentView } from "src/types/listing.type";
import { DevelopmentSeachQuery } from ".";
import { DEVELOPMENT_SEARCH, Development_SearchActionType } from "./types";

export const requestDevelopmentSearch = (
    query: DevelopmentSeachQuery,
): Development_SearchActionType => ({
    type: DEVELOPMENT_SEARCH.REQUEST,
    payload: {
        loading: true,
        query: query,
        error: null,
    },
});

export const receiveDevelopmentSearchSuccess = (
    listings: DevelopmentView[],
    query?: DevelopmentSeachQuery,
): Development_SearchActionType => ({
    type: DEVELOPMENT_SEARCH.SUCCESS,
    payload: {
        loading: false,
        query: query,
        data: listings,
        error: null,
    },
});

export const receiveDevelopmentSearchFailure = (error: string): Development_SearchActionType => ({
    type: DEVELOPMENT_SEARCH.FAILURE,
    payload: {
        loading: false,
        query: undefined,
        data: undefined,
        error: error,
    },
});
