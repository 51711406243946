import React from "react";
import { Helmet } from "react-helmet";

interface MetaDataProps {
    title: string;
    description: string;
    pageUrl?: string;
    image?: string | null;
}

const MetaData: React.FC<MetaDataProps> = ({ pageUrl, title, description, image }) => {
    return (
        <Helmet>
            <script type="application/ld+json">
                {`{
                    "@context": "https://schema.org",
                    "@type": "Article",
                    "image": "${image}",
                    "headLine": "${title}"
                }`}
            </script>
            <meta charSet="utf-8" />
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="title" content={title} />
            {pageUrl && <meta property="og:url" content={pageUrl} />}
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:type" content="article" />
            <meta
                property="og:image"
                content={
                    image ? image : "https://frontend-static-image.s3.amazonaws.com/icons/logo.svg"
                }
            />
        </Helmet>
    );
};

export default MetaData;
