import { Button } from "antd";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import intl from "react-intl-universal";

import { gotoGoogleLogin } from "src/utils/helpers";
import { GoogleIcon } from "src/static";
import { Text } from "../base";

interface GoogleLoginButtonProps {
    renderType: "register" | "login" | "continue";
}

const GoogleLoginButton: React.FC<GoogleLoginButtonProps> = ({ renderType }) => {
    const location = useLocation<{ [key: string]: string }>();
    const [disableButton, setDisableButton] = useState(false);

    const redirectToGoogleLogin = () => {
        setDisableButton(true);
        const pathname = location.pathname;
        const query = location.search.slice(1).split("&");
        query.push(`targetURL=${pathname}`);
        gotoGoogleLogin(query.join("+"));
    };

    return (
        <BlueButton onClick={redirectToGoogleLogin} disabled={disableButton}>
            <StyledImg src={GoogleIcon} />
            <StyledText>{intl.get(`authentication.shared.google-${renderType}`)}</StyledText>
        </BlueButton>
    );
};

export const StyledButton = styled(Button)`
    height: 51px;
    width: 100%;
`;

const BlueButton = styled(StyledButton)`
    background-color: #4285f4;
    :hover {
        background-color: #4285f4f0;
        border: none;
    }
`;

const StyledText = styled(Text)`
    font-family: ${({ theme }) => theme.fontFamily.heading};
    font-size: ${({ theme }) => theme.fontSize.bodyM}px;
    font-weight: 400;
    color: ${({ theme }) => theme.color.white};
    display: inline;
    margin-left: 8px;
    position: relative;
    top: 2px;
`;

const StyledImg = styled.img`
    position: absolute;
    left: 16px;
    top: 10px;
`;

export default GoogleLoginButton;
