import { SETTINGS, SettingsActionType } from "./types";
import { AUTH_TITLE } from "src/utils/constants";
import { authTitle, BLOCKER_TYPES, BLOCKER_TITLES } from "src/utils";

export const setLoginPopup = (
    auth: boolean,
    title: string = authTitle(AUTH_TITLE.LOGIN),
    isBlocker: boolean = false,
): SettingsActionType => ({
    type: SETTINGS.LOGIN_POPUP_OPEN_STATE,
    payload: {
        login: { loginPopup: auth, loginTitle: title, isBlocker: isBlocker },
    },
});

export const setBlocker = (
    blockerType: BLOCKER_TYPES,
    blockerTitle: BLOCKER_TITLES = BLOCKER_TITLES.DETAIL,
    onLogin: () => void = () => {},
): SettingsActionType => ({
    type: SETTINGS.BLOCKER_SET_STATE,
    payload: {
        blocker: { blockerType: blockerType, blockerTitle: blockerTitle, onLogin: onLogin },
    },
});
