import { put, takeLatest } from "redux-saga/effects";
import * as Effects from "redux-saga/effects";

import { DEVELOPMENT_CLUSTERS, DevelopmentClustersActionType } from "./types";
import { fetchDevelopmentClusters } from "./services";
import get from "lodash.get";
import { receiveDevelopmentClustersSuccess, receiveDevelopmentClustersFailure } from "./actions";
import { SagaIterator } from "redux-saga";
import { DevelopmentViewCluster } from "src/types/listing.type";

const call: any = Effects.call;

function* getDevelopmentClusters(action: DevelopmentClustersActionType): SagaIterator {
    try {
        const data: DevelopmentViewCluster = yield call(
            fetchDevelopmentClusters,
            action.payload.query,
        );

        if (data) {
            yield put(receiveDevelopmentClustersSuccess(data));
        } else {
            yield put(
                receiveDevelopmentClustersFailure("ERROR: Failed to fetch residential development"),
            );
        }
    } catch (error) {
        yield put(receiveDevelopmentClustersFailure(get(error, "message", "Network Error!")));
    }
}

export function* watchGetDevelopmentClustersRequest(): SagaIterator {
    yield takeLatest(DEVELOPMENT_CLUSTERS.REQUEST, getDevelopmentClusters);
}
