import { DEVELOPMENT_CLUSTERS, DevelopmentClustersActionType } from "./types";

export const DevelopmentClustersReducer = (state = {}, action: DevelopmentClustersActionType) => {
    switch (action.type) {
        case DEVELOPMENT_CLUSTERS.REQUEST:
        case DEVELOPMENT_CLUSTERS.SUCCESS:
        case DEVELOPMENT_CLUSTERS.FAILURE:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
