import { SEARCH, SearchActionType } from "./types";

export const requestSearch = (query: string): SearchActionType => ({
  type: SEARCH.REQUEST,
  payload: {
    loading: true,
    query: query,
    error: null,
  },
});

export const receiveSearchSuccess = (
  listings: any
): SearchActionType => ({
  type: SEARCH.SUCCESS,
  payload: {
    loading: false,
    query: null,
    data: listings,
    error: null,
  },
});

export const receiveSearchFailure = (
  error: string
): SearchActionType => ({
  type: SEARCH.FAILURE,
  payload: {
    loading: false,
    query: null,
    data: null,
    error: error,
  },
});
