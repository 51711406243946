import { Card } from "antd";
import React from "react";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import styled from "styled-components";
import intl from "react-intl-universal";

import { parsePrice, getIntlOrRaw } from "src/utils/helpers";
import { DevelopmentView, DevelopmentTag, DevelopmentStatus } from "src/types/listing.type";
import {
    RecommendedTag,
    SellingTag,
    RegistrationTag,
    SoldOutTag,
    MoveTag,
} from "src/components/Development/Tag";
import { Spacer } from "src/components/base";

interface DevelopmentCardProps {
    development: DevelopmentView;
    route: (development: DevelopmentView) => void;
}

export const DevelopmentCard: React.FC<DevelopmentCardProps> = ({ development, route }) => {
    const bp = useBreakpoint();
    return (
        <>
            <StyledCard
                cover={
                    <>
                        <StyledImg alt="..." src={development.projectImageUrls[0]} />
                        {development.developmentPromotions.length > 0 && (
                            <PromotionTag bp={bp}>
                                {intl.get("homePage.newDev.promotion")}
                            </PromotionTag>
                        )}
                    </>
                }
                onClick={() => route(development)}
            >
                <TagContainer>
                    {development.tags &&
                        development.tags.indexOf(DevelopmentTag.RECOMMENDED) >= 0 && (
                            <RecommendedTag />
                        )}
                    {development.status === DevelopmentStatus.SELLING && <SellingTag />}
                    {development.status === DevelopmentStatus.REGISTRATION && <RegistrationTag />}
                    {development.status === DevelopmentStatus.SOLDOUT && <SoldOutTag />}
                    {development.completionYear && (
                        <MoveTag completionYear={development.completionYear} />
                    )}
                </TagContainer>
                <Spacer height={8} />
                <Name bp={bp}>{development.name}</Name>
                <Spacer height={8} />
                <Price bp={bp}>
                    {development.minPrice
                        ? `${intl.get("development.shared.simpleFrom1")} $${parsePrice(
                              development.minPrice,
                          )} ${intl.get("development.shared.from2")}`
                        : intl.get("development.floorPlan.noPriceData")}
                </Price>
                <Spacer height={8} />
                <Address bp={bp}>
                    {getIntlOrRaw(`shared.municipality`, development.municipality)},{" "}
                    {getIntlOrRaw(`shared.province`, development.province)}
                </Address>
            </StyledCard>
        </>
    );
};

const StyledCard = styled(Card)`
    box-shadow: ${({ theme }) => theme.boxShadow.One};
    cursor: pointer;
    .ant-card-body {
        padding: 16px;
    }
`;

const Name = styled.div`
    font-family: ${({ theme }) => theme.fontFamily.homeBody};
    ${({ theme }) => theme.styleGroup.body}
    font-size: ${(props: { bp; theme }) =>
        props.bp.xs ? `${props.theme.fontSize.bulletTitle}px` : `${props.theme.fontSize.bodyL}px`};
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const Price = styled.div`
    font-family: ${({ theme }) => theme.fontFamily.homeBody};
    ${({ theme }) => theme.styleGroup.body}
    font-size: ${(props: { bp; theme }) =>
        props.bp.xs ? `${props.theme.fontSize.bodyS}px` : `${props.theme.fontSize.bodyM}px`};
    font-weight: 700;
    opacity: 0.7;
`;

const Address = styled(Price)`
    font-size: ${(props: { bp; theme }) =>
        props.bp.xs ? `${props.theme.fontSize.bodyS}px` : `${props.theme.fontSize.bodyXs}px`};
    font-weight: 400;
`;

const StyledImg = styled.img`
    height: 152px;
    width: 100%;
    position: relative;
    object-fit: cover;
`;

const TagContainer = styled.div`
    font-family: ${({ theme }) => theme.fontFamily.body};
    font-size: ${({ theme }) => theme.fontSize.bodyS}px;
    ${({ theme }) => theme.styleGroup.body}
    font-weight: normal;
    overflow: hidden;
    white-space: nowrap;
    width: 125%;
    transform: translate(-10%, -10%) scale(0.8);

    :before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 85%, white);
    }
`;

const PromotionTag = styled.span`
    font-family: ${({ theme }) => theme.fontFamily.homeBody};
    ${({ theme }) => theme.styleGroup.body}
    font-weight: 500;
    font-size: ${(props: { bp; theme }) =>
        props.bp.xs ? `${props.theme.fontSize.bodyXs}px` : `${props.theme.fontSize.bodyS}px`};
    color: ${({ theme }) => theme.color.white};
    padding: 0px 8px;
    background-color: ${({ theme }) => theme.color.alertRed};
    width: fit-content;
    border-radius: 0px 8px 8px 0px;
    position: absolute;
    top: 24px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 90%;
`;
