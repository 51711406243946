import React from "react";
import { Link, Spacer } from "src/components";
import { useLocation } from "react-router-dom";
import { CaretDownOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import intl from "react-intl-universal";
import { LinkText } from "./DesktopMenu";
import styled from "styled-components";

export interface Route {
    pathname: string;
    search: string;
}

interface NavbarItemProps {
    route: Route | Route[];
    text: string | string[];
    scrolly: number;
    icon: any;
}

export function isRoute(route: Route | Route[]): route is Route {
    return "pathname" in route;
}

const NavbarItem: React.FC<NavbarItemProps> = ({ route, text, scrolly }) => {
    const { pathname, search } = useLocation();

    const isSameLocation = (route: Route) => {
        if (route.pathname !== pathname) {
            return false;
        }

        const routeSearch = new URLSearchParams(route.search);
        const curSearch = new URLSearchParams(search);

        let isSame = true;
        routeSearch.forEach((value, key) => {
            if (curSearch.get(key) !== value) {
                isSame = false;
            }
        });
        return isSame;
    };

    const isSameLocationGroup = (route: Route[]) => {
        let isSame = false;
        route.forEach((r) => {
            isSame = isSame || isSameLocation(r);
        });
        return isSame;
    };

    return (
        <React.Fragment>
            {isRoute(route) && typeof text === "string" && (
                <Link href={`${route.pathname}?${route.search}`}>
                    <LinkText $isRouteSelected={isSameLocation(route)} scrolly={scrolly}>
                        {intl.get(text)}
                    </LinkText>
                </Link>
            )}
            {!isRoute(route) && (
                <Dropdown
                    overlay={
                        <StyledMenu>
                            {route.map((r, i) => {
                                const useBaseLink =
                                    r.pathname.split("?")[0] === window.location.pathname;
                                return (
                                    <StyledMenuItem key={i}>
                                        <Link
                                            href={`${r.pathname}?${r.search}`}
                                            useBaseLink={useBaseLink}
                                        >
                                            {intl.get(text[i + 1])}
                                        </Link>
                                    </StyledMenuItem>
                                );
                            })}
                        </StyledMenu>
                    }
                    trigger={["hover"]}
                    getPopupContainer={(trigger) =>
                        trigger.parentElement ? trigger.parentElement : trigger
                    }
                >
                    <LinkText
                        $isRouteSelected={isSameLocationGroup(route)}
                        scrolly={scrolly}
                        style={{ cursor: "pointer" }}
                    >
                        {intl.get(text[0])}
                        <CaretDownOutlined
                            style={{
                                fontSize: "15px",
                                marginLeft: "7px",
                            }}
                        />
                    </LinkText>
                </Dropdown>
            )}
            <Spacer width={23} />
        </React.Fragment>
    );
};

export default NavbarItem;

const StyledMenu = styled(Menu)`
    border-radius: 8px;
`;

const StyledMenuItem = styled(Menu.Item)`
    padding: 8px 16px;
`;
