type TDefaultTheme = {
    color: {
        manorleadGold: string;
        goldAccent: string;
        goldL: string;
        yellowPrimaryL: string;
        yellowPrimaryM: string;
        yellowPrimaryD: string;

        blackPrimary: string;
        blackPrimaryL: string;
        blackPrimaryM: string;

        greySecondaryL: string;
        greySecondaryM: string;
        greySecondaryD: string;

        white: string;
        black: string;
        navy: string;
        lightBlue: string;
        purple: string;

        alertRed: string;
        success: string;
    };
    fontFamily: {
        heading: string;
        body: string;
        homeHeading: string;
        homeBody: string;
    };
    fontSize: {
        h1: number;
        h2: number;
        cardtitle: number;
        subtitle: number;
        bulletTitle: number;
        h3: number;
        h4: number;
        bodyL: number;
        bodyM: number;
        bodyS: number;
        bodyXs: number;
        button: number;
    };
    boundaryColor: {
        regular: string;
        holding: string;
    };
    boundaryOpacity: {
        regular: number;
        holding: number;
    };
    boundaryWidth: {
        holding: string;
    };
    boxShadow: {
        One: string;
        CardContainer: string;
    };
    styleGroup: {
        body: string;
        button: string;
        heading: string;
    };
};

/**
 * This merges our theme's interface with the default theme interface
 * and other declarations in styled-components, which provides
 * correct typing for the theme object in styled-components' ThemeProvider
 * (see https://www.styled-components.com/docs/api#typescript)
 */
declare module "styled-components" {
    interface DefaultTheme extends TDefaultTheme {}
}

export const theme: TDefaultTheme = {
    color: {
        manorleadGold: "#FFC12D",
        goldAccent: "#FFB400",
        goldL: "#FFF9EA",
        yellowPrimaryL: "#FFEFCA",
        yellowPrimaryM: "#FFE096",
        yellowPrimaryD: "#FFD061",

        blackPrimary: "#151515",
        blackPrimaryL: "#4F4F4F",
        blackPrimaryM: "#454545",

        greySecondaryL: "#F2F2F2",
        greySecondaryM: "#A9A9A9",
        greySecondaryD: "#151515",

        white: "#FFFFFF",
        black: "#151515",
        navy: "#222F7D",
        lightBlue: "#F3F8FF",
        purple: "#5C4AA9",

        alertRed: "#BA3A3A",
        success: "#589C65",
    },
    fontFamily: {
        heading: "Rubik, Roboto, Helvetica, Arial, sans-serif",
        body: "Rubik, Roboto, Helvetica, Arial, sans-serif",
        homeHeading: "DM Serif Display, serif",
        homeBody: "DM Sans",
    },
    fontSize: {
        h1: 54,
        h2: 36,
        cardtitle: 32,
        subtitle: 28,
        h3: 24,
        bulletTitle: 20,
        h4: 16,
        bodyL: 18,
        bodyM: 16,
        bodyS: 14,
        bodyXs: 12,
        button: 16,
    },
    boundaryColor: {
        regular: "#FFC12D",
        holding: "#151515",
    },
    boundaryOpacity: {
        regular: 0.3,
        holding: 0.1,
    },
    boundaryWidth: {
        holding: "2px",
    },
    boxShadow: {
        One:
            "0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)",
        CardContainer:
            "0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02)",
    },
    styleGroup: {
        button: "letter-spacing: 0.03em; font-weight: 400;",
        body: "letter-spacing: 0.01em; line-height: 140%; font-weight: 300;",
        heading: "letter-spacing: -0.02em; font-weight: 500;",
    },
};
