import { put, takeLatest, call } from "redux-saga/effects";
import { CONTACT_AGENT, ContactAgentActionType } from "./types";
import { fetchContactAgent } from "./services";
import get from "lodash.get";
import {
    receiveContactAgentSuccess,
    receiveContactAgentFailure,
} from "./actions";

function* getContactAgent(action: ContactAgentActionType) {
    try {
        const response = yield call(fetchContactAgent, action.payload.query);
        
        if (response) {
            yield put(receiveContactAgentSuccess(response));
        } else {
            yield put(
                receiveContactAgentFailure(
                    "ERROR: Failed to fetch Contact Us response"
                )
            );
        }
    } catch (error) {
        yield put(
            receiveContactAgentFailure(get(error, "message", "Network Error!"))
        );
    }
}

export function* watchGetContactAgentRequest() {
    yield takeLatest(CONTACT_AGENT.REQUEST, getContactAgent);
}
