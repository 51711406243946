export enum LOCALE {
    SUCCESS = "LOCALE_SUCCESS",
}

export interface LocaleState {
    lang: string;
}

export interface LocaleActionType {
    type: LOCALE;
    payload: LocaleState;
}
