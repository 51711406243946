import React from "react";
import { Input } from "antd";
import styled from "styled-components";
import { AiFillWarning } from "react-icons/ai";
import { theme } from "src/theme/theme";

export type StyledInputProps = {
    type: string;
    text: string;
    value?: string | number;
    alert?: boolean;
    errMsg?: string;
    defaultValue?: string;
    handleChange: (value: any) => void;
    handlePress?: (value: React.SyntheticEvent<EventTarget>) => void;
    handleChecking?: () => void;
};

const StyledInput: React.FC<StyledInputProps> = ({
    type,
    text,
    value,
    alert,
    errMsg,
    defaultValue,
    handleChange,
    handlePress,
    handleChecking,
}) => {
    const CreateTextAlert = (props: { msg?: string }) => {
        const { msg } = props;
        return <AlertText>{msg}</AlertText>;
    };

    return (
        <Container>
            <InputBox
                placeholder={text}
                type={type}
                defaultValue={defaultValue}
                value={value}
                onChange={(e) => handleChange(e)}
                onPressEnter={(e) => handlePress && handlePress(e)}
                onBlur={() => handleChecking && handleChecking()}
            />
            {alert && (
                <AlertContainer>
                    <AiFillWarning
                        style={{
                            color: theme.color.alertRed,
                            marginRight: "5px",
                            fontSize: "16px",
                        }}
                    />
                    <CreateTextAlert msg={errMsg} />
                </AlertContainer>
            )}
        </Container>
    );
};

const Container = styled.div`
    width: 100%;
`;

const AlertContainer = styled.div`
    display: flex;
    margin-top: 5px;
    position: absolute;
`;

const AlertText = styled.div`
    width: 100%;
    color: ${({ theme }) => theme.color.alertRed};
    font-size: ${({ theme }) => theme.fontSize.bodyS}px;
    font-family: ${({ theme }) => theme.fontFamily.body};
`;

const InputBox = styled(Input)`
    && {
        background-color: ${({ theme }) => theme.color.white};
        width: 100%;
        min-width: 100%;
        max-width: 450px;
        border: 2px solid #e5e5e5;
        border-radius: 0px;
        &:hover {
            border-color: #ffb800;
        }
    }
`;

export default StyledInput;
