import { put, takeLatest, call } from "redux-saga/effects";
import { COMMUNITY } from "./types";
import { fetchCommunity } from "./services";
import get from "lodash.get";
import { receiveCommunitySuccess, receiveCommunityFailure } from "./actions";

function* getCommunityInfo() {
    try {
        const response = yield call(fetchCommunity);
        if (response) {
            yield put(receiveCommunitySuccess(response));
        } else {
            yield put(receiveCommunityFailure("ERROR: Failed to fetch Community response"));
        }
    } catch (error) {
        yield put(receiveCommunityFailure(get(error, "message", "Network Error!")));
    }
}

export function* watchGetCommunityRequest() {
    yield takeLatest(COMMUNITY.REQUEST, getCommunityInfo);
}
