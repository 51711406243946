import React from "react";
import styled from "styled-components";
import { Spacer, Flex, Text } from "src/components";
// import { asanohaBackgroundImageStyles } from "src/theme";
import { TechnologyImage } from "src/static";

import { ContentWrapper, SectionWrapper } from "src/components";
import { Section } from "../constants";
import { useWindowSize } from "src/utils/useWindowSize";
import { Col, Row } from "antd";

const Reasons = () => {
    const { windowWidth } = useWindowSize();

    return (
        <StyledSectionWrapper id={Section.FEATURES}>
            <ContentWrapper>
                <Spacer height={windowWidth < 600 ? 40 : 76} />
                <Row justify="center" align="middle" gutter={[{ lg: 68, xl: 120 }, 48]}>
                    <Col xs={24} md={10} lg={10}>
                        <TextContentContainer column>
                            <div className="line" />
                            <Title>Trade With Manorlead</Title>
                            <Spacer height={36} />
                            <Subtitle>
                                Integrating intelligence and data analytics in a modern real estate
                                platform.
                            </Subtitle>
                            <Spacer height={36} />
                            <Description>
                                Whether is it finding your dream home, purchasing for investment, or
                                selling your property, Manorlead combines the latest AI technologies
                                with local industry expertise to provide an unbeatable real estate
                                trading experience.
                            </Description>
                        </TextContentContainer>
                    </Col>
                    <Col xs={24} md={14} lg={14}>
                        <ImageSection>
                            <img
                                src={TechnologyImage}
                                alt=""
                                width={"100%"}
                                height={windowWidth < 950 ? "420px" : "500px"}
                            />
                        </ImageSection>
                    </Col>
                </Row>

                <Spacer height={windowWidth < 600 ? 40 : 86} />
            </ContentWrapper>
        </StyledSectionWrapper>
    );
};

export default Reasons;

const ImageSection = styled.div`
    overflow: hidden;
`;

const StyledSectionWrapper = styled(SectionWrapper)`
    background-color: #fff;
    margin-left: 12px;
    margin-right: 12px;
    width: 100%;
`;

const Title = styled(Text).attrs({ as: "h2" })`
    font-family: ${({ theme }) => theme.fontFamily.heading};
    ${({ theme }) => theme.styleGroup.heading}
    font-size: ${({ theme }) => theme.fontSize.h2}px;
`;

const Description = styled(Text)`
    font-family: ${({ theme }) => theme.fontFamily.body};
    font-weight: normal;
    ${({ theme }) => theme.styleGroup.body}
    font-size: 20px;
`;

const Subtitle = styled(Text).attrs({ as: "h4" })`
    font-family: ${({ theme }) => theme.fontFamily.heading};
    ${({ theme }) => theme.styleGroup.heading}
    font-size: ${({ theme }) => theme.fontSize.h3}px;
`;

const TextContentContainer = styled(Flex)`
    max-width: 580px;
`;
