import { CONTACT_AGENT, ContactAgentActionType } from "./types";

export const contactAgentReducer = (
    state = {},
    action: ContactAgentActionType
) => {
    switch (action.type) {
        case CONTACT_AGENT.REQUEST:
        case CONTACT_AGENT.SUCCESS:
        case CONTACT_AGENT.FAILURE:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
