import { SIMILARHOMES, SimilarHomesActionType } from "./types";

export const requestSimilarHomes = (query: string): SimilarHomesActionType => ({
  type: SIMILARHOMES.REQUEST,
  payload: {
    loading: true,
    query: query,
    error: null,
  },
});

export const receiveSimilarHomesSuccess = (
  similarHomes: any
): SimilarHomesActionType => ({
  type: SIMILARHOMES.SUCCESS,
  payload: {
    loading: false,
    query: null,
    data: similarHomes,
    error: null,
  },
});

export const receiveSimilarHomesFailure = (
  error: string
): SimilarHomesActionType => ({
  type: SIMILARHOMES.FAILURE,
  payload: {
    loading: false,
    query: null,
    data: null,
    error: error,
  },
});
