import { put, takeLatest, call } from "redux-saga/effects";
import { USERINFO, UserInfoActionType } from "./types";
import { fetchUserInfo } from "./services";
import get from "lodash.get";
import {
    receiveUserInfoSuccess,
    receiveUserInfoFailure,
} from "./actions";

function* getUserInfo(action: UserInfoActionType) {
    try {
        const response = yield call(fetchUserInfo, action.payload.query);
        if (response) {
            yield put(receiveUserInfoSuccess(response));
        } else {
            yield put(
                receiveUserInfoFailure(
                    "ERROR: Failed to fetch User Info response"
                )
            );
        }
    } catch (error) {
        yield put(
            receiveUserInfoFailure(get(error, "message", "Network Error!"))
        );
    }
}

export function* watchGetUserInfoRequest() {
    yield takeLatest(USERINFO.REQUEST, getUserInfo);
}
