import { useState, useEffect } from "react";
import { requestLogin } from "src/redux/modules/Login";
import { useDispatch } from "react-redux";
import { cleanUserInfo } from "src/redux/modules/UserInfo";
import { cleanSignup } from "src/redux/modules/Signup";

function TokenReset() {
    const [tokenLastClearDate, setTokenLastClearDate] = useState<number | null>(
        localStorage.getItem("tokenLastClearDate")
            ? new Date(localStorage.getItem("tokenLastClearDate")!).getTime()
            : null,
    );

    const dispatch = useDispatch();

    // handle route change when token last clear date is expired / never set
    useEffect(() => {
        const resetToken = async () => {
            // log user out when refresh token is expired
            await dispatch(
                requestLogin({
                    endpoint: "signout",
                    body: "body",
                    method: "POST",
                }),
            );

            // set token last clear date
            const currentDate = new Date();
            setTokenLastClearDate(currentDate.getTime());
            localStorage.setItem("tokenLastClearDate", currentDate.toString());

            // remove token info and do a full cleanup(copied from log in code)
            localStorage.removeItem("refresh_token");
            localStorage.removeItem("_MN_Token");
            dispatch(cleanUserInfo());
            dispatch(cleanSignup());
        };

        if (!tokenLastClearDate) {
            // Clear local storage item for the first time, i.e. tokenLastClearDate is null
            resetToken();
        } else {
            const currentDate = new Date().getTime();
            const timeDiff = currentDate - tokenLastClearDate;
            const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

            if (daysDiff >= 15) {
                resetToken();
            }
        }
    }, [tokenLastClearDate, dispatch]);

    return null;
}

export default TokenReset;
