export const eRequirement: RegExp = /\S+@\S+\.\S+/;
export const pRequirement: RegExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;
export const tRequirement: RegExp = /^[a-zA-Z]+$/;
export const phoneRequirement: RegExp = /\+?1?\s*\(?-*\.*(\d{3})\)?\.*-*\s*(\d{3})\.*-*\s*(\d{4})$/;
export const nameRequirement: RegExp = /^[^0-9]+$/;

export const numberOnlyCheck: RegExp = /^[0-9]{10,11}$/;

export const moneyFormat: RegExp = /\B(?=(\d{3})+(?!\d))/g;
export const moneyParsers: RegExp = /\$\s?|(,*)/g;
// export const phoneRequirement: RegExp = /^[0-9]*/;
