import { USERINFO, UserInfoActionType } from "./types";

export const requestUserInfo = (query: any): UserInfoActionType => ({
    type: USERINFO.REQUEST,
    payload: {
        loading: true,
        query: query,
        error: null,
    },
});

export const receiveUserInfoSuccess = (userinfo: any): UserInfoActionType => ({
    type: USERINFO.SUCCESS,
    payload: {
        loading: false,
        query: null,
        data: userinfo,
        error: null,
    },
});

export const receiveUserInfoFailure = (error: string): UserInfoActionType => ({
    type: USERINFO.FAILURE,
    payload: {
        loading: false,
        query: null,
        data: null,
        error: error,
    },
});

export const cleanUserInfo = (): UserInfoActionType => ({
    type: USERINFO.CLEAN,
    payload: {
        loading: false,
        query: null,
        data: null,
    },
});
