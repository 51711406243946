import { useSelector as useReduxSelector, TypedUseSelectorHook } from "react-redux";
import { ListingsState } from "src/redux/modules/Listings";
import { ListingClustersState } from "src/redux/modules/Clusters";
import { CalculatorState } from "src/redux/modules/MortgageCalculator";
import { ContactUsState } from "src/redux/modules/ContactUs";
import { ContactAgentState } from "src/redux/modules/ContactAgent";
import { SignupState } from "src/redux/modules/Signup";
import { LoginState } from "src/redux/modules/Login";
import { UserInfoState } from "src/redux/modules/UserInfo";
import { LocaleState } from "src/redux/modules/Locale";
import { SearchState } from "../modules/Search";
import { SimilarHomesState } from "../modules/SimilarHomes";
import { DevelopmentState } from "../modules/Development";
import { Development_SearchState } from "../modules/DevelopmentSearch";
import { School_SearchState } from "../modules/SchoolSearch";
import { School_ListingsState } from "../modules/SchoolFetch";
import { SettingsState } from "../modules/Setting/types";
import { CondoState } from "../modules/Condo";
import { AssignmentState } from "../modules/Assignment";
interface RootState {
    developmentClusters?: ListingClustersState;
    schoolListings?: School_ListingsState;
    schoolSearch?: School_SearchState;
    listings?: ListingsState;
    clusters?: ListingClustersState;
    calculation?: CalculatorState;
    contactUs?: ContactUsState;
    contactAgent?: ContactAgentState;
    signup?: SignupState;
    login?: LoginState;
    userInfo?: UserInfoState;
    search?: SearchState;
    similarHomes?: SimilarHomesState;
    locale?: LocaleState;
    development?: DevelopmentState;
    developmentSearch?: Development_SearchState;
    settings?: SettingsState;
    condo?: CondoState;
    assignment?: AssignmentState;
}

const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export default useSelector;
