import { Button, Form, Input } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { requestLogin } from "src/redux/modules/Login";
import { dataType } from "src/types/authentication.type";
import { eRequirement, pRequirement } from "src/utils/regCheck";
import intl from "react-intl-universal";
import styled from "styled-components";
import Spacer from "src/components/base/Spacer";
import useSelector from "src/redux/store/useSelector";
import useGoogleAnalytics, { Category } from "src/utils/GoogleAnalytics";
import { Flex } from "src/components";

type ResetProps = {
    data: dataType;
};

const ResetPassword: React.FC<ResetProps> = ({ data }) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { sendEvent } = useGoogleAnalytics();

    const loading = useSelector(
        (state) => state.login?.loading || state.signup?.loading || state.userInfo?.loading,
    );

    const handleResetForm = async () => {
        const formValues = await form.validateFields();
        let { email, password, code } = formValues;

        sendEvent(Category.EDIT, `user interacts with reset pwd form: ${email}`, "ResetPassword");

        email = email.toLowerCase();
        dispatch(
            requestLogin({
                endpoint: "verifypassword",
                body: {
                    email: email,
                    newPassword: password,
                    code: code,
                },
                method: "POST",
            }),
        );
    };

    return (
        <Flex align="center" column>
            <Spacer height={8} />
            <SubTitle> {intl.get("authentication.reset.subtitle")}</SubTitle>
            <Spacer height={24} />
            <Form layout={"vertical"} form={form} style={{ width: "100%" }}>
                <Form.Item
                    name="email"
                    initialValue={data.email}
                    rules={[
                        {
                            required: true,
                            message: intl.get("authentication.warning.inputEmail"),
                        },
                        {
                            pattern: eRequirement,
                            message: intl.get("authentication.warning.inputValidEmail"),
                        },
                    ]}
                >
                    <Input
                        size="large"
                        placeholder={intl.get("authentication.shared.email")}
                        value={data.email}
                    />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: intl.get("authentication.warning.inputPW"),
                        },
                        {
                            pattern: pRequirement,
                            message: intl.get("authentication.warning.inputValidPW"),
                        },
                    ]}
                >
                    <Input.Password
                        size="large"
                        placeholder={intl.get("authentication.shared.pw")}
                    />
                </Form.Item>
                <Form.Item
                    name="rpassword"
                    dependencies={["password"]}
                    rules={[
                        { required: true, message: intl.get("authentication.warning.retypePW") },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue("password") === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(
                                    new Error(intl.get("authentication.warning.pwNotMatch")),
                                );
                            },
                        }),
                    ]}
                >
                    <Input.Password
                        size="large"
                        placeholder={intl.get("authentication.shared.retypePW")}
                    />
                </Form.Item>
                <Form.Item
                    name="code"
                    rules={[
                        {
                            required: true,
                            message: intl.get("authentication.warning.code"),
                        },
                    ]}
                >
                    <Input size="large" placeholder={intl.get("authentication.shared.code")} />
                </Form.Item>

                <Form.Item shouldUpdate>
                    {() => (
                        <Button
                            loading={loading}
                            htmlType="submit"
                            block
                            type="primary"
                            size="large"
                            onClick={handleResetForm}
                            disabled={
                                !form.isFieldTouched(["password"]) ||
                                !form.isFieldTouched(["rpassword"]) ||
                                !form.isFieldTouched(["code"]) ||
                                !!form.getFieldsError().filter(({ errors }) => errors.length).length
                            }
                        >
                            {intl.get("authentication.shared.resetPW")}
                        </Button>
                    )}
                </Form.Item>
            </Form>
        </Flex>
    );
};

const SubTitle = styled.p`
    font-family: ${({ theme }) => theme.fontFamily.body};
    ${({ theme }) => theme.styleGroup.body}
    font-style: normal;
    font-weight: 300;
    font-size: ${({ theme }) => theme.fontSize.bulletTitle}px;
    align-items: center;
    text-align: center;
    color: ${({ theme }) => theme.color.blackPrimary};
`;

export default ResetPassword;
