import { Button } from "antd";
import styled from "styled-components";

export const StyledButton = styled(Button)`
    width: 30%;
    font-size: ${({ theme }) => theme.fontSize.button}px;
    background-color: ${({ theme }) => theme.color.manorleadGold};
    color: ${({ theme }) => theme.color.white};
    padding: 16px;
    text-transform: capitalize;
    font-family: ${({ theme }) => theme.fontFamily.body};
    ${({ theme }) => theme.styleGroup.button}
    font-style: normal;
    border-radius: 8px;
    min-width: 100%;
    max-width: 450px;
`;

export default StyledButton;
