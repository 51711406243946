import { put, takeLatest } from "redux-saga/effects";
import * as Effects from "redux-saga/effects";
import { LISTINGS, ListingsActionType } from "./types";
import { fetchListings } from "./services";
import get from "lodash.get";
import { receiveListingsSuccess, receiveListingsFailure } from "./actions";

const call: any = Effects.call;

function* getListings(action: ListingsActionType) {
    try {
        const data = yield call(fetchListings, action.payload.query);
        if (data) {
            yield put(receiveListingsSuccess(data, action.payload.query));
        } else {
            yield put(receiveListingsFailure("ERROR: Failed to fetch residential listings"));
        }
    } catch (error) {
        yield put(receiveListingsFailure(get(error, "message", "Network Error!")));
    }
}

export function* watchGetListingsRequest() {
    yield takeLatest(LISTINGS.REQUEST, getListings);
}
