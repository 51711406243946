import { Col, Row } from "antd";
import React from "react";
import styled from "styled-components";
import intl from "react-intl-universal";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

import { Spacer } from "src/components/base";
import { HomePageContentWrapper } from "../Shared";
import { HomePageDot, BlueDots, MobileScreens, GooglePlay, AppStore } from "src/static";
import { getClientOs, ClientOs } from "src/utils";

export const AppSection = () => {
    const bp = useBreakpoint();

    const showGoogle = getClientOs() !== ClientOs.IOS;
    const showApple = getClientOs() !== ClientOs.ANDROID;

    const openStore = (name: string) => {
        if (name === "googlePlay") {
            window.open("https://play.google.com/store/apps/details?id=com.manorlead.manorlead");
        } else {
            window.open("https://apps.apple.com/ca/app/manorlead/id1573251344");
        }
    };

    return (
        <AppContentWrapper bp={bp}>
            <Row align="middle" gutter={[40, 0]} justify="space-between">
                <Col xs={24} sm={24} md={12}>
                    <Title bp={bp}>{intl.get("homePage.appSection.title")}</Title>
                    <Spacer height={16} />
                    <Description bp={bp}>{intl.get("homePage.appSection.description")}</Description>
                    <Spacer height={16} />
                    {showApple && (
                        <StoreImg
                            src={AppStore}
                            alt="App Store"
                            onClick={() => openStore("appStore")}
                            bp={bp}
                        />
                    )}
                    {showGoogle && (
                        <StoreImg
                            src={GooglePlay}
                            alt="Google Play"
                            onClick={() => openStore("googlePlay")}
                            bp={bp}
                        />
                    )}
                </Col>
                <Col xs={24} sm={24} md={10}>
                    {bp.md ? (
                        <>
                            <YellowDotImg src={HomePageDot} alt="dots" />
                            <DotImg src={BlueDots} alt="dots" />
                        </>
                    ) : (
                        <Spacer height={32} />
                    )}

                    <PhoneImg src={MobileScreens} alt="phone" bp={bp} />
                </Col>
            </Row>
        </AppContentWrapper>
    );
};

const Title = styled.div`
    font-family: ${({ theme }) => theme.fontFamily.homeHeading};
    ${({ theme }) => theme.styleGroup.heading}
    font-size: ${(props: { bp; theme }) => (props.bp.xs ? `${props.theme.fontSize.h3}px` : `48px`)};
    line-height: 108%;
`;

const Description = styled.div`
    font-family: ${({ theme }) => theme.fontFamily.homeBody};
    ${({ theme }) => theme.styleGroup.body}
    font-size:${(props: { bp; theme }) =>
        props.bp.xs ? `${props.theme.fontSize.bodyS}px` : `${props.theme.fontSize.bodyL}px`};
    font-weight: 400;
`;

const StoreImg = styled.img`
    object-fit: contain;
    width: ${(props: { bp; theme }) => (props.bp.xs ? `124px` : `200px`)};
    margin: 8px;
    cursor: pointer;
`;

const DotImg = styled.img`
    object-fit: contain;
    position: absolute;
    top: 296px;
    left: -4vw;
    height: 200px;
    width: 200px;
    z-index: -1;
`;

const YellowDotImg = styled(DotImg)`
    top: 88px;
    left: 16vw;
`;

const PhoneImg = styled.img`
    object-fit: contain;
    height: ${(props: { bp }) => (props.bp.md ? `480px` : `auto`)};
    width: ${(props: { bp }) => (props.bp.md ? "100%" : "60%")};
`;

const AppContentWrapper = styled(HomePageContentWrapper)`
    text-align: ${(props: { bp }) => (props.bp.md ? "inherit" : "center")};
`;
