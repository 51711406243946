import React, { ComponentPropsWithoutRef } from "react";

const Divider: React.FC<ComponentPropsWithoutRef<"svg">> = ({ ...props }) => (
  <svg
    width="100%"
    height="5"
    viewBox="0 0 915 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
    {...props}
  >
    <rect width="100%" height="6" fill="#FFB800" />
  </svg>
);

export default Divider;
