import { put, takeLatest } from "redux-saga/effects";
import * as Effects from "redux-saga/effects";
import { LISTING_CLUSTERS, ListingClustersActionType } from "./types";
import { fetchListingClusters } from "./services";
import get from "lodash.get";
import { receiveListingClustersSuccess, receiveListingClustersFailure } from "./actions";

const call: any = Effects.call;

function* getListingClusters(action: ListingClustersActionType) {
    try {
        const data = yield call(fetchListingClusters, action.payload.query);
        const clusters = get(data, "clusters", null);
        if (clusters) {
            var east = clusters.reduce((a, b) => (a.avgLat > b.avgLat ? a : b));
            var west = clusters.reduce((a, b) => (a.avgLat < b.avgLat ? a : b));
            var north = clusters.reduce((a, b) => (a.avgLong > b.avgLong ? a : b));
            var south = clusters.reduce((a, b) => (a.avgLong < b.avgLong ? a : b));
            let bounds = {
                ne: { lat: north.avgLat, lng: east.avgLong },
                sw: { lat: south.avgLat, lng: west.avgLong },
            };

            yield put(receiveListingClustersSuccess({ clusters: clusters, bounds: bounds }));
        } else {
            yield put(
                receiveListingClustersFailure(
                    "ERROR: Failed to fetch residential listings cluster",
                ),
            );
        }
    } catch (error) {
        yield put(receiveListingClustersFailure(get(error, "message", "Network Error!")));
    }
}

export function* watchGetListingClustersRequest() {
    yield takeLatest(LISTING_CLUSTERS.REQUEST, getListingClusters);
}
