import { DEVELOPMENT, DevelopmentActionType } from "./types";

export const developmentReducer = (state = {}, action: DevelopmentActionType) => {
    switch (action.type) {
        case DEVELOPMENT.REQUEST:
        case DEVELOPMENT.SUCCESS:
        case DEVELOPMENT.FAILURE:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
