import React, { useEffect } from "react";
import { shallowEqual, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Navbar } from "src/components";
import AccessBlocker from "src/components/AccessBlocker";
import AuthBlocker from "src/components/AuthBlocker";
import PromotionHeader from "src/components/Navbar/PromotionHeader";
import { setLoginPopup } from "src/redux/modules/Setting";
import { authTitle, expired, IMAGE_AS_BACKGROUND_ROUTES, pathInBlockerWhiteList } from "src/utils";
import { useFrontendConfigFetch } from "src/utils/useFrontendConfigFetch";
import Authentication from "../../views/AuthenticationPage";
import { RouterSwitch } from "../../views/RouterSwitch";
import useSelector from "src/redux/store/useSelector";
import { AUTH_TITLE } from "src/utils/constants";
import RedirectToWWW from "src/services/redirect";
import TokenReset from "src/services/clearToken";
import FallbackPage from "./FallbackPage";
import ErrorBoundary, { ErrorStackType } from "./ErrorBoundary";
import useGoogleAnalytics from "src/utils/GoogleAnalytics";

export const BasicLayout = () => {
    const { pathname } = useLocation();

    const [logged, setLogged] = React.useState<boolean>(false);
    const { logError } = useGoogleAnalytics();
    const { savedConfigToLocalStorageSuccess } = useFrontendConfigFetch();
    const isImageAsBackground: boolean = IMAGE_AS_BACKGROUND_ROUTES.includes(pathname);
    const loginResponse = useSelector((state) => state.login?.data ?? [], shallowEqual);
    const userInfoResponse = useSelector((state) => state.userInfo?.data ?? false, shallowEqual);

    const dispatch = useDispatch();
    const dispatchAuth = (authTitle: string, popup: boolean) => {
        dispatch(setLoginPopup(popup, authTitle));
    };

    // Log error on trigger event
    const onErrorTrigger = (error: Error, errorStack: ErrorStackType) => {
        logError({
            description: error.message,
            fatal: true,
            traceback: errorStack.toString() ?? error.toString(),
        });
    };

    useEffect(() => {
        if (expired(loginResponse, userInfoResponse)) {
            if (!pathInBlockerWhiteList(pathname)) {
                dispatch(setLoginPopup(true, authTitle(AUTH_TITLE.LOGIN_OR_REGISTER), true));
            }
        }
    }, [loginResponse, userInfoResponse, pathname]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <ErrorBoundary FallbackPage={FallbackPage} onErrorTrigger={onErrorTrigger}>
            <TokenReset />
            <RedirectToWWW />
            {!logged && process.env.REACT_APP_TREB === "false" ? (
                <AccessBlocker logged={logged} onChange={setLogged} />
            ) : (
                <>
                    {savedConfigToLocalStorageSuccess && <PromotionHeader />}
                    <Navbar $isImageAsBackground={isImageAsBackground} dispatchAuth={dispatchAuth}>
                        <Authentication />
                        <AuthBlocker />
                        <RouterSwitch />
                    </Navbar>
                </>
            )}
        </ErrorBoundary>
    );
};
