import React, { CSSProperties } from "react";
import styled from "styled-components";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

interface IProps {
    children?: React.ReactNode;
    id?: string;
    backgroundStyle?: CSSProperties;
    containerStyle?: CSSProperties;
    className?: string;
    containerClassName?: string;
    onClick?: () => void;
}
export const ModuleContainer = (props: IProps) => {
    const {
        children,
        id,
        backgroundStyle,
        containerStyle,
        className,
        containerClassName,
        onClick,
    } = props;
    const bp = useBreakpoint();

    return (
        <Background style={backgroundStyle} id={id} className={className} onClick={onClick} bp={bp}>
            <Container style={containerStyle} className={containerClassName} bp={bp}>
                {children}
            </Container>
        </Background>
    );
};

const Background = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: ${(props: { bp }) => (props.bp.xl ? "0 120px 0 176px" : "0")};
`;

const Container = styled.div`
    max-width: ${(props: { bp }) => (props.bp.xxl ? "1224px" : "auto")};
    width: 100%;
`;
