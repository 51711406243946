import { put, takeLatest, call } from "redux-saga/effects";
import { LOGIN, LoginActionType } from "./types";
import { fetchLogin } from "./services";
import get from "lodash.get";
import { receiveLoginSuccess, receiveLoginFailure } from "./actions";

function* getLogin(action: LoginActionType) {
    try {
        const response = yield call(fetchLogin, action.payload.query);
        if (response) {
            let endpoint: string | null = action?.payload?.query?.endpoint;
            let hasToken: boolean = (endpoint !== null) && 
                                        (["signin", "verify"].includes(endpoint) || endpoint.includes('tokensignin'))
            let setExpiry: boolean = hasToken && !response.requireVerification;
            let result: any =
                !response.error && setExpiry
                    ? { ...response, expiry: Date.now() + response.cognitoJWT.expires_in * 1000 }
                    : response;
            yield put(receiveLoginSuccess(result));
        } else {
            yield put(receiveLoginFailure("ERROR: Failed to fetch Login response"));
        }
    } catch (error: any) {
        yield put(receiveLoginFailure(get(error, "data", { error: "Network Error", message: "Network Error!" }),)); 
    }
}

export function* watchGetLoginRequest() {
    yield takeLatest(LOGIN.REQUEST, getLogin);
}
