import { createStore, applyMiddleware } from "redux";

import createSagaMiddleware from "redux-saga";
import rootSaga from "src/redux/sagas";
import { composeWithDevTools } from "redux-devtools-extension";
import { loadState, saveState } from "./localStorage";
import { rootReducer } from "../reducers";

const sagaMiddleware = createSagaMiddleware();
const initialState = loadState();

const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(sagaMiddleware)),
);

store.subscribe(() => {
    saveState(store.getState());
});

sagaMiddleware.run(rootSaga);

export default store;
