import { Nullable } from "src/utils";

export enum CONTACT_US {
    REQUEST = "CONTACT_US_REQUEST",
    SUCCESS = "CONTACT_US_SUCCESS",
    FAILURE = "CONTACT_US_FAILURE",
    ERROR = "CONTACT_US_ERROR",
    INITIAL = "CONTACT_US_INITIAL",
}

export interface ContactUsState {
    loading: boolean;
    data?: any;
    error?: Nullable<string>;
    query?: Nullable<ContactUsQuery>;
}

export interface ContactUsActionType {
    type: CONTACT_US;
    payload: ContactUsState;
}

export interface ContactUsQuery {
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    message: string;
}
