import { message, Modal } from "antd";
import React, { useState } from "react";
import { AiFillCheckCircle } from "react-icons/ai";
import intl from "react-intl-universal";
import { shallowEqual, useDispatch } from "react-redux";
import { Flex, Spacer } from "src/components";
import { StyledTitle } from "src/components/AuthenticationPage";
import Logo from "src/components/Navbar/Logo";
import { requestLogin } from "src/redux/modules/Login";
import { setLoginPopup } from "src/redux/modules/Setting/actions";
import { cleanSignup } from "src/redux/modules/Signup/actions";
import { requestUserInfo } from "src/redux/modules/UserInfo";
import useSelector from "src/redux/store/useSelector";
import { theme } from "src/theme/theme";
import useGoogleAnalytics, { Category } from "src/utils/GoogleAnalytics";
import {
    dataType,
    loginResponseFailed,
    signupResponseFailed,
    signupResponseSuccess,
} from "src/types/authentication.type";
import { authTitle, expired, pathInBlockerWhiteList } from "src/utils";
import { AUTH_TITLE } from "src/utils/constants";
import ForgetPassword from "src/views/AuthenticationPage/ForgetPassword";
import Login from "src/views/AuthenticationPage/Login";
import ResetPassword from "src/views/AuthenticationPage/ResetPassword";
import Signup from "src/views/AuthenticationPage/Signup";
import SignupInfo from "src/views/AuthenticationPage/SignupInfo";
import Verification from "src/views/AuthenticationPage/Verification";
import styled from "styled-components";
import Successful from "./Successful";
import RegisterOrLoginContent from "./RegisterOrLogin";
import { client } from "src/services/client";
import { getCurrentDatetime } from "src/utils/time";
import { useSendEventBHPP } from "src/components/BHPP/Shared/useSendEventBHPP";
import { HomeIcon } from "src/static";
import { useHistory, useLocation } from "react-router-dom";

const defaultData: dataType = {
    email: "",
    password: "",
    code: "",
};

const Authentication = () => {
    const [data, setData] = React.useState<dataType>(defaultData);
    const dispatch = useDispatch();
    const history = useHistory();
    const { pathname } = useLocation();
    const [title, setTitle] = useState(authTitle(AUTH_TITLE.LOGIN));
    const [isBlocker, setIsBlocker] = useState(false);
    const [loginRequested, setLoginRequested] = useState(false);
    const [lastUnblockedLocation, setLastUnblockedLocation] = React.useState<string>("/");
    const loginResponse = useSelector((state) => state.login?.data ?? [], shallowEqual);
    const signupResponse = useSelector((state) => state.signup?.data ?? [], shallowEqual);
    const userInfoResponse = useSelector((state) => state.userInfo?.data ?? [], shallowEqual);
    const settingsResponse = useSelector((state) => state.settings?.login ?? []);
    const { sendEventBHPP } = useSendEventBHPP();
    const { sendEvent } = useGoogleAnalytics();

    React.useEffect(() => {
        const token = loginResponse?.cognitoJWT?.access_token;
        // if jwt not expired and login didn't result in error
        if (!expired(loginResponse, userInfoResponse) && !loginResponse.error) {
            dispatch(
                requestUserInfo({ Authorization: `Bearer ${token}`, endpoint: "", method: "GET" }),
            );
            loginResponse.userProfile?.firstName
                ? dispatch(setLoginPopup(false))
                : setTitle(authTitle(AUTH_TITLE.SIGNUPINFO));

            if (loginResponse?.cognitoJWT?.refresh_token) {
                localStorage.setItem("refresh_token", loginResponse?.cognitoJWT?.refresh_token);
            }
            if (loginRequested) {
                setLoginRequested(false);
                sendEvent(
                    Category.SUBMIT,
                    `user logged in with success. ID: ${
                        loginResponse.userProfile?.profileId
                    }; web page URL: ${
                        window.location.pathname
                    }; timestamp: ${getCurrentDatetime()}; user-agent: ${
                        window.navigator.userAgent
                    }`,
                    "Authentification Index",
                );
            }
            // BHPP GA
            const BHPPAccessFrom = localStorage.getItem("_MN_BHPP_SOURCE");
            if (BHPPAccessFrom && !localStorage.getItem("_MN_BHPP_LOGIN")) {
                sendEventBHPP(
                    Category.Other,
                    `User login after view BHPP.`,
                    "BHPP_Traffic",
                    loginResponse.userProfile?.profileId,
                );
                localStorage.setItem("_MN_BHPP_LOGIN", "true");
            }
            // if login resulted in error
        } else if (
            (loginResponse as loginResponseFailed) &&
            loginResponse.error &&
            loginResponse.path === "/auth/signin"
        ) {
            message.error({
                content: intl.get("authentication.signin.failure"),
                duration: 3,
                style: {
                    marginTop: "5vh",
                },
                key: "loginError",
            });
            // if verification is required
        } else if (loginResponse.requireVerification) {
            resendVerifyDispatch();
            message.error({
                content: intl.get("authentication.verification.verNeeded"),
                duration: 10,
                style: {
                    marginTop: "5vh",
                },
            });
            setTitle(authTitle(AUTH_TITLE.VERIFICATION));
            // This means user requested to reset password and it was successful, need to update backend to send proper response
        } else if (loginResponse.profileId) {
            if (title === authTitle(AUTH_TITLE.RESET)) {
                setTitle(authTitle(AUTH_TITLE.SUCCESS_RESET));
            } else {
                if (loginResponse.loginType === "Email") {
                    setTitle(authTitle(AUTH_TITLE.RESET));
                } else {
                    message.error({
                        content: intl.get("authentication.forgotPassword.errorNotEmail"),
                        duration: 10,
                        style: {
                            marginTop: "5vh",
                        },
                    });
                }
            }
        }
    }, [loginResponse]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if ((signupResponse as signupResponseSuccess).email) {
            setTitle(authTitle(AUTH_TITLE.VERIFICATION));
            sendEvent(
                Category.SUBMIT,
                `user sign up with success: ${signupResponse.email}`,
                "Authentification Index",
            );
        } else if ((signupResponse as signupResponseFailed) && signupResponse.error) {
            message.error({
                content: signupResponse.message,
                duration: 10,
                style: {
                    marginTop: "5vh",
                },
            });
        }
    }, [signupResponse]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        setData(defaultData);
    }, [settingsResponse.loginPopup]);

    React.useEffect(() => {
        setTitle(settingsResponse.loginTitle);
    }, [settingsResponse.loginTitle]);

    React.useEffect(() => {
        setIsBlocker(settingsResponse.isBlocker);
    }, [settingsResponse.isBlocker]);

    React.useEffect(() => {
        if (pathInBlockerWhiteList(pathname) && !pathname.includes("/listing")) {
            setLastUnblockedLocation(pathname);
        }
    }, [pathname]);

    const handleLoginForm = (body: { email: string; password: string }) => {
        sendEvent(Category.EDIT, `user interacts with login form`, "Authentification Index");
        body.email = body.email.toLowerCase();
        setData({
            ...data,
            email: body.email,
            password: body.password,
        });
        message.destroy("loginError");
        setLoginRequested(true);
        dispatch(requestLogin({ endpoint: "signin", body: body, method: "POST" }));
    };

    const verifyDispatch = () => {
        sendEvent(
            Category.EDIT,
            `user interacts with verifcation code: ${data.email}`,
            "Authentification Index",
        );

        let body: {
            email: string;
            password: string;
            code: string;
        } = {
            email: data.email,
            password: data.password,
            code: data.code,
        };

        dispatch(requestLogin({ endpoint: "verify", body: body, method: "POST" }));
    };

    const resendVerifyDispatch = () => {
        sendEvent(
            Category.SUBMIT,
            `user requests re-send of verification code, ${data.email}`,
            "Authentification Index",
        );

        dispatch(
            requestLogin({ endpoint: "verify/resend", body: { email: data.email }, method: "PUT" }),
        );
    };

    const fetchIfUserEmailExist = (email: string) => {
        sendEvent(
            Category.SUBMIT,
            `submit on login/register form, ${email}`,
            "Authentification Index",
        );

        client({
            method: "GET",
            url: `/auth/${email}/exists`,
            headers: { "Content-Type": "application/json" },
        })
            .then((response) => response.data)
            .then((user) => {
                setData((prevState) => {
                    return { ...prevState, email: email };
                });
                if (user.exists) {
                    dispatch(setLoginPopup(true, authTitle(AUTH_TITLE.LOGIN)));
                } else {
                    dispatch(setLoginPopup(true, authTitle(AUTH_TITLE.SIGNUP)));
                }
            });
    };

    const handleModalClose = () => {
        dispatch(setLoginPopup(false, title));
        authTitle(AUTH_TITLE.LOGIN);
        dispatch(cleanSignup());
        if (isBlocker) {
            history.push(lastUnblockedLocation);
        }
    };

    return (
        <Modal
            centered
            visible={settingsResponse.loginPopup}
            onCancel={() => handleModalClose()}
            footer={null}
            maskClosable={false}
            closeIcon={isBlocker ? <HomeIcon /> : null}
        >
            <Container column justify="center" align="stretch">
                <Logo />
                {title === authTitle(AUTH_TITLE.SUCCESS_RESET) ||
                title === authTitle(AUTH_TITLE.SUCCESS_SIGNUP) ? (
                    <AiFillCheckCircle
                        style={{
                            color: theme.color.success,
                            fontSize: "80px",
                            marginTop: "40px",
                        }}
                    />
                ) : null}
                <Spacer height={40} />
                <StyledTitle text={title} />

                {title === authTitle(AUTH_TITLE.LOGIN_OR_REGISTER) && (
                    <RegisterOrLoginContent fetchIfUserEmailExist={fetchIfUserEmailExist} />
                )}
                {title === authTitle(AUTH_TITLE.SUCCESS_RESET) ||
                title === authTitle(AUTH_TITLE.SUCCESS_SIGNUP) ? (
                    <Successful setTitle={setTitle} title={title} />
                ) : null}
                {title === authTitle(AUTH_TITLE.LOGIN) ? (
                    <Login
                        handleLoginForm={handleLoginForm}
                        setTitle={setTitle}
                        setData={setData}
                        data={data}
                    />
                ) : null}
                {title === authTitle(AUTH_TITLE.FORGOT) ? (
                    <ForgetPassword setData={setData} setTitle={setTitle} data={data} />
                ) : null}
                {title === authTitle(AUTH_TITLE.RESET) ? <ResetPassword data={data} /> : null}
                {title === authTitle(AUTH_TITLE.SIGNUP) ? (
                    <Signup setData={setData} data={data} setTitle={setTitle} />
                ) : null}
                {title === authTitle(AUTH_TITLE.VERIFICATION) ? (
                    <Verification
                        verifyDispatch={verifyDispatch}
                        resendVerifyDispatch={resendVerifyDispatch}
                        setData={setData}
                    />
                ) : null}
                {title === authTitle(AUTH_TITLE.SIGNUPINFO) ? (
                    <SignupInfo setTitle={setTitle} />
                ) : null}
            </Container>
        </Modal>
    );
};

export default Authentication;

const Container = styled(Flex)`
    width: 100%;
    padding: 8px;
    padding-top: 30px;
    min-width: 240px;
    z-index: 777;
`;
