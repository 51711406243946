import { put, takeLatest } from "redux-saga/effects";
import { setLoginPopup, setBlocker } from "./actions";
import { SETTINGS, SettingsActionType } from "./types";

function setSettingState(action: SettingsActionType) {
    put(setBlocker(action.payload.blocker));
    put(setLoginPopup(action.payload.login));
}

export function* watchSetLoginPopupState() {
    yield takeLatest(SETTINGS.LOGIN_POPUP_SET_STATE, setSettingState);
}
