import { DevelopmentView } from "src/types/listing.type";
import { Nullable } from "src/utils";
import { DevelopmentSeachQuery } from ".";

export enum DEVELOPMENT_SEARCH {
    REQUEST = "DEVELOPMENT_SEARCH_REQUEST",
    SUCCESS = "DEVELOPMENT_SEARCH_SUCCESS",
    FAILURE = "DEVELOPMENT_SEARCH_FAILURE",
    ERROR = "DEVELOPMENT_SEARCH_ERROR",
}

export interface Development_SearchState {
    loading: boolean;
    data?: DevelopmentView[];
    error?: Nullable<string>;
    query?: DevelopmentSeachQuery;
}

export interface Development_SearchActionType {
    type: DEVELOPMENT_SEARCH;
    payload: Development_SearchState;
}
