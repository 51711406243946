import React from "react";
import styled from "styled-components";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { Collapse } from "antd";
import intl from "react-intl-universal";

import { Spacer, Text } from "src/components";
import { ContentWrapper } from "src/components";
import { useWindowSize } from "src/utils/useWindowSize";

const { Panel } = Collapse;

const OurServices = () => {
    const { windowWidth } = useWindowSize();
    const bp = useBreakpoint();

    return (
        <ContentWrapper style={{ padding: bp.xs ? "0px 10px" : "0px 20px" }}>
            <Spacer height={windowWidth < 600 ? 40 : 76} />
            <Title bp={bp}>{intl.get("homePage.ourServices.title")}</Title>
            <Spacer height={bp.xs ? 8 : 24} />
            <StyledCollapse expandIconPosition="right">
                <StyledPanel
                    header={intl.get("homePage.ourServices.newDevelopmentsHeader")}
                    key={1}
                    bp={bp}
                >
                    {intl.get("homePage.ourServices.newDevelopmentsContent")}
                </StyledPanel>
                <StyledPanel
                    header={intl.get("homePage.ourServices.residentialHeader")}
                    key={2}
                    bp={bp}
                >
                    {intl.get("homePage.ourServices.residentialContent")}
                </StyledPanel>
                <StyledPanel
                    header={intl.get("homePage.ourServices.condominiumsHeader")}
                    key={3}
                    bp={bp}
                >
                    {intl.get("homePage.ourServices.condominiumsContent")}
                </StyledPanel>
            </StyledCollapse>
            <Spacer height={windowWidth < 600 ? 40 : 86} />
        </ContentWrapper>
    );
};

export default OurServices;

const Title = styled(Text).attrs({ as: "h2" })`
    font-family: ${({ theme }) => theme.fontFamily.heading};
    ${({ theme }) => theme.styleGroup.heading}
    font-size: ${(props: { bp; theme }) =>
        props.bp.xs ? `${props.theme.fontSize.bulletTitle}px` : `${props.theme.fontSize.h2}px`};
    text-align: center;
`;

const StyledPanel = styled(Panel)`
    .ant-collapse-header {
        font-family: ${({ theme }) => theme.fontFamily.heading};
        font-style: normal;
        ${({ theme }) => theme.styleGroup.heading}
        font-size: ${(props: { bp; theme }) =>
            props.bp.xs ? `${props.theme.fontSize.h4}px` : `${props.theme.fontSize.h3}px`};
        padding-left: ${(props: { bp; theme }) => (props.bp.xs ? `${0}px` : `${16}px`)} !important;
    }

    .ant-collapse-content-box {
        padding-left: ${(props: { bp; theme }) => (props.bp.xs ? `${0}px` : `${16}px`)};
    }

    font-family: ${({ theme }) => theme.fontFamily.body};
    font-weight: normal;
    font-size: ${(props: { bp; theme }) =>
        props.bp.xs ? `${props.theme.fontSize.bodyS}px` : `${props.theme.fontSize.bodyM}px`};
    ${({ theme }) => theme.styleGroup.body}
    letter-spacing: 0.01em;
    margin-top: 10px;
`;

const StyledCollapse = styled(Collapse)`
    border: 0px;
    background-color: ${({ theme }) => theme.color.white};
    .ant-collapse-content {
        border: 0px;
    }

    .ant-collapse-item {
        border-bottom: 1px solid ${({ theme }) => theme.color.greySecondaryL};
    }
`;
