import { Nullable } from "src/utils";
import { client } from "src/services/client";

export const fetchSignup = async (query: Nullable<string>) =>
    client({
        method: "post",
        url: `/auth/signup`,
        headers: { "Content-Type": "application/json" },
        data: query,
    }).then((response) => response.data);
