import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useState } from "react";
import styled from "styled-components";
import intl from "react-intl-universal";
import { Slider } from "antd";

import { Line } from "src/components/base/Line";
import { ModuleContainer } from "src/components/base/ModuleContainer";
import { Flex, Spacer, Text } from "src/components/base";
import { parsePrice } from "src/utils/helpers";
import { theme } from "src/theme";

const SavingsSection = () => {
    const bp = useBreakpoint();
    const [homePrice, setHomePrice] = useState(800000);

    const savingsPrice = Math.floor(homePrice * 0.02);

    return (
        <div id="savingsSection">
            <YellowDiv>
                <ModuleContainer
                    containerStyle={{
                        padding: `0px 24px`,
                    }}
                >
                    <LineContainer justify="center" align="center">
                        <Line />
                    </LineContainer>
                    <Title bp={bp}>{intl.get("homePage.savings.title")}</Title>
                    <Description bp={bp}>{intl.get("homePage.savings.description")}</Description>
                    <Spacer height={120} />
                </ModuleContainer>
            </YellowDiv>
            <GreyDiv>
                <ModuleContainer
                    backgroundStyle={{
                        overflow: "inherit",
                    }}
                    containerStyle={{
                        padding: `0px 24px`,
                    }}
                >
                    <WhiteDiv>
                        <PriceRow align="baseline" justify="space-evenly">
                            <div>
                                {intl.get("homePage.savings.homePrice")}
                                {bp.xs ? <br /> : ":"}
                                <Price bp={bp}>{` $${parsePrice(homePrice)}`}</Price>
                            </div>
                            <div>
                                {intl.get("homePage.savings.savings")}
                                {bp.xs ? <br /> : ":"}
                                <YellowPrice bp={bp}>{` $${parsePrice(
                                    savingsPrice,
                                )}*`}</YellowPrice>
                            </div>
                        </PriceRow>
                        <Spacer height={40} />
                        <Slider
                            tipFormatter={null}
                            min={300000}
                            max={1500000}
                            marks={{
                                300000: (
                                    <PriceMark bp={bp}>
                                        {intl.get("homePage.savings.lowBound")}
                                    </PriceMark>
                                ),
                                1500000: (
                                    <PriceMark bp={bp}>
                                        {intl.get("homePage.savings.upBound")}
                                    </PriceMark>
                                ),
                            }}
                            onChange={setHomePrice}
                            value={homePrice}
                            handleStyle={{
                                backgroundColor: theme.color.manorleadGold,
                                border: `solid 6px ${theme.color.yellowPrimaryM}`,
                                height: 32,
                                width: 32,
                                marginTop: "-14px",
                            }}
                        />
                    </WhiteDiv>
                </ModuleContainer>
            </GreyDiv>
        </div>
    );
};

const LineContainer = styled(Flex)`
    margin: 40px 0;
`;

const Title = styled.div`
    font-family: ${({ theme }) => theme.fontFamily.homeHeading};
    ${({ theme }) => theme.styleGroup.heading}
    font-size: ${(props: { bp; theme }) =>
        props.bp.xs ? `${props.theme.fontSize.subtitle}px` : `${props.theme.fontSize.h1}px`};
    line-height: 1;
    margin-bottom: 0.5em;
    text-align: center;
`;

const Description = styled.div`
    font-family: ${({ theme }) => theme.fontFamily.homeBody};
    ${({ theme }) => theme.styleGroup.body}
    font-size: ${(props: { bp; theme }) =>
        props.bp.xs ? `${props.theme.fontSize.bodyM}px` : `${props.theme.fontSize.bodyL}px`};
    line-height: 28px;
    text-align: center;
`;

const YellowDiv = styled.div`
    background-color: ${({ theme }) => theme.color.yellowPrimaryL};
`;

const GreyDiv = styled.div`
    background-color: #f3f8ff;
`;

const WhiteDiv = styled.div`
    width: 100%;
    background-color: ${({ theme }) => theme.color.white};
    transform: translateY(-35%);
    padding: 40px;
`;

const Price = styled(Text).attrs({ as: "span" })`
    font-family: ${({ theme }) => theme.fontFamily.homeHeading};
    font-size: ${(props: { bp; theme }) =>
        props.bp.xs ? `${props.theme.fontSize.h3}px` : `${props.theme.fontSize.h2}px`};
    font-weight: 400;
`;

const YellowPrice = styled(Price)`
    color: ${({ theme }) => theme.color.manorleadGold};
`;

const PriceMark = styled.div`
    font-family: ${({ theme }) => theme.fontFamily.homeBody};
    ${({ theme }) => theme.styleGroup.body}
    font-size: ${(props: { bp; theme }) =>
        props.bp.xs ? `${props.theme.fontSize.bodyS}px` : `${props.theme.fontSize.bodyL}px`};
    color: ${({ theme }) => theme.color.black};
`;

const PriceRow = styled(Flex)`
    font-family: ${({ theme }) => theme.fontFamily.homeBody};
    ${({ theme }) => theme.styleGroup.body}
    font-size: ${({ theme }) => theme.fontSize.bodyS}px;
    font-weight: 700;
    text-align: center;
    line-height: 220%;
    color: #1b1642;
`;

export default SavingsSection;
