import { put, takeLatest, call } from "redux-saga/effects";
import { SCHOOL_SEARCH, School_SearchActionType } from "./types";
import { searchSchool } from "./services";
import { receiveSchoolSearchSuccess, receiveSchoolSearchFailure } from ".";
import get from "lodash.get";
import { SchoolView } from "src/types/school.type";

function* getListings(action: School_SearchActionType) {
    try {
        const data = yield call(searchSchool, action.payload.query);
        const listings: SchoolView[] = get(data, "schools", null);
        if (listings) {
            yield put(receiveSchoolSearchSuccess(listings, action.payload.query));
        } else {
            yield put(receiveSchoolSearchFailure("ERROR: Failed to fetch school data"));
        }
    } catch (error) {
        yield put(receiveSchoolSearchFailure(get(error, "message", "Network Error!")));
    }
}

export function* watchGetSchoolSearchRequest() {
    yield takeLatest(SCHOOL_SEARCH.REQUEST, getListings);
}
