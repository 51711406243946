import { Button, Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import intl from "react-intl-universal";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import { fetchDevelopment } from "src/redux/modules/Development";
import { Line } from "src/components/base/Line";
import { HomePageContentWrapper } from "../Shared";
import { DevelopmentCard } from "./DevelopmentCard";
import { DevelopmentView } from "src/types/listing.type";
import { Spacer } from "src/components/base";
import { buildDevUrl } from "src/utils/helpers";
import Carousel from "react-multi-carousel";
import { exclusiveListingsResponsive } from "src/utils";

export const DevelopmentSection = () => {
    const bp = useBreakpoint();
    const history = useHistory();

    const [cards, setCards] = useState<DevelopmentView[] | null>();
    const [developmentNum, setDevelopmentNum] = useState(50);

    const deferredFetch = async () => {
        try {
            const data = await fetchDevelopment({
                filters: `orderBy=priority:ASC&count=8&showHidden=false`,
            });
            setCards(data.developments);
            setDevelopmentNum(Number(data.page.totalCount));
        } catch (err) {
            console.error("ERROR: ", err);
        }
    };

    useEffect(() => {
        deferredFetch();
    }, []);

    const handleViewMore = () => {
        history.push({
            pathname: `/development`,
        });
    };

    const handleClick = (development: DevelopmentView) => {
        history.push({
            pathname: buildDevUrl(development),
        });
    };

    const cardList = cards && (
        <Row gutter={[16, 16]}>
            {cards.slice(0, 8).map((r) => {
                return (
                    <Col key={r.id} xs={24} sm={12} md={12} lg={6}>
                        <DevelopmentCard development={r} route={handleClick} />
                    </Col>
                );
            })}
        </Row>
    );

    const cardCarousel = cards && (
        <Carousel
            draggable={false}
            responsive={exclusiveListingsResponsive}
            partialVisible={true}
            renderButtonGroupOutside={true}
            transitionDuration={500}
        >
            {cards.map((r) => (
                <CardContainer key={r.id}>
                    <DevelopmentCard development={r} route={handleClick} />
                </CardContainer>
            ))}
        </Carousel>
    );

    return (
        <>
            <HomePageContentWrapper>
                <Line />
                <Spacer height={16} />
                <Row justify="space-between" align="middle" gutter={[0, 16]}>
                    <div>
                        <Title bp={bp}>{"8 " + intl.get("homePage.newDev.title")}</Title>
                        <Spacer height={16} />
                        <SubTitle bp={bp}>{intl.get("homePage.newDev.description")}</SubTitle>
                    </div>
                    <StyledButton type="primary" size="large" onClick={handleViewMore}>
                        {`${intl.get("homePage.newDev.viewAll1")}${developmentNum}${intl.get(
                            "homePage.newDev.viewAll2",
                        )}`}
                    </StyledButton>
                </Row>
                <Spacer height={16} />
                {bp.lg ? cardList : cardCarousel}
            </HomePageContentWrapper>
        </>
    );
};

const Title = styled.div`
    font-family: ${({ theme }) => theme.fontFamily.homeHeading};
    ${({ theme }) => theme.styleGroup.body}
    font-size: ${(props: { bp; theme }) =>
        props.bp.xs ? `${props.theme.fontSize.h3}px` : `${props.theme.fontSize.cardtitle}px`};
    line-height: 1;
`;

const SubTitle = styled.div`
    font-family: ${({ theme }) => theme.fontFamily.homeBody};
    ${({ theme }) => theme.styleGroup.body}
    font-size: ${(props: { bp; theme }) =>
        props.bp.xs ? `${props.theme.fontSize.bodyS}px` : `${props.theme.fontSize.bodyM}px`};
    font-weight: 400;
`;

const StyledButton = styled(Button)`
    border-radius: 8px;
    color: ${({ theme }) => theme.color.greySecondaryD};
    background-color: ${({ theme }) => theme.color.manorleadGold};
    font-family: ${({ theme }) => theme.fontFamily.homeBody};

    :hover {
        background-color: ${({ theme }) => theme.color.manorleadGold};
        color: ${({ theme }) => theme.color.greySecondaryD};
        filter: brightness(95%);
    }
`;

const CardContainer = styled.div`
    margin-right: 16px;
`;
