import React, { ComponentPropsWithoutRef, ReactElement } from "react";
import styled from "styled-components";
import { hoverStyles, disabledStyles } from "src/theme";

import Text from "../Text";

export type ButtonProps = ComponentPropsWithoutRef<"button"> & { text: string | ReactElement };

export const RawButton = styled.button`
    background: transparent;
    padding: 0;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 8px;
`;

export const StyledButton = styled.button`
    background-color: ${({ theme }) => theme.color.manorleadGold};
    border: none;
    padding: 8px 16px;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    border-radius: 8px;
    ${hoverStyles}
    ${disabledStyles}
`;

export const StyledButtonGrey = styled.button`
    background-color: #151515s;
    border: none;
    padding: 12px 0;
    font: inherit;
    color: #ffffff;
    cursor: pointer;
    outline: inherit;
    border-radius: 8px;
    ${hoverStyles}
    ${disabledStyles}
`;

StyledButton.displayName = "StyledButton";

const StyledText = styled(Text)`
    font-family: ${({ theme }) => theme.fontFamily.body};
    ${({ theme }) => theme.styleGroup.button}
    font-size: ${({ theme }) => theme.fontSize.bodyM}px;
`;

const Button: React.FC<ButtonProps> = ({ text, ...rest }) => (
    <StyledButton {...rest}>
        <StyledText>{text}</StyledText>
    </StyledButton>
);

export default Button;
