import { Nullable } from "src/utils";

export enum CALCULATORS {
    REQUEST = "CALCULATORS_REQUEST",
    SUCCESS = "CALCULATORS_SUCCESS",
    FAILURE = "CALCULATORS_FAILURE",
    ERROR = "CALCULATORS_ERROR",
}

export interface CalculatorState {
    loading: boolean;
    data?: any;
    error?: Nullable<string>;
    query?: { [key: string]: number } | null;
}

export interface CalculationActionType {
    type: CALCULATORS;
    payload: CalculatorState;
}
