import { Card, Row, Col, Badge, Typography } from "antd";
import _startCase from "lodash/startCase";
import React from "react";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import styled from "styled-components";
import intl from "react-intl-universal";

import { capitalize, parsePrice, getIntlOrRaw } from "src/utils/helpers";
import { RoomData } from "src/types/listing.type";
import { Spacer } from "src/components/base";
import { BathtubIcon, RulerIcon } from "src/static";
import { displaySqft } from "src/utils/helpers";
import { MoveTagContainer } from "src/components/Development/Tag";
import { BiBed } from "react-icons/bi";
import { theme } from "src/theme";

const { Text } = Typography;

export const ListingCard = (props: { listing: RoomData; route: (id: string) => void }) => {
    const bp = useBreakpoint();
    const { listing, route } = props;

    const propertyOverview: [number, any, string?][] = [
        [
            0,
            <BiBed />,
            intl.get("listing.banner.bedrooms", {
                num: parseInt(listing.bedrooms) + parseInt(listing.extraBedrooms),
            }),
        ],
        [
            1,
            <BathtubIcon />,
            intl.get("listing.banner.bathrooms", { num: parseInt(listing.baths) }),
        ],
        [2, <RulerIcon />, displaySqft(parseInt(listing.sqftMin), parseInt(listing.sqftMax))],
    ];

    return (
        <StyledCard
            cover={
                <>
                    <StyledImg alt="property_image" src={listing.images[0]} />
                    <TagContainer>
                        {listing.hotTag && <StyledMoveTagContainer>HOT</StyledMoveTagContainer>}
                        {listing.listedByManorleadTag && (
                            <ExclusiveTagContainer>Exclusive</ExclusiveTagContainer>
                        )}
                        {listing.openHouseTag && (
                            <StyledMoveTagContainer>Open House</StyledMoveTagContainer>
                        )}
                        {listing?.virtualTourUrl?.virtualTourUrlType === "TOUR3D" && (
                            <VirtualTourTag>Virtual Tour</VirtualTourTag>
                        )}
                        {listing?.virtualTourUrl?.virtualTourUrlType === "TOURVIDEO" && (
                            <VirtualTourTag>Video Tour</VirtualTourTag>
                        )}
                    </TagContainer>
                </>
            }
            onClick={() => route(listing.id)}
            bp={bp}
        >
            <Price bp={bp}>{`C$${parsePrice(listing.price)}`}</Price>
            <Spacer height={8} />
            <Address bp={bp}>
                {_startCase(listing.streetAddress)}, <br />
                {getIntlOrRaw(`shared.municipality`, listing.city)},{" "}
                {intl.get(`shared.province.ON`)} {listing.postalCode}
            </Address>
            <Spacer height={8} />
            <StyledRow gutter={{ xs: 8, sm: 8 }}>
                {propertyOverview.map((data) => (
                    <StyledInfo key={data[0]}>
                        <IconDiv>{data[1]}</IconDiv>
                        <StyledText bp={bp}>{data[2]}</StyledText>
                    </StyledInfo>
                ))}
            </StyledRow>
            <Spacer height={8} />
            <StyledRow align="bottom">
                <Badge color={theme.color.manorleadGold} />
                <StyledBrokerageInfo>
                    {capitalize(`${listing.propertyType} | ${listing.brokerage}`, true)}
                </StyledBrokerageInfo>
            </StyledRow>
        </StyledCard>
    );
};

const StyledCard = styled(Card)`
    box-shadow: ${({ theme }) => theme.boxShadow.One};
    height: 100%;
    min-width: ${(props: { bp }) => (props.bp.xs ? "264px" : "328px")};
    cursor: pointer;
    .ant-card-body {
        padding: 16px;
    }
`;

const StyledRow = styled(Row)`
    width: 100%;
    flex-wrap: nowrap;
    overflow: hidden;
`;

const Price = styled.div`
    font-family: ${({ theme }) => theme.fontFamily.homeBody};
    ${({ theme }) => theme.styleGroup.body}
    font-size: ${(props: { bp; theme }) =>
        props.bp.xs ? `${props.theme.fontSize.bulletTitle}px` : `${props.theme.fontSize.h3}px`};
    font-weight: 500;
`;

const StyledBrokerageInfo = styled(Text)`
    font-family: ${({ theme }) => theme.fontFamily.heading};
    font-size: ${({ theme }) => theme.fontSize.bodyS}px;
    ${({ theme }) => theme.styleGroup.body};
    font-size: 400;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const Address = styled(Price)`
    font-size: ${(props: { bp; theme }) =>
        props.bp.xs ? `${props.theme.fontSize.bodyS}px` : `${props.theme.fontSize.bodyM}px`};

    opacity: 0.7;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const StyledImg = styled.img`
    height: 224px;
    width: 100%;
    position: relative;
    object-fit: cover;
`;

const TagContainer = styled.div`
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    top: 24px;
    left: 24px;
    font-family: ${({ theme }) => theme.fontFamily.body};
    font-size: ${({ theme }) => theme.fontSize.bodyS}px;
    ${({ theme }) => theme.styleGroup.body}
    font-weight: 400;
`;

const StyledMoveTagContainer = styled(MoveTagContainer)`
    height: 24px;
    margin: 0px;
`;

const ExclusiveTagContainer = styled(StyledMoveTagContainer)`
    background-color: ${({ theme }) => theme.color.manorleadGold};
    color: ${({ theme }) => theme.color.black};
`;

const VirtualTourTag = styled(StyledMoveTagContainer)`
    background-color: #5c4aa9;
`;

const StyledInfo = styled(Col)`
    text-align: center;
    white-space: nowrap;
`;

const IconDiv = styled.span`
    position: relative;
    top: 6px;
    svg {
        width: 24px;
        height: 24px;
    }
`;

const StyledText = styled.span`
    font-family: ${({ theme }) => theme.fontFamily.homeBody};
    ${({ theme }) => theme.styleGroup.body}
    font-size: ${(props: { bp; theme }) =>
        props.bp.xs ? `${props.theme.fontSize.bodyS}px` : `${props.theme.fontSize.bodyM}px`};
    margin-left: 8px;
    white-space: nowrap;
`;
