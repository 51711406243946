import { client } from "src/services/client";
import { DevelopmentView } from "src/types/listing.type";

export interface DevelopmentSeachQuery {
    searchText?: string;
    limit?: number;
}
export const searchDevelopment = (query?: DevelopmentSeachQuery) =>
    client
        .post<{ developments: DevelopmentView[] }>("/development/search", query)
        .then((res) => res.data);
