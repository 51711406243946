import React from "react";
import styled from "styled-components";
import { Spacer, Flex, Text } from "src/components";
import { ContentWrapper, SectionWrapper } from "src/components";
import { Section } from "../constants";
import { useWindowSize } from "src/utils/useWindowSize";
import { Col, Row } from "antd";
import ReactPlayer from "react-player";
import { VideoCover } from "src/static";
import Fade from "react-reveal/Fade";

import { BsPlay } from "react-icons/bs";

const VideoIntro = () => {
    const { windowWidth } = useWindowSize();

    return (
        <StyledSectionWrapper id={Section.BUY_SELL}>
            <ContentWrapper>
                <Spacer height={windowWidth < 600 ? 40 : 86} />
                <Row justify="center" align="middle" gutter={[{ lg: 68, xl: 120 }, 48]}>
                    <Col xs={24} md={24} lg={12}>
                        <TextContentContainer column>
                            <Fade bottom={true}>
                                <Subtitle>
                                    "My clients and I tested an aggressive price as a Private
                                    Exclusive on Compass. Another agent brought buyers to see the
                                    home and they submitted a full price, all cash offer within
                                    days. My clients said this was the easiest, no hassle sale
                                    they’ve experienced!"
                                </Subtitle>
                                <Spacer height={15} />
                                <FooterText>
                                    David S & Victoria H, Agents | San Francisco Bay Area
                                </FooterText>
                            </Fade>
                        </TextContentContainer>
                    </Col>
                    <Col xs={24} md={24} lg={12}>
                        <ReactPlayer
                            url="https://frontend-static-images.s3.amazonaws.com/Manorlead+Trailer+-+V04.mp4"
                            width={windowWidth < 700 ? "100%" : "697px"}
                            height={"300px"}
                            playing
                            controls
                            playIcon={<BsPlay className="play-icon" size="5em" color="#fff" />}
                            light={VideoCover}
                        />
                    </Col>
                </Row>
                <Spacer height={30} />
                <SmallDisclaimer>
                    This information is provided for informational purposes only and is not a
                    solicitation, recommendation, offer or promise to provide services. Compass is
                    not offering legal, financial or other professional advice. Compass reserves the
                    right to refuse, reject, or cancel the program for any reason at any time
                    without liability. Compass offers no guarantee or warranty of results. Subject
                    to additional terms and conditions.
                </SmallDisclaimer>

                <Spacer height={windowWidth < 600 ? 40 : 86} />
            </ContentWrapper>
        </StyledSectionWrapper>
    );
};

export default VideoIntro;

const SmallDisclaimer = styled(Text).attrs({ as: "p" })`
    font-family: ${({ theme }) => theme.fontFamily.body};
    font-weight: normal;
    ${({ theme }) => theme.styleGroup.body}
    font-size: 12px
    color:  ${({ theme }) => theme.color.greySecondaryL}
`;

const FooterText = styled(Text).attrs({ as: "h4" })`
    font-family: ${({ theme }) => theme.fontFamily.heading};
    ${({ theme }) => theme.styleGroup.heading}
    font-size: ${({ theme }) => theme.fontSize.h4}px;
`;

const StyledSectionWrapper = styled(SectionWrapper)`
    background-color: #fff;
    margin-left: 12px;
    margin-right: 12px;
    width: 100%;
`;

const Subtitle = styled(Text).attrs({ as: "h4" })`
    font-family: ${({ theme }) => theme.fontFamily.body};
    font-weight: normal;
    ${({ theme }) => theme.styleGroup.body}
    font-size: 20px;
`;

const TextContentContainer = styled(Flex)`
    max-width: 580px;
`;
