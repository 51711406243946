import { put, takeLatest, call } from "redux-saga/effects";
import { SIGNUP, SignupActionType } from "./types";
import { fetchSignup } from "./services";
import get from "lodash.get";
import { receiveSignupSuccess, receiveSignupFailure } from "./actions";

function* getSignup(action: SignupActionType) {
    try {
        const response = yield call(fetchSignup, action.payload.query);
        if (response) {
            yield put(receiveSignupSuccess(response));
        } else {
            yield put(receiveSignupFailure("ERROR: Failed to fetch Sign Up response"));
        }
    } catch (error) {
        yield put(
            receiveSignupFailure(
                get(error, "data", { error: "Network Error", message: "Network Error!" }),
            ),
        );
    }
}

export function* watchGetSignupRequest() {
    yield takeLatest(SIGNUP.REQUEST, getSignup);
}
