import { LISTING_CLUSTERS, ListingClustersActionType, ListingClustersQuery } from "./types";

export const requestListingClusters = (query: ListingClustersQuery): ListingClustersActionType => ({
    type: LISTING_CLUSTERS.REQUEST,
    payload: {
        loading: true,
        query: query,
        error: null,
    },
});

export const receiveListingClustersSuccess = (clusters: any): ListingClustersActionType => ({
    type: LISTING_CLUSTERS.SUCCESS,
    payload: {
        loading: false,
        query: null,
        data: clusters,
        error: null,
    },
});

export const receiveListingClustersFailure = (error: string): ListingClustersActionType => ({
    type: LISTING_CLUSTERS.FAILURE,
    payload: {
        loading: false,
        query: null,
        data: null,
        error: error,
    },
});
