import styled from "styled-components";
import { Button } from "antd";

export const ListingMenuButtonFull = styled(Button)`
    font-family: ${({ theme }) => theme.fontFamily.body};
    ${({ theme }) => theme.styleGroup.button};
    font-size: ${({ theme }) => theme.fontSize.button}px;
    font-weight: normal;
    margin-right: 8px;
    height: 32px;
    border: 0px;
    border-radius: 8px;

    :hover {
        filter: brightness(95%);
    }
`;

export const FullButtonIcon = styled.span`
    position: relative;
    top: 3px;
    left: 6px;
`;

export const ListingMenuButtonSmall = styled(ListingMenuButtonFull)`
    font-size: ${({ theme }) => theme.fontSize.bulletTitle}px;
    margin-right: 0px;
    width: 32px;
    border-radius: 2px;
`;

export const SmallButtonIcon = styled.span`
    position: relative;
    top: 0px;
    right: 8px;
`;
