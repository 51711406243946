import { DEVELOPMENT_SEARCH, Development_SearchActionType } from "./types";

export const developmentSearchReducer = (state = {}, action: Development_SearchActionType) => {
    switch (action.type) {
        case DEVELOPMENT_SEARCH.REQUEST:
        case DEVELOPMENT_SEARCH.SUCCESS:
        case DEVELOPMENT_SEARCH.FAILURE:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
