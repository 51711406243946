import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ListingMenuButtonFull } from "src/components/Shared/ListingMenuButton";
import intl from "react-intl-universal";
import { AUTH_TITLE } from "src/utils";

const LoginRegiButton = (props: { handleAuthClick: (title: AUTH_TITLE) => void }) => {
    const { handleAuthClick } = props;

    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 576);
        };

        handleResize();
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <StyledLoginRegisterButton
            type="primary"
            onClick={() => handleAuthClick(AUTH_TITLE.LOGIN_OR_REGISTER)}
        >
            {isMobile ? intl.get("navbar.login") : `${intl.get("navbar.login")}/${intl.get("navbar.register")}`}
        </StyledLoginRegisterButton>
    );
};

const StyledLoginRegisterButton = styled(ListingMenuButtonFull)`
    background: ${({ theme }) => theme.color.manorleadGold};
    color: ${({ theme }) => theme.color.blackPrimary};
    width: auto;
    font-family: ${({ theme }) => theme.fontFamily.homeBody};
    font-weight: 500;
    height: 40px;
    ${({ theme }) => theme.styleGroup.body}

    padding: 10px 20px;
    font-size: 16px;
    margin-right: 0px;

    @media (max-width: 576px) {
        padding: 5px 8px;
        font-size: 10px;
        height: 24px;
        position: absolute;
        right: 6px;
        top: 10px;
    }
`;

export default LoginRegiButton;
