import { Nullable } from "src/utils";
import { PageResponse, DevelopmentView } from "src/types/listing.type";

export enum DEVELOPMENT {
    REQUEST = "DEVELOPMENT_REQUEST",
    SUCCESS = "DEVELOPMENT_SUCCESS",
    FAILURE = "DEVELOPMENT_FAILURE",
    ERROR = "DEVELOPMENT_ERROR",
}

export interface DevelopmentState {
    loading: boolean;
    data?: DevelopmentView[] | null;
    page?: PageResponse;
    error?: Nullable<string>;
    query?: Nullable<{ [key: string]: string }>;
}

export interface DevelopmentActionType {
    type: DEVELOPMENT;
    payload: DevelopmentState;
}
