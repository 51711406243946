import { CONDO, CondoActionType } from "./types";

export const condoReducer = (state = {}, action: CondoActionType) => {
    switch (action.type) {
        case CONDO.REQUEST:
        case CONDO.SUCCESS:
        case CONDO.FAILURE:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
