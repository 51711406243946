import React, { lazy } from "react";
import styled from "styled-components";
import Dialogs from "../Shared/Dialogs";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { Text, Link } from "src/components";
import {
    AiTwotoneMail,
    AiFillFacebook,
    AiFillInstagram,
    AiFillTwitterSquare,
} from "react-icons/ai";
import {
    MARKETING_PAGE_ROUTES,
    SOCIAL_MEDIA_URLS,
    SOCIAL_MEDIA_PLATFORMS,
    MARKETING_PAGES,
} from "src/utils";
import { Layout, Row, Col } from "antd";
import intl from "react-intl-universal";
const PrivacyPolicy = lazy(() => import("src/utils/policy"));
const TermsAndConditions = lazy(() => import("src/utils/terms"));
const { Footer } = Layout;

const ContentWrapper = styled.div`
    margin-left: auto;
    margin-right: auto;
    max-width: 1224px;
    margin-top: 5px;
    width: 100%;
`;

const Title = styled(Text).attrs({ as: "h4" })`
    font-family: ${({ theme }) => theme.fontFamily.heading};
    ${({ theme }) => theme.styleGroup.heading}
    color: ${({ theme }) => theme.color.yellowPrimaryD};
    font-size: ${({ theme }) => theme.fontSize.bodyL}px;
    padding-top: 25px;
`;

const Body = styled(Text)`
    font-family: ${({ theme }) => theme.fontFamily.body};
    font-size: ${({ theme }) => theme.fontSize.bodyM}px;
    ${({ theme }) => theme.styleGroup.body}
`;

const ContentBody = styled(Text)`
    font-family: ${({ theme }) => theme.fontFamily.body};
    font-size: ${({ theme }) => theme.fontSize.bodyM}px;
    ${({ theme }) => theme.styleGroup.body}
`;

const MarginRow = styled(Row)`
    padding-top: 15px;
`;

const StyledFooter = styled(Footer)`
    background: ${({ theme }) => theme.color.white};
`;

const LEGAL_CONTENT = ["Terms of Use", "Privacy Policy"];

const year = new Date().getFullYear();

const EXPLORE_CONTENT = [
    [MARKETING_PAGES.DEVELOPMENT, MARKETING_PAGE_ROUTES[MARKETING_PAGES.DEVELOPMENT]],
    [MARKETING_PAGES.RESIDENTIAL, MARKETING_PAGE_ROUTES[MARKETING_PAGES.RESIDENTIAL]],
    [MARKETING_PAGES.ABOUT, MARKETING_PAGE_ROUTES[MARKETING_PAGES.ABOUT]],
    [MARKETING_PAGES.COMMUNITY, MARKETING_PAGE_ROUTES[MARKETING_PAGES.COMMUNITY]],
];

const CONNECT_CONTENT = Object.entries(SOCIAL_MEDIA_URLS);

const ManorLeadFooter = () => {
    const [terms, setTerms] = React.useState(false);
    const [policy, setPolicy] = React.useState(false);
    const bp = useBreakpoint();
    return (
        <>
            <StyledFooter>
                <Dialogs
                    Html={TermsAndConditions}
                    open={terms}
                    title={"TERMS AND CONDITIONS"}
                    toggle={() => setTerms(false)}
                />
                <Dialogs
                    Html={PrivacyPolicy}
                    open={policy}
                    title={"PRIVACY POLICY"}
                    toggle={() => setPolicy(false)}
                />
                <ContentWrapper style={{ padding: `0 ${bp.sm ? "12px" : bp.md ? "20px" : "0px"}` }}>
                    <Row align="top" justify="space-between">
                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                            <Title>{intl.get("footer.explore")}</Title>
                            {EXPLORE_CONTENT.map(([title, route], i) => (
                                <MarginRow key={i}>
                                    <Link key={i} href={route}>
                                        <Body>{intl.get(`footer.${title}`)}</Body>
                                    </Link>
                                </MarginRow>
                            ))}
                        </Col>
                        <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                            <Title>{intl.get("footer.connect")}</Title>
                            {CONNECT_CONTENT.map(([title, url], i) => (
                                <MarginRow key={i} align="middle">
                                    <Link key={i} href={url}>
                                        <Body>{title}</Body>
                                    </Link>
                                </MarginRow>
                            ))}
                        </Col>
                        <Col xs={24} sm={12} md={5} lg={5} xl={5}>
                            <Title>{intl.get("footer.legalContent")}</Title>
                            {LEGAL_CONTENT.map((text, i) => (
                                <div
                                    key={i}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        text === "Privacy Policy"
                                            ? setPolicy(true)
                                            : setTerms(true);
                                    }}
                                >
                                    <MarginRow key={i}>
                                        <Body key={i}>{intl.get(`footer.${text}`)}</Body>
                                    </MarginRow>
                                </div>
                            ))}
                        </Col>
                        <Col xs={24} sm={12} md={7} lg={7} xl={7}>
                            <Row>
                                <Title>Manorlead Group Inc.</Title>
                            </Row>
                            <MarginRow>
                                <ContentBody>{intl.get("footer.slogan")}</ContentBody>
                            </MarginRow>
                            <MarginRow>
                                <Link href="mailto:info@manorlead.com?Subject=Manorlead Inquiry">
                                    <AiTwotoneMail size="4em" style={{ paddingRight: "25px" }} />
                                </Link>
                                <Link
                                    href={SOCIAL_MEDIA_URLS[SOCIAL_MEDIA_PLATFORMS.FACEBOOK]}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <AiFillFacebook size="4em" style={{ paddingRight: "25px" }} />
                                </Link>
                                <Link
                                    href={SOCIAL_MEDIA_URLS[SOCIAL_MEDIA_PLATFORMS.INSTAGRAM]}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <AiFillInstagram size="4em" style={{ paddingRight: "25px" }} />
                                </Link>
                                <Link
                                    href={SOCIAL_MEDIA_URLS[SOCIAL_MEDIA_PLATFORMS.TWITTER]}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <AiFillTwitterSquare
                                        size="4em"
                                        style={{ paddingRight: "25px" }}
                                    />
                                </Link>
                            </MarginRow>
                        </Col>
                    </Row>
                </ContentWrapper>
            </StyledFooter>
            <Footer style={{ width: "100%" }}>
                <ContentWrapper>
                    <Row>
                        ©{year} Manorlead Group Inc. All Rights Reserved. By accessing the site, you
                        agree to the Terms of Use and Privacy Policy.
                    </Row>
                    <Row style={{ paddingTop: "10px" }}>
                        The trademarks REALTOR®, REALTORS®, and the REALTOR® logo are controlled by
                        The Canadian Real Estate Association (CREA) and identify real estate
                        professionals who are members of CREA. The trademarks MLS®, Multiple Listing
                        Service® and the associated logos are owned by The Canadian Real Estate
                        Association (CREA) and identify the quality of services provided by real
                        estate professionals who are members of CREA. Used under license.
                    </Row>
                    {window.location.pathname === "/" && (
                        <Row style={{ paddingTop: "10px" }}>
                            *Potential savings only apply for select new developments. All pricing,
                            details, and promotions are just for reference only and commission
                            amounts may vary. Final interpretation belongs to Manorlead Group Inc.
                        </Row>
                    )}
                </ContentWrapper>
            </Footer>
        </>
    );
};

export default ManorLeadFooter;
