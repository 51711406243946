import { Nullable } from "src/utils";

export enum SIMILARHOMES {
  REQUEST = "SIMILARHOMES_REQUEST",
  SUCCESS = "SIMILARHOMES_SUCCESS",
  FAILURE = "SIMILARHOMES_FAILURE",
  ERROR = "SIMILARHOMES_ERROR",
}

export interface SimilarHomesState {
  loading: boolean;
  data?: any;
  error?: Nullable<string>;
  query?: Nullable<string>;
}

export interface SimilarHomesActionType {
  type: SIMILARHOMES;
  payload: SimilarHomesState;
}
