import React from "react";
import { Spin } from "antd";

const Load = () => (
    <div
        style={{
            position: "absolute",
            backgroundColor: "rgb(255,255,255,0.4)",
            width: "100%",
            height: "188.5%",
            left: 0,
            top: 0,
            alignItems: "center",
            justifyContent: "center",
        }}
    >
        <Spin
            style={{
                left: "50%",
                top: "25%",
                right: "50%",
                position: "absolute",
            }}
        />
    </div>
);

export default Load;
