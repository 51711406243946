import { LISTINGS, ListingsActionType } from "./types";

export const listingsReducer = (
  state = {},
  action: ListingsActionType
) => {
  switch (action.type) {
    case LISTINGS.REQUEST:
    case LISTINGS.SUCCESS:
    case LISTINGS.FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
