import { CALCULATORS, CalculationActionType } from "./types";

export const requestMortgageCalculations = (query: {
    [key: string]: number;
}): CalculationActionType => ({
    type: CALCULATORS.REQUEST,
    payload: {
        loading: true,
        query: query,
        error: null,
    },
});

export const receiveMortgageCalculationsSuccess = (calculation: any): CalculationActionType => ({
    type: CALCULATORS.SUCCESS,
    payload: {
        loading: false,
        query: null,
        data: calculation,
        error: null,
    },
});

export const receiveMortgageCalculationsFailure = (error: string): CalculationActionType => ({
    type: CALCULATORS.FAILURE,
    payload: {
        loading: false,
        query: null,
        data: null,
        error: error,
    },
});
