import React from "react";
import styled from "styled-components";
import intl from "react-intl-universal";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { CircleCheckIcon, CalendarIcon, TrendingUpIcon, TagIcon, LocationIcon } from "src/static";
import { BaseIconTag, TagText, BaseTag } from "src/components";
import formatMoney from "src/components/Shared/FormatMoney";
import Text from "antd/lib/typography/Text";

export const MoveTag = (props: { completionYear: number }) => {
    return (
        <MoveTagContainer>
            {intl.get("development.tag.move1")} {props.completionYear}{" "}
            {intl.get("development.tag.move2")}
        </MoveTagContainer>
    );
};

export const ConstructionTag = (props: { completionYear: number }) => {
    return (
        <MoveTagContainer>
            {intl.get("development.floorPlan.completed-in")} {props.completionYear}
        </MoveTagContainer>
    );
};

export const ExclusiveTag = () => {
    const bp = useBreakpoint();
    return (
        <ExclusiveTagContainer>
            <TagText>{intl.get("development.tag.exclusives")}</TagText>
            <StyledCircleCheckIcon bp={bp} />
        </ExclusiveTagContainer>
    );
};

export const SellingTag = () => {
    return <SellingTagContainer>{intl.get("development.tag.selling")}</SellingTagContainer>;
};

export const RegistrationTag = () => {
    const bp = useBreakpoint();
    return (
        <RegistrationTagContainer>
            <TagText>{intl.get("development.tag.registration")}</TagText>
            <StyledCalendarIcon bp={bp} />
        </RegistrationTagContainer>
    );
};

export const SoldOutTag = () => {
    return <SoldOutTagContainer>{intl.get("development.tag.soldOut")}</SoldOutTagContainer>;
};

export const RecommendedTag = () => {
    return (
        <RecommendedTagContainer>{intl.get("development.tag.recommended")}</RecommendedTagContainer>
    );
};

export const SavingsTag = (props: { price: number | string }) => {
    return (
        <SavingsTagContainer>
            <SavingTagText>{`${intl.get("homePage.stats.saveDesc1")} $${formatMoney(
                props.price,
            )}`}</SavingTagText>
        </SavingsTagContainer>
    );
};

export const HotTag = () => {
    return (
        <HotTagContainer>
            <TagText>{`${intl.get("development.tag.hot")}`}</TagText>
            <StyledTrendingUpIcon />
        </HotTagContainer>
    );
};

export const PromotionTag = () => {
    return (
        <HotTagContainer>
            <TagText>{`${intl.get("development.education.promotionsTitle")}`}</TagText>
            <StyledTagIcon />
        </HotTagContainer>
    );
};

export const DistanceTag = (props: { distance: number; direction: string }) => {
    return (
        <DistanceTagContainer>
            <TagText>
                {props.distance > 0 && props.direction ? (
                    <>
                        {props.distance < 1000
                            ? `${(props.distance * 1000).toFixed(0)} Metres`
                            : `${props.distance.toFixed(2)} KM`}{" "}
                        {props.direction}
                    </>
                ) : (
                    "Located Nearby"
                )}
            </TagText>

            <StyledLocationIcon />
        </DistanceTagContainer>
    );
};

export const GeneralTag = (props: { message: string }) => {
    return <RecommendedTagContainer>{props.message}</RecommendedTagContainer>;
};

export const MoveTagContainer = styled(BaseTag)`
    background-color: ${({ theme }) => theme.color.navy};
    color: ${({ theme }) => theme.color.white};
`;

export const ExclusiveTagContainer = styled(BaseIconTag)`
    background-color: ${({ theme }) => theme.color.manorleadGold};
    color: ${({ theme }) => theme.color.black};
`;

const RegistrationTagContainer = styled(ExclusiveTagContainer)`
    background-color: ${({ theme }) => theme.color.success};
    color: ${({ theme }) => theme.color.white};
    white-space: nowrap;
`;

const SavingTagText = styled(Text)`
    font-family: ${({ theme }) => theme.fontFamily.body};
    ${({ theme }) => theme.styleGroup.button};
    font-size: ${({ theme }) => theme.fontSize.bodyS}px;
    white-space: nowrap;
`;
const SellingTagContainer = styled(MoveTagContainer)`
    background-color: ${({ theme }) => theme.color.success};
`;

const SoldOutTagContainer = styled(MoveTagContainer)`
    background-color: ${({ theme }) => theme.color.alertRed};
`;

const StyledCircleCheckIcon = styled(CircleCheckIcon)`
    position: relative;
    height: ${(props: { bp }) => (props.bp.xs ? `16px` : `18px`)};
    top: ${(props: { bp }) => (props.bp.xs ? `4px` : `5px`)};
`;

const RecommendedTagContainer = styled(MoveTagContainer)`
    background-color: ${({ theme }) => theme.color.manorleadGold};
    color: ${({ theme }) => theme.color.black};
`;

const SavingsTagContainer = styled(ExclusiveTagContainer)`
    margin: 0;
    padding-top: 4px;
`;

const StyledCalendarIcon = styled(CalendarIcon)`
    position: relative;
    height: ${(props: { bp }) => (props.bp.xs ? `16px` : `18px`)};
    top: ${(props: { bp }) => (props.bp.xs ? `3px` : `4px`)};
    path {
        fill: ${({ theme }) => theme.color.white};
    }
`;

const HotTagContainer = styled(BaseIconTag)`
    background-color: ${({ theme }) => theme.color.alertRed};
    color: ${({ theme }) => theme.color.white};
`;

const DistanceTagContainer = styled(BaseIconTag)`
    background-color: ${({ theme }) => theme.color.manorleadGold};
    color: ${({ theme }) => theme.color.blackPrimary};
`;

const StyledTrendingUpIcon = styled(TrendingUpIcon)`
    position: relative;
    height: 18px;
    top: 6px;
    path {
        fill: ${({ theme }) => theme.color.white};
    }
`;

const StyledTagIcon = styled(TagIcon)`
    position: relative;
    height: 18px;
    top: 4px;
    path {
        fill: ${({ theme }) => theme.color.white};
    }
`;

const StyledLocationIcon = styled(LocationIcon)`
    position: relative;
    height: 18px;
    top: 4px;
    path {
        fill: ${({ theme }) => theme.color.blackPrimary};
    }
`;
