import { Nullable } from "src/utils";
import { client } from "src/services/client";

export const fetchSearch = async (query: Nullable<string>) =>
    client({
        method: "post",
        url: `/listings/residential/search`,
        headers: { "Content-Type": "application/json" },
        data: { searchText: query, limit: 3 },
    }).then((response) => response.data);
