import { Button, Col, Row } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React from "react";
import intl from "react-intl-universal";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { Line } from "src/components/base/Line";
import { HomePageContentWrapper } from "../Shared";
import { Dev1, Dev2 } from "src/static";

import { Spacer } from "src/components/base";

export const DevelopmentIntro = () => {
    const bp = useBreakpoint();

    return (
        <>
            <HomePageContentWrapper>
                <Line />
                <Spacer height={16} />
                <Row justify="space-between" align="middle">
                    <div>
                        <Title>{intl.get("homePage.intro.title")}</Title>
                        <Spacer height={16} />
                        <SubTitle bp={bp}>{intl.get("homePage.intro.subTitle")}</SubTitle>
                    </div>
                </Row>
                <Spacer height={16} />

                <CardRow>
                    <Col xs={0} sm={0} md={0} lg={13} xl={11} xxl={10}>
                        <StyledTrapezoid>
                            <AbsoluteImg
                                src={Dev1}
                                alt="..."
                                style={{
                                    top: 30,
                                    left: 40,
                                }}
                            />
                            <AbsoluteImg
                                src={Dev2}
                                alt="..."
                                style={{
                                    top: 130,
                                    left: 290,
                                }}
                            />
                        </StyledTrapezoid>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={11} xl={13} xxl={14}>
                        <CardContainer bp={bp}>
                            <CardHeader bp={bp}>{intl.get("homePage.intro.cardHeader")}</CardHeader>
                            <Spacer height={16} />
                            <CardTitle>{intl.get("homePage.intro.cardTitle1")}</CardTitle>
                            <Spacer height={16} />
                            <CardBody bp={bp}>{intl.get("homePage.intro.cardBody1")}</CardBody>
                            <Spacer height={12} />
                            <CardList bp={bp}>
                                <li> {intl.get("homePage.intro.cardList1")}</li>
                                <li> {intl.get("homePage.intro.cardList2")} </li>
                                <li> {intl.get("homePage.intro.cardList3")}</li>
                            </CardList>
                            <Spacer height={24} />
                            <Link
                                to={{
                                    pathname: "https://www.about.manorlead.com/about-development",
                                }}
                                target="_blank"
                            >
                                <StyledButton type="primary" size="large">
                                    {intl.get("homePage.intro.find")}
                                </StyledButton>
                            </Link>
                        </CardContainer>
                    </Col>
                </CardRow>
            </HomePageContentWrapper>
        </>
    );
};

const StyledTrapezoid = styled.div`
    border-bottom: 496px solid rgba(27, 22, 66, 0.9);
    border-radius: 16px 0px 0px 0px;
    border-right: 50px solid transparent;
    height: 0;
    width: 100%;
    position: relative;
`;

const Title = styled.div`
    font-family: ${({ theme }) => theme.fontFamily.homeHeading};
    ${({ theme }) => theme.styleGroup.heading}
    font-size: ${({ theme }) => theme.fontSize.h2}px;
    line-height: 1;
`;

const SubTitle = styled.div`
    font-family: ${({ theme }) => theme.fontFamily.homeBody};
    ${({ theme }) => theme.styleGroup.heading}
    font-size: ${(props: { bp; theme }) =>
        props.bp.xs ? `${props.theme.fontSize.h4}px` : `${props.theme.fontSize.bulletTitle}px`};
`;

const CardRow = styled(Row)`
    border-radius: 16px 0px;
    box-shadow: ${({ theme }) => theme.boxShadow.CardContainer};
`;

const AbsoluteImg = styled.img`
    width: 200px;
    height: 296px;
    object-fit: contain;
    position: absolute;
`;

const CardContainer = styled.div`
    font-family: ${({ theme }) => theme.fontFamily.homeBody};
    ${({ theme }) => theme.styleGroup.body}
    padding: 40px ${(props: { bp }) => (props.bp.xl ? `80px` : props.bp.lg ? `40px` : `24px`)};
`;

const CardBody = styled.div`
    font-size: ${(props: { bp; theme }) =>
        props.bp.xs ? `${props.theme.fontSize.bodyM}px` : `${props.theme.fontSize.bodyL}px`};
    line-height: ${(props: { bp; theme }) => (props.bp.xs ? "20px" : "28px")};
    font-weight: 400;
`;

const CardHeader = styled(CardBody)`
    color: ${({ theme }) => theme.color.manorleadGold};
`;

const CardTitle = styled.div`
    font-family: ${({ theme }) => theme.fontFamily.homeHeading};
    ${({ theme }) => theme.styleGroup.heading}
    font-size: ${({ theme }) => theme.fontSize.h2}px;
    line-height: 1;
    margin-bottom: 0.5em;
`;

const CardList = styled(CardBody)`
    list-style-type: disc;
    list-style-position: inside;
    line-height: 1;
    li {
        font-size: ${(props: { bp; theme }) =>
            props.bp.xs ? `${props.theme.fontSize.bodyM}px` : `${props.theme.fontSize.bodyL}px`};
    }
`;

const StyledButton = styled(Button)`
    border-radius: 8px;
    color: ${({ theme }) => theme.color.greySecondaryD};
    background-color: ${({ theme }) => theme.color.manorleadGold};
    font-family: ${({ theme }) => theme.fontFamily.homeBody};

    :hover {
        background-color: ${({ theme }) => theme.color.manorleadGold};
        color: ${({ theme }) => theme.color.greySecondaryD};
        filter: brightness(95%);
    }
`;
