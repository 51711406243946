import { getCurrentDatetime } from "src/utils/time";
import useGoogleAnalytics from "src/utils/GoogleAnalytics";

export const useSendEventBHPP = () => {
    const { sendEvent } = useGoogleAnalytics();

    const sendEventBHPP = (
        categoryName: string,
        eventName: string,
        componentName: string,
        userID?: string,
        community?: string,
        value?: number,
        nonInteraction: boolean = false,
    ) => {
        let IDString = userID;
        if (!userID) {
            IDString = "not login";
        }
        let communityString = "";
        if (community) {
            communityString = `; Community: ${community}`;
        }
        eventName += `${communityString}; page URL: ${
            window.location.pathname
        }; User ID: ${IDString}; timestamp: ${getCurrentDatetime()}; user-agent: ${
            window.navigator.userAgent
        }`;
        sendEvent(categoryName, eventName, componentName, value, nonInteraction);
    };

    return {sendEventBHPP}
}


