import { Nullable } from "src/utils";
import { LISTINGS, ListingsActionType, ListingQuery } from "./types";

export const requestListings = (query: ListingQuery): ListingsActionType => ({
    type: LISTINGS.REQUEST,
    payload: {
        loading: true,
        query: query,
        error: null,
    },
});

export const receiveListingsSuccess = (
    data: any,
    query: Nullable<ListingQuery>,
): ListingsActionType => ({
    type: LISTINGS.SUCCESS,
    payload: {
        loading: false,
        query: query,
        data: data.residentialListings,
        page: data.page,
        error: null,
    },
});

export const receiveListingsFailure = (error: string): ListingsActionType => ({
    type: LISTINGS.FAILURE,
    payload: {
        loading: false,
        query: null,
        data: null,
        error: error,
    },
});
