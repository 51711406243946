import React from "react";
import { Flex, Link } from "src/components";
import { LogoIcon } from "src/static";
const Logo = () => {
    return (
        <Flex align="center">
            <Link href="/">
                <img style={{ height: "25px" }} src={LogoIcon} alt="manorlead-logo" />
            </Link>
        </Flex>
    );
};

export default Logo;
