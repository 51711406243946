import styled from "styled-components";

/**
 * A component to hold content for a section
 */
const ContentWrapper = styled.div`
    margin: 0 auto;
    max-width: 1224px;
    margin-left: auto;
    margin-right: auto;
    z-index: 3;
`;

export default ContentWrapper;
