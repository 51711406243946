import React from "react";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import intl from "react-intl-universal";

import { ListingSection } from "src/components/HomePage/ListingSection";
import { StatsSection, MobileStatsSection } from "src/components/HomePage/StatsSection";
import { DevelopmentSection } from "src/components/HomePage/DevelopmentSection";
import { DevelopmentIntro } from "src/components/HomePage/DevelopmentIntro";
import { ProcessIntroSection } from "src/components/HomePage/ProcessIntroSection";
import { SupporterSection } from "src/components/HomePage/SupporterSection";
import { ContactAgentSection } from "src/components/HomePage/ContactAgentSection";
import { QandA } from "src/components/HomePage/QandA";
import { AppSection } from "src/components/HomePage/AppSection";
import { Hero } from "src/components/HomePage";
import SavingsSection from "src/components/HomePage/SavingsSection";
import { Footer } from "src/components";
import MetaData from "src/components/Listing/MetaData";
import { SEO_IMAGE } from "src/static";
import { FeaturedOnSection } from "src/components/HomePage/FeaturedOnSection";

const HomePage = () => {
    const bp = useBreakpoint();

    return (
        <>
            <MetaData
                title={intl.get("title.home")}
                description={intl.get("helmetDescription.home")}
                image={SEO_IMAGE.HOMEPAGE}
            />
            <article>
                <Hero />
                <FeaturedOnSection />
                <DevelopmentSection />
                <SupporterSection />
                <SavingsSection />
                <ProcessIntroSection />
                {!bp.md ? <MobileStatsSection /> : <StatsSection />}
                <DevelopmentIntro />
                <ListingSection />
                <AppSection />
                <ContactAgentSection />
                <QandA />
                <Footer />
            </article>
        </>
    );
};

export default HomePage;
