import styled from "styled-components";

export const BaseTag = styled.div`
    font-family: ${({ theme }) => theme.fontFamily.homeBody};
    font-size: ${({ theme }) => theme.fontSize.bodyS}px;
    background-color: ${({ theme }) => theme.color.manorleadGold};
    color: ${({ theme }) => theme.color.black};
    display: inline-block;
    text-align: center;
    border-radius: 100px;
    margin: 8px 0px 0px 8px;
    padding: 2px 8px;
`;

export const BaseIconTag = styled(BaseTag)`
    padding: 0px 8px;
`;

export const TagText = styled.div`
    padding: 4px 0px;
    display: inline-block;
`;
