import { Form, Input, Typography } from "antd";
import React, { lazy, useState } from "react";
import { Flex, Spacer } from "src/components";
import Dialogs from "src/components/Shared/Dialogs";
import styled from "styled-components";
import intl from "react-intl-universal";
import { eRequirement } from "src/utils/regCheck";
import GoolgeLoginButton, {
    StyledButton,
} from "src/components/AuthenticationPage/GoogleLoginButton";
import OrDivider from "src/components/AuthenticationPage/OrDivider";

const { Text } = Typography;

const PrivacyPolicy = lazy(() => import("src/utils/policy"));
const TermsAndConditions = lazy(() => import("src/utils/terms"));

interface RegisterOrLoginContentProps {
    fetchIfUserEmailExist: (string) => void;
}

const RegisterOrLoginContent: React.FC<RegisterOrLoginContentProps> = ({
    fetchIfUserEmailExist,
}) => {
    const [showTerms, setShowTerms] = useState<boolean>(false);
    const [showPolicy, setShowPolicy] = useState<boolean>(false);
    const [form] = Form.useForm();

    const handleEmailSubmissionClick = () => {
        fetchIfUserEmailExist(form.getFieldValue("email"));
    };

    return (
        <Flex justify="center" column>
            <Dialogs
                Html={PrivacyPolicy}
                open={showPolicy}
                title={intl.get("authentication.shared.privacyPolicy")}
                toggle={() => setShowPolicy(!showPolicy)}
            />
            <Dialogs
                Html={TermsAndConditions}
                open={showTerms}
                title={intl.get("authentication.shared.termsOfUse")}
                toggle={() => setShowTerms(!showTerms)}
            />
            <Spacer height={24} />
            <GoolgeLoginButton renderType="continue" />
            <Spacer height={16} />
            <OrDivider />
            <Form form={form}>
                <StyledFormItem
                    style={{ height: "64px" }}
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: intl.get("authentication.warning.inputEmail"),
                        },
                        {
                            pattern: eRequirement,
                            message: intl.get("authentication.warning.inputValidEmail"),
                        },
                    ]}
                >
                    <StyledInput
                        size="large"
                        placeholder={intl.get("authentication.shared.email")}
                    />
                </StyledFormItem>
                <StyledFormItem shouldUpdate>
                    {() => (
                        <StyledButton
                            htmlType="submit"
                            block
                            type="primary"
                            size="large"
                            onClick={handleEmailSubmissionClick}
                            disabled={
                                !form.isFieldsTouched(true) ||
                                form.getFieldsError().filter(({ errors }) => errors.length).length >
                                    0
                            }
                        >
                            {intl.get("authentication.shared.continueWithEmail")}
                        </StyledButton>
                    )}
                </StyledFormItem>
            </Form>
            <Spacer height={8} />
            <TermsAndConditionsText>
                {`${intl.get("authentication.shared.manorleadAccept")} `}
                <ClickableText onClick={() => setShowTerms(true)}>
                    {intl.get("authentication.shared.termsOfUse")}
                </ClickableText>
                {" & "}
                <ClickableText onClick={() => setShowPolicy(true)}>
                    {intl.get("authentication.shared.privacyPolicy")}
                </ClickableText>
            </TermsAndConditionsText>
        </Flex>
    );
};

export default RegisterOrLoginContent;

const TermsAndConditionsText = styled(Text)`
    color: ${({ theme }) => theme.color.greySecondaryM};
    font-family: ${({ theme }) => theme.fontFamily.heading};
    font-size: ${({ theme }) => theme.fontSize.bodyXs}px;
    font-weight: 300;
    text-align: center;
`;

const StyledFormItem = styled(Form.Item)`
    && {
        margin: 0 auto;
    }
`;

const ClickableText = styled.a`
    font-weight: 600;
    && {
        color: ${({ theme }) => theme.color.greySecondaryM};
    }
    font-family: ${({ theme }) => theme.fontFamily.homeBody};
    font-size: ${({ theme }) => theme.fontSize.bodyXs}px;
`;

const StyledInput = styled(Input)`
    color: ${({ theme }) => theme.color.blackPrimaryL};
    font-family: ${({ theme }) => theme.fontFamily.homeBody};
    font-size: ${({ theme }) => theme.fontSize.bodyL}px;
    border: ${({ theme }) => `1px solid ${theme.color.goldAccent}`};
    background-color: ${({ theme }) => theme.color.white};
    height: 40px;
    border-radius: 8px;
    padding: 8px 16px;
`;
