import { SIMILARHOMES, SimilarHomesActionType } from "./types";

export const similarHomesReducer = (
  state = {},
  action: SimilarHomesActionType
) => {
  switch (action.type) {
    case SIMILARHOMES.REQUEST:
    case SIMILARHOMES.SUCCESS:
    case SIMILARHOMES.FAILURE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
