import { Col, Row } from "antd";
import React from "react";
import styled from "styled-components";
import { HomePageContentWrapper } from "../Shared";
import { BMO, RBC, Plaza, NBC } from "src/static";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { Spacer } from "src/components";
import intl from "react-intl-universal";

export const SupporterSection = () => {
    const bp = useBreakpoint();

    return (
        <HomePageContentWrapper>
            <Header bp={bp}>{intl.get("homePage.ourPartners.title")}</Header>
            <Spacer height={32} />
            <Row justify="center" gutter={[8, 32]} align="middle">
                <Col xs={12} sm={12} md={12} lg={5}>
                    <StyledImg src={BMO} alt="bmo" />
                </Col>
                <Col xs={12} sm={12} md={12} lg={5}>
                    <StyledImg src={RBC} alt="rbc" />
                </Col>
                <Col xs={12} sm={12} md={12} lg={5}>
                    <StyledImg src={NBC} alt="nbc" />
                </Col>
                <Col xs={12} sm={12} md={12} lg={5}>
                    <StyledImg src={Plaza} alt="plaza" />
                </Col>
            </Row>
        </HomePageContentWrapper>
    );
};

const StyledImg = styled.img`
    height: 60px;
    width: 100%;
    object-fit: contain;
    position: relative;
`;

const Header = styled.div`
    font-family: ${({ theme }) => theme.fontFamily.homeBody};
    ${({ theme }) => theme.styleGroup.body}
    font-size: ${(props: { bp; theme }) =>
        props.bp.xs ? `${props.theme.fontSize.bodyXs}px` : `${props.theme.fontSize.bodyS}px`};
    font-weight: 700;
    letter-spacing: 0.16em;
    color: #687ded;
    text-transform: uppercase;
    text-align: center;
`;
