import { DEVELOPMENT_CLUSTERS, DevelopmentClustersActionType } from "./types";

export const requestDevelopmentClusters = (query: string): DevelopmentClustersActionType => ({
    type: DEVELOPMENT_CLUSTERS.REQUEST,
    payload: {
        loading: true,
        query: query,
        error: null,
    },
});

export const receiveDevelopmentClustersSuccess = (data: any): DevelopmentClustersActionType => ({
    type: DEVELOPMENT_CLUSTERS.SUCCESS,
    payload: {
        loading: false,
        query: null,
        data: data,
        error: null,
    },
});

export const receiveDevelopmentClustersFailure = (error: string): DevelopmentClustersActionType => ({
    type: DEVELOPMENT_CLUSTERS.FAILURE,
    payload: {
        loading: false,
        query: null,
        data: null,
        error: error,
    },
});
