import { Col, Row } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React from "react";
import styled from "styled-components";
import intl from "react-intl-universal";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { HomePageContentWrapper } from "../Shared";
import { Spacer } from "src/components/base";
import { Line } from "src/components/base/Line";
import { setLoginPopup } from "src/redux/modules/Setting";
import { AUTH_TITLE } from "src/utils/constants";
import { authTitle } from "src/utils";

export const ProcessIntroSection = () => {
    const bp = useBreakpoint();
    const history = useHistory();
    const dispatch = useDispatch();

    const points = [
        {
            id: 1,
            onClick: () =>
                history.push({
                    pathname: `/development`,
                }),
        },
        {
            id: 2,
            onClick: () => {
                dispatch(setLoginPopup(true, authTitle(AUTH_TITLE.SIGNUP)));
            },
        },
        {
            id: 3,
            onClick: () => {
                dispatch(setLoginPopup(true, authTitle(AUTH_TITLE.SIGNUP)));
            },
        },
    ];

    const content = (
        <>
            <TitleContainer>
                <TitleDiv>
                    <Header bp={bp}>{intl.get("homePage.process.header")}</Header>
                    <Spacer height={32} />
                    <Title bp={bp}>
                        {intl.get("homePage.process.title1")}
                        <Underline>{intl.get("homePage.process.title2")}</Underline>
                        {intl.get("homePage.process.title3")}
                    </Title>
                    <Spacer height={32} />
                </TitleDiv>
            </TitleContainer>
            <Spacer height={32} />
            <Row justify="center">
                {points.map((point, idx) => (
                    <>
                        <Col key={`dot${point.id}`}>
                            <StyledDot num={point.id} />
                        </Col>
                        {idx < points.length - 1 && (
                            <Col span={24 / points.length - 1} key={`line${point.id}`}>
                                <LineContainer>
                                    <StyledLine />
                                </LineContainer>
                            </Col>
                        )}
                    </>
                ))}
            </Row>
            <Spacer height={32} />
            <Row justify="space-around">
                {points.map((point) => (
                    <PointCol span={6} key={point.id}>
                        <PointTitle bp={bp}>
                            {intl.get(`homePage.process.pointTitle${point.id}`)}
                        </PointTitle>
                        <Spacer height={8} />
                        <PointBody bp={bp}>
                            {intl.get(`homePage.process.pointBody${point.id}`)}
                        </PointBody>
                        <Spacer height={8} />
                        <PointFooter bp={bp} onClick={point.onClick}>
                            {intl.get(`homePage.process.pointFooter${point.id}`)}
                        </PointFooter>
                    </PointCol>
                ))}
            </Row>
        </>
    );

    const mobileContent = (
        <>
            <Line />
            <Spacer height={16} />
            <Row justify="space-between" align="middle">
                <div>
                    <Title bp={bp}>Our Process</Title>
                    <Spacer height={16} />
                </div>
            </Row>
            <Spacer height={16} />
            <div>
                {points.map((point) => (
                    <div key={point.id}>
                        <StyledDot num={point.id} />
                        <Spacer height={24} />
                        <PointTitle bp={bp}>
                            {intl.get(`homePage.process.pointTitle${point.id}`)}
                        </PointTitle>
                        <Spacer height={16} />
                        <PointBody bp={bp}>
                            {intl.get(`homePage.process.pointBody${point.id}`)}
                        </PointBody>
                        <Spacer height={16} />
                        <PointFooter bp={bp} onClick={point.onClick}>
                            {intl.get(`homePage.process.pointFooter${point.id}`)}
                        </PointFooter>
                        <Spacer height={40} />
                    </div>
                ))}
            </div>
        </>
    );

    return <HomePageContentWrapper>{!bp.md ? mobileContent : content}</HomePageContentWrapper>;
};

const StyledDot = styled.div`
    width: 70px;
    padding: 35px;
    position: static;
    border-radius: 50%;
    font-family: "DM Serif Display", serif;
    background-color: #ffc267;
    color: white;
    font-size: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;

    &:after {
        content: "${(props: { num: string | number }) => props.num}";
        position: absolute;
    }
`;

const StyledLine = styled.div`
    height: 2px;
    width: 100%;
    background-color: #ffc267;
`;

const LineContainer = styled.div`
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;
`;

const TitleContainer = styled.div`
    justify-content: center;
    align-items: center;
    display: flex;
    text-align: center;
`;

const TitleDiv = styled.div`
    max-width: 872px;
`;

const Header = styled.div`
    font-family: ${({ theme }) => theme.fontFamily.homeBody};
    ${({ theme }) => theme.styleGroup.body}
    font-size: ${(props: { bp; theme }) =>
        props.bp.xs ? `12px` : `${props.theme.fontSize.bodyS}px`};
    font-weight: 700;
    letter-spacing: 0.16em;
    color: #687ded;
`;

const Title = styled.div`
    font-family: ${({ theme }) => theme.fontFamily.homeHeading};
    ${({ theme }) => theme.styleGroup.heading}
    font-size: ${(props: { bp; theme }) => (props.bp.xs ? `${props.theme.fontSize.h2}px` : `48px`)};
    line-height: 1;
`;

const Underline = styled.span`
    position: relative;

    :after {
        background: ${({ theme }) => theme.color.manorleadGold};
        content: "";
        position: absolute;
        width: 100%;
        height: 11px;
        bottom: 4px;
        left: 0;
        opacity: 0.5;
    }
`;

const PointTitle = styled.div`
    font-family: ${({ theme }) => theme.fontFamily.homeHeading};
    ${({ theme }) => theme.styleGroup.heading}
    font-size: ${(props: { bp; theme }) =>
        props.bp.xs ? `${props.theme.fontSize.subtitle}px` : `${props.theme.fontSize.cardtitle}px`};
    line-height: ${(props: { bp; theme }) => (props.bp.xs ? "36px" : "44px")};
`;

const PointBody = styled.div`
    font-family: ${({ theme }) => theme.fontFamily.homeBody};
    ${({ theme }) => theme.styleGroup.body}
    font-size: ${(props: { bp; theme }) =>
        props.bp.xs ? `${props.theme.fontSize.bodyM}px` : `${props.theme.fontSize.bodyL}px`};
    line-height: ${(props: { bp; theme }) => (props.bp.xs ? "24px" : "28px")};
    font-weight: 400;
`;

const PointFooter = styled(PointBody)`
    color: #687ded;
    text-decoration: underline;
    cursor: pointer;

    :hover {
        filter: brightness(1.25);
    }
`;

const PointCol = styled(Col)`
    text-align: center;
`;
