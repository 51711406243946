import { Button, Row } from "antd";
import React, { useEffect, useState } from "react";
import intl from "react-intl-universal";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import { Line } from "src/components/base/Line";
import { RoomData } from "src/types/listing.type";
import { HomePageContentWrapper } from "../Shared";
import { fetchListings } from "src/redux/modules/Listings";
import { ListingCard } from "./ListingCard";
import { Spacer } from "src/components/base";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { exclusiveListingsResponsive } from "src/utils";

export const ListingSection = () => {
    const bp = useBreakpoint();
    const history = useHistory();
    const [cards, setCards] = useState<RoomData[] | null>();

    const deferredFetch = async () => {
        try {
            const exclusiveData = await fetchListings({
                filters: `brokerage=MANORLEAD GROUP INC., BROKERAGE&isExclusive=true&count=10&leaseOrSale=Sale`,
            });
            const dataListings1 = await fetchListings({
                filters: `brokerage=MANORLEAD GROUP INC., BROKERAGE&isExclusive=false&count=20&leaseOrSale=Sale`,
            });
            const dataListings2 = await fetchListings({
                filters: `brokerage=MANORLEAD GROUP INC.&isExclusive=false&count=20&leaseOrSale=Sale`,
            });
            setCards(
                exclusiveData.residentialListings.concat(
                    dataListings2.residentialListings,
                    dataListings1.residentialListings,
                ),
            );
        } catch (err) {
            console.error("ERROR: ", err);
        }
    };

    useEffect(() => {
        deferredFetch();
    }, []);

    const handleViewMore = () => {
        history.push({
            pathname: `/residential`,
        });
    };

    const handleClick = (id: string) => {
        history.push({
            pathname: `/listing/${id}`,
        });
    };

    return (
        <>
            <HomePageContentWrapper>
                <Line />
                <Spacer height={16} />
                <Row justify="space-between" align="middle" gutter={[0, 16]}>
                    <div>
                        <Title bp={bp}>{intl.get("homePage.exclusive.title")}</Title>
                        <Spacer height={16} />
                        <SubTitle bp={bp}>{intl.get("homePage.exclusive.subTitle")}</SubTitle>
                    </div>
                    <StyledButton type="primary" size="large" onClick={handleViewMore}>
                        {intl.get("homePage.exclusive.viewMore")}
                    </StyledButton>
                </Row>
                <Spacer height={16} />
                {cards && (
                    <StyledDiv>
                        <Carousel
                            swipeable={true}
                            draggable={false}
                            arrows={true}
                            showDots={true}
                            responsive={exclusiveListingsResponsive}
                            autoPlaySpeed={9000}
                            partialVisible={true}
                            autoPlay={false}
                            renderButtonGroupOutside={true}
                            keyBoardControl={true}
                            transitionDuration={500}
                            ssr={false} // means to render carousel on server-side.
                        >
                            {cards.map((r) => (
                                <ListingCardContainer key={r.id}>
                                    <ListingCard listing={r} route={handleClick} />
                                </ListingCardContainer>
                            ))}
                        </Carousel>
                    </StyledDiv>
                )}
            </HomePageContentWrapper>
        </>
    );
};

const Title = styled.div`
    font-family: ${({ theme }) => theme.fontFamily.homeHeading};
    ${({ theme }) => theme.styleGroup.body}
    font-size: ${(props: { bp; theme }) =>
        props.bp.xs ? `${props.theme.fontSize.h3}px` : `${props.theme.fontSize.cardtitle}px`};
    line-height: 1;
`;

const StyledDiv = styled.div`
    max-width: 100%;
`;

const ListingCardContainer = styled.div`
    margin: 8px 16px 8px 0px;
`;

const StyledButton = styled(Button)`
    border-radius: 8px;
    color: ${({ theme }) => theme.color.greySecondaryD};
    background-color: ${({ theme }) => theme.color.manorleadGold};
    font-family: ${({ theme }) => theme.fontFamily.homeBody};

    :hover {
        background-color: ${({ theme }) => theme.color.manorleadGold};
        color: ${({ theme }) => theme.color.greySecondaryD};
        filter: brightness(95%);
    }
`;

const SubTitle = styled.div`
    font-family: ${({ theme }) => theme.fontFamily.homeBody};
    ${({ theme }) => theme.styleGroup.heading}
    font-size: ${(props: { bp; theme }) =>
        props.bp.xs ? `${props.theme.fontSize.h4}px` : `${props.theme.fontSize.bulletTitle}px`};
`;
