/**
 * Read @https://github.com/styled-components/styled-components/issues/2303#issuecomment-480047731
 */
export type TDeviceType = "large" | "medium" | "tablet" | "largeMobile" | "mobile" | "smallMobile";

/**
 * Maximum widths in px for a screen
 * to be considered each type of
 * device.
 */
export const deviceBreakpoints: Record<TDeviceType, number> = {
    large: 1440,
    medium: 1386,
    tablet: 1261,
    largeMobile: 425,
    mobile: 375,
    smallMobile: 320,
};

const customMediaQuery = (maxWidth: number) => `@media (max-width: ${maxWidth}px)`;

export const mediaQueries = {
    custom: customMediaQuery,
    large: customMediaQuery(deviceBreakpoints.large),
    medium: customMediaQuery(deviceBreakpoints.medium),
    tablet: customMediaQuery(deviceBreakpoints.tablet),
    largeMobile: customMediaQuery(deviceBreakpoints.largeMobile),
    mobile: customMediaQuery(deviceBreakpoints.mobile),
    smallMobile: customMediaQuery(deviceBreakpoints.smallMobile),
};

// for carousel
export const responsive = {
    desktop: {
        breakpoint: { max: 2147483640, min: 1080 },
        items: 3,
        partialVisibilityGutter: 20,
    },
    tablet: {
        breakpoint: { max: 1080, min: 700 },
        items: 2,
        partialVisibilityGutter: 20,
    },
    smallMobile: {
        breakpoint: { max: 700, min: 0 },
        items: 1,
        partialVisibilityGutter: 0,
    },
};

// for exclusive listings carousel in homepage
export const exclusiveListingsResponsive = {
    largeDesktop: {
        breakpoint: { max: 2147483640, min: 1600 },
        items: 3,
        partialVisibilityGutter: 20,
    },
    desktop: {
        breakpoint: { max: 1600, min: 1200 },
        items: 2,
        partialVisibilityGutter: 88,
    },
    tablet: {
        breakpoint: { max: 1200, min: 700 },
        items: 2,
        partialVisibilityGutter: 20,
    },
    smallMobile: {
        breakpoint: { max: 700, min: 0 },
        items: 1,
        partialVisibilityGutter: 20,
    },
};

export const SingleItemResponsive = {
    general: {
        breakpoint: { max: 9999, min: 0 },
        items: 1,
    },
};
