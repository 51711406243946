import { CONTACT_AGENT, ContactAgentActionType } from "./types";

export const requestContactAgent = (query: any): ContactAgentActionType => ({
    type: CONTACT_AGENT.REQUEST,
    payload: {
        loading: true,
        query: query,
        error: null,
    },
});

export const receiveContactAgentSuccess = (
    contactAgent: any
): ContactAgentActionType => ({
    type: CONTACT_AGENT.SUCCESS,
    payload: {
        loading: false,
        query: null,
        data: contactAgent,
        error: null,
    },
});

export const receiveContactAgentFailure = (
    error: string
): ContactAgentActionType => ({
    type: CONTACT_AGENT.FAILURE,
    payload: {
        loading: false,
        query: null,
        data: null,
        error: error,
    },
});
