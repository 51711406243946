import { SIGNUP, SignupActionType } from "./types";

export const requestSignup = (query: any): SignupActionType => ({
    type: SIGNUP.REQUEST,
    payload: {
        loading: true,
        query: query,
        error: null,
    },
});

export const receiveSignupSuccess = (signup: any): SignupActionType => ({
    type: SIGNUP.SUCCESS,
    payload: {
        loading: false,
        query: null,
        data: signup,
        error: null,
    },
});

export const receiveSignupFailure = (error: any): SignupActionType => ({
    type: SIGNUP.FAILURE,
    payload: {
        loading: false,
        query: null,
        data: error,
        error: error.error,
    },
});

export const cleanSignup = (): SignupActionType => ({
    type: SIGNUP.CLEAN,
    payload: {
        loading: false,
        query: null,
        data: null,
    },
});
