import ReactGA from 'react-ga4';


/* You are free to add categories as needed, but make sure to know...
* A category is a name that you supply as a way to group objects that you want to analyze.
* Typically, you will use the same category name multiple times over related UI elements
* that you want to group under a given category.
*
* Think about how many times your categories will be used and definite it with a high level
* naming instead of specific to any particular feature, page or components.
*
* For example, don't add a categories for calculator but use BUTTON
*
* Read https://support.google.com/analytics/answer/1033068#Anatomy&zippy=%2Cin-this-article
*/
export const Category = {
    /*
    * BUTTON: Represents events triggered by clicking a button or a link.
    * Typically used for onClick events.
    * Example: Clicking the "submit" button on a form
    */
    BUTTON: "Button",

    /*
    * BUTTON: Represents events triggered by a filter on properties
    * Typically used for onClick events for filters.
    * Example: Filter applied for map page
    */
    FILTER: "Filter",

    /*
    * BUTTON: Represents events triggered by a sort action on properties
    * Typically used for onClick events for sorting.
    * Example: Sorting applied for map page
    */
    SORT: "Sort",

    /*
    * DROP_DOWN: Represents events triggered by interacting with a dropdown.
    * Example: Selecting an option from a dropdown menu
    */
    DROP_DOWN: "Drop_down",

    /*
    * DISMISS: Represents events triggered when dismissing UI elements.
    * Example: Closing a pop-up window
    */
    DISMISS: "Dismiss",

    /*
    * REDIRECTION: Represents events triggered when the user is redirected.
    * Example: User is redirected to another page after clicking a link
    */
    REDIRECTION: "Redirection",

    /*
    * SUBMIT: Represents events triggered when a form is submitted.
    * Example: User submits a registration form
    */
    SUBMIT: "Submit",

    /*
    * EDIT: Represents events triggered when a text field is edited.
    * Example: User types into a search bar
    */
    EDIT: "Edit",

    /*
    * SEARCH_LISTING: Represents events triggered during a search operation.
    * Example: User performs a search on a listing page
    */
    SEARCH_LISTING: "Search_listing",

    /*
    * SEARCH_SCHOOL: Represents events triggered during a search operation.
    * Example: User performs a search for schools
    */
    SEARCH_SCHOOL: "Search_school",

    /*
    * ADMIN: Represents events triggered in an admin interface.
    * Example: Admin approves a user account
    */
    ADMIN: "Admin",

    /*
    * SCROLL: Represents events triggered by scrolling.
    * Example: User scrolls to the bottom of a page
    */
    SCROLL: "Scroll",

    /*
    * VIDEO: Represents events triggered by interacting with a video.
    * Example: User clicks the "play" button on a video player
    */
    VIDEO: "Video",

    /*
    * IMAGE: Represents events triggered by interacting with an image.
    * Example: User clicks on an image to view it in a lightbox
    */
    IMAGE: "Image",

    /*
    * Other: Represents any other type of events not covered above.
    * Example: User hovers over a tooltip
    */
    Other: "Other",

    /*
    * ERROR: Represents events triggered by critical errors or exceptions.
    * Example: An error occurs while trying to fetch data from an API
    */
    ERROR_FATAL: "Error",

    /*
    * ERROR: Represents events triggered by non critical errors or exceptions.
    * Example: An error occurs due to network connection timeout
    */
    ERROR_NOT_FATAL: "Error",

    /*
    * TIME_SPENT: Represents events related to the time spent by the user.
    * Example: User spends a certain amount of time on a page
    */
    TIME_SPENT: "Time_spent",

    /*
    * Timing: Represents the time elapsed or a function or page view
    * Example: pass in value in milliseconds for map reloads
    */
    TIMING: "Error",

    /*
    * BUTTON: Represents events triggered by user logged in.
    * Typically used for logging in to the site.
    * Example: Logged in on a page
    */
    LOGIN: "Login",

    /*
    * BUTTON: Represents events triggered by user signup.
    * Typically used for signup in to the site.
    * Example: Signup on a page
    */
    SIGNUP: "Signup",
};

type ErrorConfig = {
    description: string;
    fatal: boolean;
    traceback: string;
};

// Function to check if the given category is valid
export const isValidCategory = (category: string) => {
    return Object.values(Category).includes(category);
};

const useGoogleAnalytics = () => {
    /*
    * This function sends a page view hit to Google Analytics
    * type {string} path: Relative path of the viewed page 
    * type {string} title: Optional, title of the viewed page
    * Pageview hit lets you track views of individual pages on your site.
    * Example: sendPageView('/homepage', 'Homepage')
    */
    const sendPageView = (path: string, title) => {
        ReactGA.send({ hitType: "pageview", page: path, title: title });
    };

    /*
    * This function sends a custom event hit to Google Analytics
    * type {object} event: Object describing the event
    * Event hit lets you track user interactions like clicks, form submissions etc.
    * event object consists of
    *   - type {string} category: Group of objects you want to track (e.g., 'Videos')
    *   - type {string} action: Type of user interaction you want to track (e.g., 'Play')
    *   - type {string} label: Optional, more information about the interaction (e.g., 'Gone With the Wind')
    *   - type {number} value: Optional, numerical data about user interaction
    *   - type {boolean} nonInteraction: Optional, true if the event is not a user-initiated interaction
    *   - type {string} transport: Optional, method of transporting the event ('beacon', 'xhr', 'image')
    * Example: sendEvent({category: 'Videos', action: 'Play', label: 'Gone With the Wind'})
    */
    const sendEvent = (
        category: string, 
        action: string, 
        label: string, 
        value?: number, 
        nonInteraction?: boolean, 
        transport?: 'beacon' | 'xhr' | 'image'
    ) => {
        if (!isValidCategory(category)) {
            throw new Error(`Invalid category "${category}".`);
        }

        ReactGA.event({category, action, label, value, nonInteraction, transport});
    };

    /*
    * This function sends a timing hit to Google Analytics
    * Timing hits let you measure load times and other custom timings.
    * type {string} action: Name of the timed event
    * type {number} value: Time in milliseconds
    * type {string} label: Optional, more information about the timing
    */
    const trackTiming = (action: string, value: number, label?: string) => {
        ReactGA.event({
            category: Category.TIMING,
            action,
            value, // in milliseconds
            label,
        });
    };

    /*
    * This function sends an production env exception hit to Google Analytics
    * Exception hits let you measure the number and type of crashes or errors on your site.
    * type {string} description: Description of the error
    * type {boolean} fatal: Optional, indicates if the error was fatal
    * * type {boolean} fatal: Optional, indicates if the error was fatal
    */
    const logError = ({description, fatal, traceback}: ErrorConfig) => {
        if (process.env.NODE_ENV === "production") {
            sendEvent(
                fatal ? Category.ERROR_FATAL : Category.ERROR_NOT_FATAL, 
                description, 
                traceback
            );   
        }
    };

    return {
        sendPageView,
        sendEvent,
        trackTiming,
        logError,
    };
};

export default useGoogleAnalytics;

