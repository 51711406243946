import { SIGNUP, SignupActionType } from "./types";

export const signupReducer = (state = {}, action: SignupActionType) => {
    switch (action.type) {
        case SIGNUP.REQUEST:
        case SIGNUP.SUCCESS:
        case SIGNUP.FAILURE:
        case SIGNUP.CLEAN:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
