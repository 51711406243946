import { put, takeLatest, call } from "redux-saga/effects";
import { DEVELOPMENT_SEARCH, Development_SearchActionType } from "./types";
import { searchDevelopment } from "./services";
import { receiveDevelopmentSearchSuccess, receiveDevelopmentSearchFailure } from ".";
import get from "lodash.get";
import { DevelopmentView } from "src/types/listing.type";

function* getListings(action: Development_SearchActionType) {
    try {
        const data = yield call(searchDevelopment, action.payload.query);
        const listings: DevelopmentView[] = get(data, "developments", null);
        if (listings) {
            yield put(receiveDevelopmentSearchSuccess(listings, action.payload.query));
        } else {
            yield put(receiveDevelopmentSearchFailure("ERROR: Failed to fetch development data"));
        }
    } catch (error) {
        yield put(receiveDevelopmentSearchFailure(get(error, "message", "Network Error!")));
    }
}

export function* watchGetDevelopmentSearchRequest() {
    yield takeLatest(DEVELOPMENT_SEARCH.REQUEST, getListings);
}
