import { client } from "src/services/client";

export const fetchDevelopment = async (query: { [key: string]: string }) =>
    client({
        method: "get",
        url: `/development?${query.filters}`,
        headers: { "Content-Type": "application/json" },
    }).then((response) => response.data);

export const fetchDevelopmentById = async (id: string) => 
    client({
        method: "get",
        url: `/development/${id}`,
        headers: { "Content-Type": "application/json" },
    }).then((response) => response.data);