import { Nullable } from "src/utils";
import { client } from "src/services/client";
import { ListingQuery } from "./types";
import { formatTargetDate } from "src/utils/helpers";

export const fetchListings = async (query: ListingQuery) => {
    const data = await client({
        method: "get",
        url: `/listings/residential?${query.filters ?? ""}${
            query.isSold ? `&isSold=true&closingDate=gte:${formatTargetDate(-180)}` : ""
        }`,
    })
        .then((response) => response.data);
    return data;
};

export const fetchNewDev = async (query: Nullable<string>) =>
    client({
        method: "get",
        url: `/development?${query ?? ""}`,
    }).then((response) => response.data);

export const fetchListingDetail = async (id: string) => 
    client({
        method: "get",
        url: `/listings/residential/${id}`,
    })
        .then((response) => response.data);

export const fetchSoldListingDetail = async (id: string) => 
    client({
        method: "get",
        url: `/listings/residential/${id}/soldDetails?limit=0`,
    })
        .then((response) => response.data);

