import axios from "axios";
import { loadState, saveState } from "src/redux/store/localStorage";
import CacheBuster from "src/cacheBuster.js";
import useGoogleAnalytics from "src/utils/GoogleAnalytics";

export const getToken = () => localStorage.getItem("_MN_TOKEN");
export const setToken = (token: string) => localStorage.setItem("_MN_TOKEN", token);
export const removeToken = () => localStorage.removeItem("_MN_TOKEN");

const client = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

client.interceptors.request.use((config) => {
    const token = getToken();
    if (token) {
        return {
            ...config,
            headers: {
                ...config.headers,
                authorization: `Bearer ${token}`,
            },
        };
    }
    return config;
});

export const useSetupInterceptors = () => {
    const { logError } = useGoogleAnalytics();

    const setupInterceptors = (history) => {
        client.interceptors.response.use(
            (response) => {
                // decide if to clear cache
                const { loading, isLatestVersion, refreshCacheAndReload } = CacheBuster(
                    response.headers["frontend-build-version"],
                );

                if (!loading && !isLatestVersion) {
                    refreshCacheAndReload();
                }

                return response;
            },
            (error) => {
                return new Promise((resolve, reject) => {
                    const originalRequest = error.config;
                    const refreshToken = localStorage.getItem("refresh_token");
                    if (
                        error.response &&
                        (error.response.status === 401 || error.response.status === 500) &&
                        error.config &&
                        !error.config.__isRetryRequest &&
                        refreshToken
                    ) {
                        originalRequest.__isRetryRequest = true;

                        const response = fetch(`${process.env.REACT_APP_API_URL}/auth/refresh`, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({
                                refreshToken: refreshToken,
                            }),
                        })
                            .then((res) => res.json())
                            .then((res) => {
                                let state = loadState();
                                if (!state) {
                                    state = {};
                                }
                                if (!state.login) {
                                    state.login = {};
                                }
                                state.login.data = res;
                                state.login.data.expiry =
                                    Date.now() + res.cognitoJWT.expires_in * 1000;
                                saveState(state);
                                setToken(res.cognitoJWT.access_token);
                                window.location.reload();
                                return axios(originalRequest);
                            })
                            .catch(() => {
                                let state = loadState();
                                if (!state) {
                                    state = {};
                                }
                                state.login = {};
                                state.signup = {};
                                state.userInfo = {};
                                saveState(state);
                                removeToken();
                                localStorage.removeItem("refresh_token");
                                window.location.reload();
                                return axios(originalRequest);
                            });
                        resolve(response);
                    }
                    if (error.response.status === 500) {
                        logError({
                            description: error.message,
                            fatal: true,
                            traceback: error.componentStack?.toString() ?? error.toString(),
                        });
                        // disable redirect until backend is stable
                        // history.push(ERROR_PAGE);
                        resolve(error.response);
                    } else if (error.response.status === 404) {
                        // Log error since it wont go through error boundary
                        logError({
                            description: error.message,
                            fatal: true,
                            traceback: error.stack ? error.stack : error.toString(),
                        });
                        history.replace("/page-not-found");
                        resolve(error.response);
                    }
                    reject(error.response);
                });
            },
        );
    };
    return { setupInterceptors };
};

export { client };
