import React, { lazy, Suspense, useEffect, useState } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import Load from "src/components/HomePage/Hero/SearchBar/Load";
import Sitemap from "src/components/Sitemap";
import { client } from "src/services/client";
import { buildDevUrl, ENABLE_FULL_PORTFOLIO, ENABLE_BHPP } from "src/utils";
import * as URI from "src/utils/uri";
import HomePage from "./MarketingPage/HomePage";
import useGoogleAnalytics from "src/utils/GoogleAnalytics";
import { FallbackPage } from "src/components/Layout";
import { useHistory } from "react-router-dom";
import { ERROR_PAGE } from "src/utils/uri";

const Listing = lazy(() => import("./ListingPage"));
const DevelopmentIndividual = lazy(() => import("./DevelopmentPage/Details"));
const CommunityIndividual = lazy(() => import("./CommunityPage/Details"));
const Community = lazy(() => import("./CommunityPage/Landing"));
const Development = lazy(() => import("./DevelopmentPage/Map"));
const Exclusive = lazy(() => import("./ExclusivePage"));
const AboutPage = lazy(() => import("./MarketingPage/AboutPage"));
const ServicesPage = lazy(() => import("./MarketingPage/ServicesPage"));
const Profile = lazy(() => import("./ProfilePage"));
const SavedHomePage = lazy(() => import("./SavedHomesPage"));
const MapPage = lazy(() => import("./MapPage"));
const PortfolioPageLanding = lazy(() => import("./PortfolioPage/Development/Landing"));
const PortfolioPagePersonalLanding = lazy(
    () => import("./PortfolioPage/Development/Landing/PersonalLanding"),
);
const PortfolioPageForm = lazy(() => import("./PortfolioPage/Development/FormPage"));
const PortfolioPageDetail = lazy(() => import("./PortfolioPage/Development/DetailPage"));
const ResidentialPortfolioLandingPage = lazy(() => import("./PortfolioPage/Residential/Landing"));
const ResidentialPortfolioDetailPage = lazy(() => import("./PortfolioPage/Residential/DetailPage"));
const DevelopmentPortfolioLandingPage = lazy(() => import("./PortfolioPage/Development/Landing"));
const AppPage = lazy(() => import("./AppPage"));
const CondoDetailPage = lazy(() => import("./CondoPage/Detail"));
const CondoMapPage = lazy(() => import("./CondoPage/Map"));
const GoogleLoginRedirectionPage = lazy(() => import("src/components/Redirect"));
const PrivacyPage = lazy(() => import("src/views/PrivacyPage"));
const AssignmentMapPage = lazy(() => import("src/views/AssignmentPage/Map"));
const AssignmentDetailPage = lazy(() => import("src/views/AssignmentPage/Detail"));
const BHPPLandingPage = lazy(() => import("src/views/BHPPPage/LandingPage"));
const BHPPFormPage = lazy(() => import("src/views/BHPPPage/FormPage"));
const BHPPDetailPage = lazy(() => import("src/views/BHPPPage/DetailPage"));
const ListPage = lazy(() => import("src/views/ChatbotPage/List"));
const DevelopmentListPage = lazy(() => import("src/views/ChatbotPage/DevelopmentList"));
const ChatPage = lazy(() => import("src/views/ChatbotPage/Chat"));
const ChatV2Page = lazy(() => import("src/views/ChatbotPage/ChatV2"));
const BrainPage = lazy(() => import("src/views/ChatbotPage/Brain"));
const AgentListPage = lazy(() => import("src/views/AgentPage/List"));
const AgentDetailPage = lazy(() => import("src/views/AgentPage/Detail"));
const LawyerListPage = lazy(() => import("src/views/LawyerPage/List"));
const ProposedLandingPage = lazy(() => import("src/views/ProposedPage/Landing"));
const ProposedMapPage = lazy(() => import("src/views/ProposedPage/Map"));

const PageViewAndTimingListener = () => {
    const location = useLocation();
    const { sendPageView, trackTiming } = useGoogleAnalytics();
    const [entryTime, setEntryTime] = useState<number>(Date.now());

    useEffect(() => {
        console.log("first case", Date.now(), location.pathname);
        setEntryTime(Date.now()); // reset the entry time
        sendPageView(location.pathname, document.title);

        // When the location changes (i.e., a different page is navigated to), the cleanup
        // function below will execute before the new render, effectively capturing the time
        // spent on the current page.
        return () => {
            const timeSpent = Date.now() - entryTime;
            trackTiming(location.pathname, timeSpent);
        };
    }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

    return null;
};

export const RouterSwitch = () => {
    const { pathname } = useLocation();

    const [developmentURL, setDevelopmentURL] = useState<string>();
    const { logError } = useGoogleAnalytics();
    let history = useHistory();

    useEffect(() => {
        if (pathname.match(/^\/development\/([^/]+?)\/?$/g)) {
            const slicedPathname = pathname.split("/");
            const developmentID =
                slicedPathname[slicedPathname.length - 1] === ""
                    ? slicedPathname[slicedPathname.length - 2]
                    : slicedPathname[slicedPathname.length - 1];
            client({
                method: "get",
                url: URI.DEVELOPMENT_URL.buildDetailPageUri(developmentID),
                headers: { "Content-Type": "application/json" },
            })
                .then((response) => response.data)
                .then((result) => {
                    setDevelopmentURL(`${buildDevUrl(result)}`);
                })
                .catch((err) => {
                    logError({
                        description: err.message,
                        fatal: true,
                        traceback: err.componentStack?.toString() ?? err.toString(),
                    });
                    history.push(ERROR_PAGE);
                });
        } else {
            // prevent the search go to the revious search result
            setDevelopmentURL("");
        }
    }, [pathname]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Suspense fallback={<Load />}>
            <PageViewAndTimingListener />
            <Switch>
                <Route exact path={URI.HOME_PAGE_URL} component={HomePage} />
                <Route exact path={URI.RESIDENTIAL_URL.landing} component={MapPage} />
                <Route exact path={URI.SERVICES_URL} component={ServicesPage} />
                <Route exact path={URI.ABOUT_URL} component={AboutPage} />
                <Route
                    exact
                    path={URI.RESIDENTIAL_URL.buildDetailPageUri(":mls")}
                    component={Listing}
                />
                <Route
                    exact
                    path={URI.COMMUNITY_URL.buildDetailPageUri(":name")}
                    component={CommunityIndividual}
                />
                <Route exact path={URI.COMMUNITY_URL.landing} component={Community} />
                <Route exact path={URI.PROFILE_URL} component={Profile} />
                <Route exact path={URI.EXCLUSIVE_URL} component={Exclusive} />
                <Route exact path={URI.DEVELOPMENT_URL.landing} component={Development} />
                <Route exact path={URI.SAVED_URL} component={SavedHomePage} />
                <Route
                    exact
                    path={[URI.DEVELOPMENT_URL.buildDetailPageUri(":id", ":info")]}
                    component={DevelopmentIndividual}
                />
                <Route
                    exact
                    path={URI.CONDO_URL.buildDetailPageUri(":id")}
                    component={CondoDetailPage}
                />
                <Route path={URI.CONDO_URL.landing} component={CondoMapPage} />
                {ENABLE_FULL_PORTFOLIO
                    ? [
                          <Route
                              exact
                              path={URI.PORTFOLIO_URL.landing}
                              component={PortfolioPageLanding}
                              key="portfolioPageLanding"
                          />,
                          <Route
                              exact
                              path={URI.PORTFOLIO_URL.personal}
                              component={PortfolioPagePersonalLanding}
                              key="PortfolioPagePersonalLanding"
                          />,
                          <Route
                              exact
                              path={URI.PORTFOLIO_URL.buildFormUri(":id")}
                              component={PortfolioPageForm}
                              key="portfolioPageForm"
                          />,
                          <Route
                              exact
                              path={[
                                  URI.PORTFOLIO_URL.buildUnitUri(":id"),
                                  URI.PORTFOLIO_URL.buildFloorplanUri(":id", ":floorplanId"),
                                  URI.PORTFOLIO_URL.buildPurchasedUri(":purchaseHistoryId"),
                              ]}
                              component={PortfolioPageDetail}
                              key="portfolioPageDetail"
                          />,
                      ]
                    : [
                          <Route
                              exact
                              path={[
                                  URI.PORTFOLIO_URL.buildUnitUri(":id"),
                                  URI.PORTFOLIO_URL.buildFloorplanUri(":id", ":floorplanId"),
                                  URI.PORTFOLIO_URL.buildPurchasedUri(":purchaseHistoryId"),
                              ]}
                              component={PortfolioPageDetail}
                          />,
                          <Route
                              exact
                              path={URI.PORTFOLIO_URL.personal}
                              component={PortfolioPagePersonalLanding}
                          />,
                      ]}
                <Route
                    exact
                    path={URI.DEVELOPMENT_PORTFOLIO_URL.landing}
                    component={DevelopmentPortfolioLandingPage}
                />
                <Route
                    exact
                    path={URI.RESIDENTIAL_PORTFOLIO_URL.landing}
                    component={ResidentialPortfolioLandingPage}
                />
                <Route
                    exact
                    path={URI.RESIDENTIAL_PORTFOLIO_URL.buildDetailUri(":id")}
                    component={ResidentialPortfolioDetailPage}
                />
                <Route
                    path={URI.PAGE_NOT_FOUND_URL}
                    component={(props) => <FallbackPage {...props} errorCode="404" />}
                />
                <Route path={URI.ERROR_PAGE} component={FallbackPage} />
                <Route path={URI.SITEMAP_URL(":name")} component={Sitemap} />
                {developmentURL ? (
                    <Redirect
                        exact
                        from={URI.DEVELOPMENT_URL.buildDetailPageUri(":id")}
                        to={developmentURL}
                    />
                ) : (
                    <Route
                        exact
                        path={URI.DEVELOPMENT_URL.buildDetailPageUri(":id")}
                        component={undefined}
                    />
                )}
                <Route exact path={URI.ASSIGN_URL.landing} component={AssignmentMapPage} />
                <Route
                    exact
                    path={URI.ASSIGN_URL.buildDetailPageUri(":id")}
                    component={AssignmentDetailPage}
                />

                {ENABLE_BHPP && [
                    <Route exact path={URI.BHPP_URL.landing} component={BHPPLandingPage} />,
                    <Route
                        exact
                        path={URI.BHPP_URL.buildFormUri(":address")}
                        component={BHPPFormPage}
                    />,
                    <Route
                        exact
                        path={URI.BHPP_URL.buildDetailUri(":predictionId")}
                        component={BHPPDetailPage}
                    />,
                ]}
                <Route exact path={URI.LIST_OF_LISTINGS_URL} component={ListPage} />
                <Route exact path={URI.LIST_OF_DEVELOPMENTS_URL} component={DevelopmentListPage} />
                <Route exact path={URI.CHAT_BOT_URL} component={ChatPage} />
                <Route exact path={URI.CHATV2_URL} component={ChatV2Page} />
                <Route exact path={URI.BRAIN_URL} component={BrainPage} />
                <Route exact path={URI.AGENT_URL.landing} component={AgentListPage} />
                <Route
                    exact
                    path={URI.AGENT_URL.buildDetailPageUri(":id")}
                    component={AgentDetailPage}
                />
                <Route exact path={URI.LAWYER_URL.landing} component={LawyerListPage} />
                <Route exact path={URI.PROPOSED_URL.landing} component={ProposedLandingPage} />
                <Route exact path={URI.PROPOSED_URL.map} component={ProposedMapPage} />
                <Route
                    exact
                    path={[URI.APP_URL(), URI.APP_URL(":appVariable")]}
                    component={AppPage}
                />
                <Route exact path={URI.REDIRECT_URL} component={GoogleLoginRedirectionPage} />
                <Route exact path={URI.PRIVACY_URL} component={PrivacyPage} />
                <Redirect from="*" to={URI.PAGE_NOT_FOUND_URL} />
                <Redirect to={URI.HOME_PAGE_URL} />
            </Switch>
        </Suspense>
    );
};
