import { capitalize } from ".";

export const canadianProvincesWithNameAsKey = {
    "Alberta": "AB",
    "British Columbia": "BC",
    "Manitoba": "MB",
    "New Brunswick": "NB",
    "Newfoundland and Labrador": "NL",
    "Northwest Territories": "NT",
    "Nova Scotia": "NS",
    "Nunavut": "NU",
    "Ontario": "ON",
    "Prince Edward Island": "PE",
    "Quebec": "QC",
    "Saskatchewan": "SK",
    "Yukon Territory": "YT",
};

export const returnProvinceShortForm = (province: string) => {
    return canadianProvincesWithNameAsKey[capitalize(province, true)];
};
