import { Checkbox, Form, Input } from "antd";
import React, { Dispatch, lazy, SetStateAction } from "react";
import intl from "react-intl-universal";
import { useDispatch } from "react-redux";
import TextButton from "src/components/AuthenticationPage/TextButton";
import Spacer from "src/components/base/Spacer";
import Dialogs from "src/components/Shared/Dialogs";
import { requestSignup } from "src/redux/modules/Signup";
import useSelector from "src/redux/store/useSelector";
import { dataType } from "src/types/authentication.type";
import { authTitle, AUTH_TITLE } from "src/utils";
import { eRequirement, pRequirement } from "src/utils/regCheck";
import useGoogleAnalytics, { Category } from "src/utils/GoogleAnalytics";
import { Flex } from "src/components";
import styled from "styled-components";
import GoolgeLoginButton, {
    StyledButton,
} from "src/components/AuthenticationPage/GoogleLoginButton";
import OrDivider from "src/components/AuthenticationPage/OrDivider";

const TermsAndConditions = lazy(() => import("src/utils/terms"));

type SignupProps = {
    setTitle: React.Dispatch<React.SetStateAction<string>>;
    setData: Dispatch<SetStateAction<dataType>>;
    data: dataType;
};

const SignUp: React.FC<SignupProps> = ({ data, setData, setTitle }) => {
    const [terms, setTerms] = React.useState<boolean>(false);
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const loading = useSelector(
        (state) => state.login?.loading || state.signup?.loading || state.userInfo?.loading,
    );
    const { sendEvent } = useGoogleAnalytics();

    const handleLogin = () => {
        setTitle(authTitle(AUTH_TITLE.LOGIN));
    };

    const handleSignupForm = (body) => {
        sendEvent(Category.EDIT, `user interacts with signup form`, "SignUp");
        body.email = body.email.toLowerCase();
        setData({
            ...data,
            email: body.email,
            password: body.password,
        });
        dispatch(requestSignup(body));
    };

    return (
        <Flex column align="center" justify="center" style={{ width: "100%" }}>
            <Spacer height={24} />
            <Dialogs
                Html={TermsAndConditions}
                open={terms}
                title={"TERMS AND CONDITIONS"}
                toggle={() => setTerms(false)}
            />

            <Form layout={"vertical"} form={form} style={{ width: "100%" }}>
                <Form.Item
                    name="email"
                    initialValue={data.email}
                    rules={[
                        {
                            required: true,
                            message: intl.get("authentication.warning.inputEmail"),
                        },
                        {
                            pattern: eRequirement,
                            message: intl.get("authentication.warning.inputValidEmail"),
                        },
                    ]}
                >
                    <Input size="large" placeholder={intl.get("authentication.shared.email")} />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: intl.get("authentication.warning.inputPW"),
                        },
                        {
                            pattern: pRequirement,
                            message: intl.get("authentication.warning.inputValidPW"),
                        },
                    ]}
                >
                    <Input.Password
                        size="large"
                        placeholder={intl.get("authentication.shared.pw")}
                    />
                </Form.Item>
                <Form.Item
                    name="rpassword"
                    dependencies={["password"]}
                    rules={[
                        { required: true, message: intl.get("authentication.warning.retypePW") },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue("password") === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(
                                    new Error(intl.get("authentication.warning.pwNotMatch")),
                                );
                            },
                        }),
                    ]}
                >
                    <Input.Password
                        size="large"
                        placeholder={intl.get("authentication.shared.retypePW")}
                    />
                </Form.Item>

                <Form.Item
                    name="agreement"
                    valuePropName="checked"
                    rules={[
                        {
                            validator: (_, value) =>
                                value
                                    ? Promise.resolve()
                                    : Promise.reject(
                                          new Error(intl.get("authentication.warning.acceptTerm")),
                                      ),
                        },
                    ]}
                >
                    <StyledCheckbox>
                        {intl.get("authentication.signup.confirmTerms")}{" "}
                        <a onClick={() => setTerms(true)} href="# ">
                            {intl.get("authentication.signup.termOfUse")}
                        </a>
                    </StyledCheckbox>
                </Form.Item>

                <Form.Item shouldUpdate>
                    {() => (
                        <StyledButton
                            loading={loading}
                            htmlType="submit"
                            block
                            type="primary"
                            size="large"
                            onClick={() =>
                                handleSignupForm(form.getFieldsValue(["email", "password"]))
                            }
                            disabled={
                                !form.isFieldsTouched(
                                    ["rpassword", "password", "agreement"],
                                    true,
                                ) ||
                                !!form.getFieldsError().filter(({ errors }) => errors.length).length
                            }
                        >
                            {intl.get("authentication.shared.continueWithEmail")}
                        </StyledButton>
                    )}
                </Form.Item>
            </Form>
            <OrDivider />
            <GoolgeLoginButton renderType="register" />
            <Spacer height={16} />
            <TextButton
                text={intl.get("authentication.signup.alreadyHave")}
                button={intl.get("authentication.shared.login")}
                handlePress={handleLogin}
            />
        </Flex>
    );
};

const StyledCheckbox = styled(Checkbox)`
    .ant-checkbox-inner {
        border: ${({ theme }) => `1px solid ${theme.color.greySecondaryM}`};
        border-radius: 2px;
    }
`;

export default SignUp;
