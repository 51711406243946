import { USERINFO, UserInfoActionType } from "./types";

export const userInfoReducer = (state = {}, action: UserInfoActionType) => {
    switch (action.type) {
        case USERINFO.REQUEST:
        case USERINFO.SUCCESS:
        case USERINFO.FAILURE:
        case USERINFO.CLEAN:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
