import packageJson from '../package.json';
// @ts-ignore we set global variable for the last app version
global.appVersion = packageJson.version;

// version from response - first param, local version second param
const semverGreaterThan = (versionA, versionB) => {
  if (!versionB) return true;
  
  const versionsA = versionA.split(/\./g);
  const versionsB = versionB.split(/\./g);

  // compare major, then minor, then patch
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());
    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }

  return false;
};

const CacheBuster = (versionFromServer) => {
  let loading = true;
  let isLatestVersion = false;

  const refreshCacheAndReload = async () => {
    console.log('Clearing cache and hard reloading...')
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      const names = await caches.keys();
      await Promise.all(names.map(name => caches.delete(name)));

      // delete browser cache and hard reload
      window.location.reload();
    }

  };

  // @ts-ignore
  const currentVersion = global.appVersion;
  const shouldForceRefresh = semverGreaterThan(versionFromServer, currentVersion);
  if (shouldForceRefresh) {
    console.log(`We have a new version - ${versionFromServer}. Should force refresh`);
    loading = false;
    isLatestVersion = false;
  } else {
    loading = false;
    isLatestVersion = true;
  }

  return { loading, isLatestVersion, refreshCacheAndReload };
  
}

export default CacheBuster;