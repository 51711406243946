import { Nullable } from "src/utils";

export enum LOGIN {
    REQUEST = "LOGIN_REQUEST",
    SUCCESS = "LOGIN_SUCCESS",
    FAILURE = "LOGIN_FAILURE",
    ERROR = "LOGIN_ERROR",
    CLEAN = "LOGIN_CLEAN",
}

export interface LoginState {
    loading: boolean;
    data?: any;
    error?: Nullable<string>;
    query?: any;
}

export interface LoginActionType {
    type: LOGIN;
    payload: LoginState;
}
