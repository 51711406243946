import { Nullable } from "src/utils";

export enum SEARCH {
  REQUEST = "SEARCH_REQUEST",
  SUCCESS = "SEARCH_SUCCESS",
  FAILURE = "SEARCH_FAILURE",
  ERROR = "SEARCH_ERROR",
}

export interface SearchState {
  loading: boolean;
  data?: any;
  error?: Nullable<string>;
  query?: Nullable<string>;
}

export interface SearchActionType {
  type: SEARCH;
  payload: SearchState;
}
