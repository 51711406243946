import { Col, Row } from "antd";
import React from "react";
import styled from "styled-components";
import { HomePageContentWrapper } from "../Shared";
import {
    Narcity,
    Uwaterloo,
    CanadianSME,
    GoogleNews,
    YahooFinance,
    Betakit,
    BNNBloomberg,
    Storeys,
    TCN,
    Urbanize,
} from "src/static";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { Spacer } from "src/components";
import intl from "react-intl-universal";

export const FeaturedOnSection = () => {
    const bp = useBreakpoint();

    const handleClick = (url: string) => {
        window.open(url);
    };

    return (
        <HomePageContentWrapper>
            <Header bp={bp}>{intl.get("homePage.featuredOn.title")}</Header>
            <Spacer height={16} />
            <Row justify="center" gutter={[8, 32]} align="middle">
                <Col xs={0} sm={0} md={0} lg={2} />
                <Col xs={12} sm={12} md={24} lg={4}>
                    <ClickableImg
                        src={GoogleNews}
                        alt="Google News Logo"
                        onClick={() =>
                            handleClick(
                                "https://news.google.com/search?q=manorlead&hl=en-CA&gl=CA&ceid=CA%3Aen",
                            )
                        }
                    />
                </Col>
                <Col xs={12} sm={12} md={8} lg={4}>
                    <ClickableImg
                        src={BNNBloomberg}
                        alt="BNN Bloomberg Logo"
                        onClick={() =>
                            handleClick(
                                "https://www.bnnbloomberg.ca/ai-is-changing-how-people-find-their-next-home-1.1935138",
                            )
                        }
                    />
                </Col>
                <Col xs={12} sm={12} md={8} lg={4}>
                    <ClickableImg
                        src={YahooFinance}
                        alt="Yahoo Finance Logo"
                        onClick={() =>
                            handleClick(
                                "https://ca.finance.yahoo.com/news/canadian-innovation-soars-ai-driven-131500076.html",
                            )
                        }
                    />
                </Col>
                <Col xs={12} sm={12} md={8} lg={4}>
                    <ClickableImg
                        src={Uwaterloo}
                        alt="Uwaterloo Logo"
                        onClick={() =>
                            handleClick("https://uwaterloo.ca/news/chance-favours-prepared-mind")
                        }
                    />
                </Col>
                <Col xs={12} sm={12} md={8} lg={4}>
                    <ClickableImg
                        src={Narcity}
                        alt="Narcity Logo"
                        onClick={() =>
                            handleClick(
                                "https://www.narcity.com/toronto/chatgpt-for-real-estate-tool-is-gta-housing-market-hack",
                            )
                        }
                    />
                </Col>
                <Col xs={0} sm={0} md={0} lg={2} />
                <Col xs={12} sm={12} md={8} lg={4}>
                    <ClickableImg
                        src={Urbanize}
                        alt="Urbanize Logo"
                        onClick={() =>
                            handleClick(
                                "https://nyc.urbanize.city/post/how-artificial-intelligence-could-help-you-find-your-next-home",
                            )
                        }
                    />
                </Col>
                <Col xs={12} sm={12} md={8} lg={4}>
                    <ClickableImg
                        src={CanadianSME}
                        alt="Canadian SME Logo"
                        onClick={() =>
                            handleClick(
                                "https://canadiansme.ca/student-talent-is-here-to-solve-tomorrows-problems-today/",
                            )
                        }
                    />
                </Col>
                <Col xs={12} sm={12} md={8} lg={4}>
                    <ClickableImg
                        src={Betakit}
                        alt="Betakit Logo"
                        onClick={() =>
                            handleClick(
                                "https://betakit.com/spellbook-formerly-rally-raises-10-9-million-to-automate-legal-contracts-using-generative-ai/",
                            )
                        }
                    />
                </Col>
                <Col xs={12} sm={12} md={8} lg={4}>
                    <ClickableImg
                        src={TCN}
                        alt="TCN Logo"
                        onClick={() =>
                            handleClick(
                                "https://www.techcompanynews.com/manorlead-revolutionizing-real-estate-with-ai-technology/",
                            )
                        }
                    />
                </Col>
                <Col xs={12} sm={12} md={8} lg={4}>
                    <ClickableImg
                        src={Storeys}
                        alt="Storeys Logo"
                        onClick={() =>
                            handleClick("https://storeys.com/19-royal-oak-drive-toronto-for-sale/")
                        }
                    />
                </Col>
            </Row>
        </HomePageContentWrapper>
    );
};

const StyledImg = styled.img`
    height: 50px;
    width: 100%;
    object-fit: contain;
    position: relative;
`;

const ClickableImg = styled(StyledImg)`
    cursor: pointer;
`;

const Header = styled.div`
    font-family: ${({ theme }) => theme.fontFamily.homeBody};
    ${({ theme }) => theme.styleGroup.body}
    font-size: ${(props: { bp; theme }) =>
        props.bp.xs ? `${props.theme.fontSize.bodyXs}px` : `${props.theme.fontSize.bodyS}px`};
    font-weight: 700;
    letter-spacing: 0.16em;
    color: #687ded;
    text-transform: uppercase;
    text-align: center;
`;
