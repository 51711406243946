import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import intl from "react-intl-universal";
import { shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";
import SimpleReactLightbox from "simple-react-lightbox";
import useSelector from "src/redux/store/useSelector";
import styled from "styled-components";
import { BasicLayout } from "./components/Layout/BasicLayout";
import { locales } from "./components/Shared/localeSelector";
import { useSetupInterceptors } from "./services/client";
import { QueryClientProviderWrapper } from "./utils/queryClient";
import "src/assets/css/CardDetail.css";

const IntlWarningHandler = (message) => {
    if (message.includes("map.propertyType.")) {
        return;
    }
    console.warn(message);
};

const App = () => {
    const [intlDone, setIntlDone] = useState(false);
    const { setupInterceptors } = useSetupInterceptors();
    const LocaleResponse: string | undefined = useSelector(
        (state) => state?.locale?.lang,
        shallowEqual,
    );

    const history = useHistory();

    useEffect(() => {
        setupInterceptors(history);
    }, [history]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // google analytic tracking
        ReactGA.initialize("G-JCF3PN6W29");
        // set default loading animation
        Spin.setDefaultIndicator(<StyledLoadingOutlined />);
    }, []);

    useEffect(() => {
        intl.init({
            currentLocale: LocaleResponse || "en",
            locales: locales,
            warningHandler: IntlWarningHandler,
        }).then(() => setIntlDone(true));
    }, [LocaleResponse]);

    return (
        <SimpleReactLightbox>
            <QueryClientProviderWrapper>{intlDone && <BasicLayout />}</QueryClientProviderWrapper>
        </SimpleReactLightbox>
    );
};

export default App;

const StyledLoadingOutlined = styled(LoadingOutlined)`
    font-size: 80px;
`;
