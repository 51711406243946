import { setToken } from "src/services/client";
import { client } from "src/services/client";

export const fetchLogin = async (query: any) =>
    client({
        method: query.method,
        url: `/auth/${query.endpoint}`,
        headers: { "Content-Type": "application/json" },
        data: query.body,
    })
        .then((response) => response.data)
        .then((res) => {
            if (res?.cognitoJWT?.access_token) {
                setToken(res?.cognitoJWT?.access_token);
            }
            return res;
        });
