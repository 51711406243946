import React from "react";
import { Modal } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { Breakpoint } from "antd/lib/_util/responsiveObserve";
import { useDispatch, shallowEqual } from "react-redux";
import intl from "react-intl-universal";
import useSelector from "src/redux/store/useSelector";
import { useHistory, useLocation } from "react-router-dom";
import { AUTH_TITLE } from "src/utils/constants";
import { authTitle, expired, pathInBlockerWhiteList, BLOCKER_TYPES } from "src/utils";
import styled from "styled-components";
import { Spacer } from "../base";
import ButtonLink from "../base/Link/ButtonLink";
import FullLogo from "../Navbar/FullLogo";
import { setLoginPopup, setBlocker } from "src/redux/modules/Setting";
import useGoogleAnalytics, { Category } from "src/utils/GoogleAnalytics";

const AuthBlocker = () => {
    const bp = useBreakpoint();
    const history = useHistory();
    const { pathname } = useLocation();
    const [blockerType, setBlockerType] = React.useState<BLOCKER_TYPES>(BLOCKER_TYPES.CLOSE);
    const [title, setTitle] = React.useState<string>("detail");
    const [showBlocker, setShowBlocker] = React.useState<boolean>(false);
    const [lastUnblockedLocation, setLastUnblockedLocation] = React.useState<string>("/");
    const blockerResponse = useSelector((state) => state.settings?.blocker ?? [], shallowEqual);
    const loginPopupResponse = useSelector((state) => state.settings?.login ?? [], shallowEqual);
    const loginResponse = useSelector((state) => state.login?.data ?? [], shallowEqual);
    const userInfoResponse = useSelector((state) => state.userInfo?.data ?? [], shallowEqual);
    const dispatch = useDispatch();

    const { sendEvent } = useGoogleAnalytics();

    const handleClose = () => {
        if (blockerType === BLOCKER_TYPES.PAGE) {
            history.push(lastUnblockedLocation);
        }
        sendEvent(Category.DISMISS, "Dismiss auth modal", "AuthBlocker");
        dispatch(setBlocker(BLOCKER_TYPES.CLOSE));
    };

    React.useEffect(() => {
        if (pathInBlockerWhiteList(pathname) && !pathname.includes("/listing")) {
            setLastUnblockedLocation(pathname);
        }
    }, [pathname]);

    React.useEffect(() => {
        if (!blockerResponse.blockerType) {
            return;
        }
        if (
            blockerType &&
            blockerType !== BLOCKER_TYPES.CLOSE &&
            blockerResponse.blockerType !== BLOCKER_TYPES.CLOSE &&
            !expired(loginResponse, userInfoResponse)
        ) {
            // user login while blocker is showing
            if (blockerResponse.onLogin) {
                blockerResponse.onLogin();
            }
            dispatch(setBlocker(BLOCKER_TYPES.CLOSE));
        } else {
            setBlockerType(blockerResponse.blockerType);
            setTitle(blockerResponse.blockerTitle);
        }
    }, [blockerResponse, loginResponse, userInfoResponse]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        setShowBlocker(
            blockerResponse.blockerType &&
                blockerResponse.blockerType !== BLOCKER_TYPES.CLOSE &&
                !loginPopupResponse.loginPopup,
        );
    }, [blockerResponse.blockerType, loginPopupResponse.loginPopup]); // eslint-disable-line react-hooks/exhaustive-deps

    const registerLinkClicked = (action: number) => {
        if (action === AUTH_TITLE.SIGNUP) {
            sendEvent(Category.BUTTON, "Sign-up clicked on auth modal", "AuthBlocker");
        }
        if (action === AUTH_TITLE.LOGIN) {
            sendEvent(Category.BUTTON, "Log-in clicked on auth modal", "AuthBlocker");
        }
        dispatch(setLoginPopup(true, authTitle(action)));
    };

    return (
        <StyledModal
            visible={showBlocker}
            footer={null}
            closable={true}
            onCancel={handleClose}
            width={""}
            maskStyle={{ backdropFilter: "blur(5px)", alignItems: "center", display: "flex" }}
            bodyStyle={{
                maxWidth: " 832px",
                minHeight: "404px",
            }}
            style={{ justifyContent: "center", display: "flex" }}
        >
            <CardContainer bp={bp}>
                <FullLogo />
                <Spacer height={6} />
                <Title bp={bp}>{intl.get(`blocker.title.${title}`)}</Title>
                <TitleDes bp={bp}>{`${intl.get("listing.blocker.titleDescription")}`}</TitleDes>

                <ul style={{ marginBottom: "20px" }}>
                    <BulletText bp={bp}>{`${intl.get("listing.blocker.pointOne")}`}</BulletText>
                    <BulletText bp={bp}>{`${intl.get("listing.blocker.pointTwo")}`}</BulletText>
                    <BulletText bp={bp}>{`${intl.get("listing.blocker.pointThree")}`}</BulletText>
                </ul>

                <ButtonLink
                    text={`${intl.get("listing.blocker.register")}`}
                    href="#/"
                    onClick={() => registerLinkClicked(AUTH_TITLE.SIGNUP)}
                />
                <LoginText bp={bp}>
                    {`${intl.get("listing.blocker.haveAccount")}`}
                    <a
                        href="#/"
                        onClick={() => registerLinkClicked(AUTH_TITLE.LOGIN)}
                        style={{ color: "#FFB800" }}
                    >
                        <u>
                            <b>{`${intl.get("listing.blocker.signIn")}`}</b>
                        </u>
                    </a>
                </LoginText>
            </CardContainer>
        </StyledModal>
    );
};

const StyledModal = styled(Modal)`
    .ant-modal-content {
        background: rgba(255, 255, 255, 0.9);
    }
`;

const LoginText = styled.div`
    margin-top: 12px;
    font-family: ${({ theme }) => theme.fontFamily.homeBody};
    font-weight: 400;
    font-size: ${(props: { bp; theme }) =>
        props.bp.xs ? `${props.theme.fontSize.h4}px` : `${props.theme.fontSize.bulletTitle}px`};
    color: ${({ theme }) => theme.color.greySecondaryD};
`;

const BulletText = styled.li`
    font-family: ${({ theme }) => theme.fontFamily.homeBody};
    font-style: normal;
    font-weight: normal;
    font-size: ${(props: { bp; theme }) =>
        props.bp.xs ? `${props.theme.fontSize.h4}px` : `${props.theme.fontSize.bulletTitle}px`};
    text-transform: capitalize;
    color: ${({ theme }) => theme.color.blackPrimary};
`;

const TitleDes = styled.div`
    font-family: ${({ theme }) => theme.fontFamily.homeBody};
    font-style: normal;
    font-weight: normal;
    font-size: ${(props: { bp; theme }) =>
        props.bp.xs ? `${props.theme.fontSize.h5}px` : `${props.theme.fontSize.h4}px`};
    text-transform: capitalize;
    color: ${({ theme }) => theme.color.blackPrimary};
    margin-bottom: 18px;
    text-align: center;
`;

const Title = styled.div`
    font-family: ${({ theme }) => theme.fontFamily.heading};
    font-style: normal;
    font-size: ${(props: { bp; theme }) =>
        props.bp.xs ? `${props.theme.fontSize.h4}px` : `${props.theme.fontSize.subtitle}px`};
    ${({ theme }) => theme.styleGroup.heading}
    text-transform: capitalize;
    color: ${({ theme }) => theme.color.blackPrimary};
    margin-bottom: 18px;
    text-align: center;
`;

const CardContainer = styled.div`
    max-width: 832px;
    min-height: 404px;
    padding: ${(props: { bp: Partial<Record<Breakpoint, boolean>> }) =>
        props.bp.xs ? "40px 20px" : "40px 80px"};
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
`;

export default AuthBlocker;
