import { put, takeLatest } from "redux-saga/effects";
import * as Effects from "redux-saga/effects";

import { DEVELOPMENT, DevelopmentActionType } from "./types";
import { fetchDevelopment } from "./services";
import get from "lodash.get";
import { receiveDevelopmentSuccess, receiveDevelopmentFailure } from "./actions";
import { SagaIterator } from "redux-saga";
import { DevelopmentView } from "src/types/listing.type";

const call: any = Effects.call;

export interface NewDevelopmentDataType {
    developments: DevelopmentView[];
    page: {
        pageCount: string;
        offset: string;
    };
}

function* getDevelopment(action: DevelopmentActionType): SagaIterator {
    try {
        const data: NewDevelopmentDataType = yield call(fetchDevelopment, action.payload.query);
        if (data) {
            yield put(receiveDevelopmentSuccess(data, action.payload.query));
        } else {
            yield put(receiveDevelopmentFailure("ERROR: Failed to fetch residential development"));
        }
    } catch (error) {
        yield put(receiveDevelopmentFailure(get(error, "message", "Network Error!")));
    }
}

export function* watchGetDevelopmentRequest(): SagaIterator {
    yield takeLatest(DEVELOPMENT.REQUEST, getDevelopment);
}
