import { Col, Row } from "antd";
import React from "react";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import styled from "styled-components";
import intl from "react-intl-universal";

import { ModuleContainer } from "src/components/base/ModuleContainer";
import { Spacer } from "src/components/base";
import { theme } from "src/theme";

export const QandA = () => {
    const bp = useBreakpoint();

    const pointIDs = ["1", "2", "3", "4"];

    return (
        <>
            <ModuleContainer
                backgroundStyle={{ backgroundColor: "rgba(27, 22, 66)" }}
                containerStyle={{
                    width: 1000,
                    padding: `80px 16px`,
                    color: theme.color.white,
                }}
            >
                <Title bp={bp}>{intl.get("homePage.QandA.title")}</Title>
                <SubTitle bp={bp}>{intl.get("homePage.QandA.subTitle")}</SubTitle>
                <Spacer height={40} />
                <Row gutter={[32, 32]}>
                    {pointIDs.map((id) => (
                        <Col xs={24} sm={24} md={12} lg={12} key={id}>
                            <PointTitle bp={bp}>
                                {intl.get(`homePage.QandA.pointTitle${id}`)}
                            </PointTitle>
                            <Spacer height={16} />
                            <PointBody bp={bp}>
                                {intl.get(`homePage.QandA.pointBody${id}`)}
                            </PointBody>
                        </Col>
                    ))}
                </Row>
            </ModuleContainer>
        </>
    );
};

const Title = styled.div`
    font-family: ${({ theme }) => theme.fontFamily.homeHeading};
    ${({ theme }) => theme.styleGroup.heading}
    font-size: ${(props: { bp; theme }) => (props.bp.xs ? `${props.theme.fontSize.h2}px` : `48px`)};
    text-align: center;
`;

const SubTitle = styled.div`
    font-family: ${({ theme }) => theme.fontFamily.homeBody};
    ${({ theme }) => theme.styleGroup.body}
    font-size: ${(props: { bp; theme }) =>
        props.bp.xs ? `${props.theme.fontSize.bodyM}px` : `${props.theme.fontSize.bodyL}px`};
    font-weight: 400;
    opacity: 0.6;
    text-align: center;
`;

const PointTitle = styled.div`
    font-family: ${({ theme }) => theme.fontFamily.homeBody};
    ${({ theme }) => theme.styleGroup.body}
    font-size: ${(props: { bp; theme }) =>
        props.bp.xs ? `${props.theme.fontSize.bodyL}px` : `${props.theme.fontSize.bulletTitle}px`};
    line-height: 24px;
    font-weight: 700;
`;

const PointBody = styled(PointTitle)`
    font-weight: 400;
    font-size: ${(props: { bp; theme }) =>
        props.bp.xs ? `${props.theme.fontSize.bodyM}px` : `${props.theme.fontSize.bodyL}px`};
`;
