import React from "react";
import styled from "styled-components";
import { Divider } from "antd";

const OrDivider = () => {
    return <StyledDivider>or</StyledDivider>;
};

const StyledDivider = styled(Divider)`
    && {
        margin-top: 0px;
        color: ${({ theme }) => theme.color.greySecondaryM};
        border-top-color: ${({ theme }) => theme.color.greySecondaryM};
    }
`;

export default OrDivider;
