import React from "react";
import styled, { css } from "styled-components";

import Link, { LinkProps } from "./index";
import Text from "../Text";

export type ButtonLinkProps = Omit<LinkProps, "defaultStyles"> & {
    text: string;
};

const linkButtonStyles = css`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
`;

const StyledText = styled(Text)`
    font-family: ${({ theme }) => theme.fontFamily.body};
    ${({ theme }) => theme.styleGroup.button}
    font-size: ${({ theme }) => theme.fontSize.bodyM}px;
    color: ${({ theme }) => theme.color.blackPrimary};
`;

const StyledLink = styled(Link)`
    ${linkButtonStyles}
    background-color: ${({ theme }) => theme.color.manorleadGold};
    border-radius: 8px;
`;

const ButtonLink: React.FC<ButtonLinkProps> = ({ text, ...rest }) => (
    <StyledLink defaultstyles={"false"} {...rest}>
        <StyledText>{text}</StyledText>
    </StyledLink>
);

export default ButtonLink;
