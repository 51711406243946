import { COMMUNITY, CommunityActionType } from "./types";

export const communityReducer = (state = {}, action: CommunityActionType) => {
    switch (action.type) {
        case COMMUNITY.REQUEST:
        case COMMUNITY.SUCCESS:
        case COMMUNITY.FAILURE:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
