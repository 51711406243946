import { Nullable } from "src/utils";
import { DEVELOPMENT, DevelopmentActionType } from "./types";

export const requestDevelopment = (query: { [key: string]: string }): DevelopmentActionType => ({
    type: DEVELOPMENT.REQUEST,
    payload: {
        loading: true,
        query: query,
        error: null,
    },
});

export const receiveDevelopmentSuccess = (
    data: any,
    query: Nullable<{ [key: string]: string }>,
): DevelopmentActionType => ({
    type: DEVELOPMENT.SUCCESS,
    payload: {
        loading: false,
        query: query,
        data: data.developments,
        page: data.page,
        error: null,
    },
});

export const receiveDevelopmentFailure = (error: string): DevelopmentActionType => ({
    type: DEVELOPMENT.FAILURE,
    payload: {
        loading: false,
        query: null,
        data: null,
        error: error,
    },
});
