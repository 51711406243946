import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

const Sitemap = () => {
    let history = useHistory();
    const { name }: { name: string } = useParams();

    const validName = () => {
        if (name === "important-pages-sitemap.xml") {
            return true;
        }

        // check the format is xxx.xml
        const separateFileType = name.split(".");
        if (separateFileType.length !== 2 || separateFileType[1] !== "xml") {
            return false;
        }

        // check the format is listing-sitemap-xxx-xxx-xxx.xml or development-sitemap-xxx-xxx-xxx.xml
        const separateName = separateFileType[0].split("-");
        if (
            separateName.length !== 5 ||
            separateName[1] !== "sitemap" ||
            (separateName[0] !== "listing" && separateName[0] !== "development")
        ) {
            return false;
        }

        // check the date part within the range
        const startDate = new Date("2022-01-16").getTime();
        const today = new Date().getTime();
        const urlDate = new Date(separateName.slice(2).join("-")).getTime();
        if (urlDate < startDate || urlDate > today) {
            return false;
        }

        return true;
    };

    if (validName()) {
        window.location.href = `https://sitemap.manorlead.com/${name}`;
    } else {
        history.push("/page-not-found");
    }

    return null;
};

export default Sitemap;
