import { Button } from "antd";
import React, { useEffect, useState } from "react";
import intl from "react-intl-universal";
import { useDispatch } from "react-redux";
import { Flex } from "src/components";
import Spacer from "src/components/base/Spacer";
import { setLoginPopup } from "src/redux/modules/Setting/actions";
import { AUTH_TITLE } from "src/utils/constants";
import { authTitle } from "src/utils/helpers";
import styled from "styled-components";

type SuccessProps = {
    setTitle: React.Dispatch<React.SetStateAction<string>>;
    title: string;
};

type dataType = {
    subtitle: string;
    buttonText: string;
};

const defaultData: dataType = {
    subtitle: "",
    buttonText: "",
};

const Successful: React.FC<SuccessProps> = ({ setTitle, title }) => {
    const [data, setData] = useState<dataType>(defaultData);
    const dispatch = useDispatch();
    const handleSuccess = () => {
        if (title === authTitle(AUTH_TITLE.SUCCESS_RESET)) {
            setTitle(authTitle(AUTH_TITLE.LOGIN));
        } else if (title === authTitle(AUTH_TITLE.SUCCESS_SIGNUP)) {
            dispatch(setLoginPopup(false));
        }
    };

    useEffect(() => {
        if (title === authTitle(AUTH_TITLE.SUCCESS_RESET)) {
            setData({
                subtitle: intl.get("authentication.success.resetPW.subtitle"),
                buttonText: intl.get("authentication.shared.login"),
            });
        } else if (title === authTitle(AUTH_TITLE.SUCCESS_SIGNUP)) {
            setData({
                subtitle: intl.get("authentication.success.signup.subtitle"),
                buttonText: intl.get("authentication.success.signup.button"),
            });
        }
    }, [title]);

    return (
        <Flex column align="stretch" justify="center">
            <Spacer height={16} />
            <SubTitle>{data.subtitle}</SubTitle>
            <Spacer height={48} />
            <Button block type="primary" size="large" onClick={handleSuccess}>
                {data.buttonText}
            </Button>
        </Flex>
    );
};

const SubTitle = styled.p`
    font-family: ${({ theme }) => theme.fontFamily.body};
    ${({ theme }) => theme.styleGroup.body}
    font-style: normal;
    font-size: ${({ theme }) => theme.fontSize.h3};
    display: flex;
    text-transform: none;
    color: ${({ theme }) => theme.color.blackPrimary};
`;

export default Successful;
