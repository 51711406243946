import { Form, Input } from "antd";
import React, { Dispatch, SetStateAction } from "react";
import intl from "react-intl-universal";
import { Flex } from "src/components";
import { BasicButton } from "src/components/AuthenticationPage";
import Spacer from "src/components/base/Spacer";
import useSelector from "src/redux/store/useSelector";
import { dataType } from "src/types/authentication.type";
import { eRequirement } from "src/utils/regCheck";
import GoogleLoginButton, {
    StyledButton,
} from "src/components/AuthenticationPage/GoogleLoginButton";
import OrDivider from "src/components/AuthenticationPage/OrDivider";
import { authTitle, AUTH_TITLE } from "src/utils";
import TextButton from "src/components/AuthenticationPage/TextButton";

type LoginProps = {
    setTitle: React.Dispatch<React.SetStateAction<string>>;
    handleLoginForm: (body: { email: string; password: string }) => void;
    setData: Dispatch<SetStateAction<dataType>>;
    data: dataType;
};

const Login: React.FC<LoginProps> = ({ setTitle, handleLoginForm, setData, data }) => {
    const [form] = Form.useForm();
    const loading = useSelector(
        (state) => state.login?.loading || state.signup?.loading || state.userInfo?.loading,
    );

    const handleForget = async () => {
        const body = form.getFieldsValue();

        setData({
            ...data,
            email: body.email || "",
        });
        setTitle(intl.get("authentication.shared.forgotPassword"));
    };

    const handleRegister = () => {
        setTitle(authTitle(AUTH_TITLE.SIGNUP));
    };

    return (
        <Flex column align="center" justify="center" style={{ width: "100%" }}>
            <Spacer height={24} />
            <Form layout={"vertical"} form={form} style={{ width: "100%" }}>
                <Form.Item
                    name="email"
                    initialValue={data.email}
                    rules={[
                        {
                            required: true,
                            message: intl.get("authentication.warning.inputEmail"),
                        },
                        {
                            pattern: eRequirement,
                            message: intl.get("authentication.warning.inputValidEmail"),
                        },
                    ]}
                >
                    <Input size="large" placeholder={intl.get("authentication.shared.email")} />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[
                        { required: true, message: intl.get("authentication.warning.inputPW") },
                    ]}
                >
                    <Input.Password
                        size="large"
                        type="password"
                        placeholder={intl.get("authentication.shared.pw")}
                    />
                </Form.Item>

                <Form.Item shouldUpdate>
                    {() => (
                        <StyledButton
                            loading={loading}
                            htmlType="submit"
                            block
                            type="primary"
                            size="large"
                            onClick={() => handleLoginForm(form.getFieldsValue())}
                            disabled={
                                !form.isFieldsTouched(["password"], true) ||
                                !!form.getFieldsError().filter(({ errors }) => errors.length).length
                            }
                        >
                            {intl.get("authentication.shared.continueWithEmail")}
                        </StyledButton>
                    )}
                </Form.Item>
            </Form>
            <OrDivider />
            <GoogleLoginButton renderType="login" />
            <Spacer height={16} />
            <TextButton
                text={intl.get("authentication.shared.noAccount")}
                button={intl.get("authentication.shared.registerNow")}
                handlePress={handleRegister}
            />
            <Spacer height={16} />
            <BasicButton
                text={intl.get("authentication.shared.forgotPassword")}
                handlePress={handleForget}
                type="link"
            />
        </Flex>
    );
};

export default Login;
