import { put, takeLatest, call } from "redux-saga/effects";
import { SCHOOL_LISTINGS, School_ListingsActionType } from "./types";
import { fetchSchools } from "./services";
import { receiveSchoolListingsSuccess, receiveSchoolListingsFailure } from ".";
import get from "lodash.get";
import { SchoolView } from "src/types/school.type";

function* getListings(action: School_ListingsActionType) {
    try {
        const data = yield call(fetchSchools, action.payload.query);
        const listings: SchoolView[] = get(data, "schools", null);
        if (listings) {
            yield put(receiveSchoolListingsSuccess(listings, action.payload.query));
        } else {
            yield put(receiveSchoolListingsFailure("ERROR: Failed to fetch school data"));
        }
    } catch (error) {
        yield put(receiveSchoolListingsFailure(get(error, "message", "Network Error!")));
    }
}

export function* watchGetSchoolListingsRequest() {
    yield takeLatest(SCHOOL_LISTINGS.REQUEST, getListings);
}
