import React from "react";
import { Input } from "antd";
import { Spacer, Text, Flex } from "src/components";
import styled from "styled-components";

export type TitltedInputProps = {
    type: string;
    placeholder?: string;
    text: string;
    value?: string;
    handleChange: (value: any) => void;
};

const TitledInput: React.FC<TitltedInputProps> = ({
    type,
    placeholder,
    text,
    value,
    handleChange,
}) => {
    return (
        <InputBox column justify="flex-start" align="flex-start">
            <Title>{text}</Title>
            <Spacer height={10} />
            <AntdInput
                placeholder={text}
                value={placeholder}
                type={type}
                disabled={text === "Email Address"}
                onChange={(value) => handleChange(value)}
                defaultValue={value}
            />
        </InputBox>
    );
};

const AntdInput = styled(Input)`
    background-color: ${({ theme }) => theme.color.greySecondaryL};
    padding: 8px;
    border-radius: 4px;
    min-width: 100px;
    width: 100%;
`;

const InputBox = styled(Flex)`
    padding: 10px;
`;

const Title = styled(Text)`
    margin-left: 5px;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-transform: capitalize;
    color: ${({ theme }) => theme.color.blackPrimary};
`;

export default TitledInput;
