import { Nullable } from "src/utils";
import { PageResponse, RoomData } from "src/types/listing.type";

export enum LISTINGS {
    REQUEST = "LISTINGS_REQUEST",
    SUCCESS = "LISTINGS_SUCCESS",
    FAILURE = "LISTINGS_FAILURE",
    ERROR = "LISTINGS_ERROR",
}

export interface ListingQuery {
    filters: string;
    isSold?: boolean;
}

export interface ListingsState {
    loading: boolean;
    data?: RoomData[] | null;
    page?: PageResponse;
    error?: Nullable<string>;
    query?: Nullable<ListingQuery>;
}

export interface ListingsActionType {
    type: LISTINGS;
    payload: ListingsState;
}
