import { Nullable } from "src/utils";

export enum DEVELOPMENT_CLUSTERS {
  REQUEST = "DEVELOPMENT_CLUSTERS_REQUEST",
  SUCCESS = "DEVELOPMENT_CLUSTERS_SUCCESS",
  FAILURE = "DEVELOPMENT_CLUSTERS_FAILURE",
  ERROR = "DEVELOPMENT_CLUSTERS_ERROR",
}

export interface DevelopmentClustersState {
  loading: boolean;
  data?: any;
  error?: Nullable<string>;
  query?: Nullable<string>;
}

export interface DevelopmentClustersActionType {
  type: DEVELOPMENT_CLUSTERS;
  payload: DevelopmentClustersState;
}
