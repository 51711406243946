import { put, takeLatest } from "redux-saga/effects";
import * as Effects from "redux-saga/effects";

import { CONDO, CondoActionType, CondoDataType } from "./types";
import { fetchCondo } from "./services";
import get from "lodash.get";
import { receiveCondoSuccess, receiveCondoFailure } from "./actions";
import { SagaIterator } from "redux-saga";

const call: any = Effects.call;

function* getCondo(action: CondoActionType): SagaIterator {
    try {
        const data: CondoDataType = yield call(fetchCondo, action.payload.query);
        if (data) {
            yield put(receiveCondoSuccess(data, action.payload.query));
        } else {
            yield put(receiveCondoFailure("ERROR: Failed to fetch condo"));
        }
    } catch (error) {
        yield put(receiveCondoFailure(get(error, "message", "Network Error!")));
    }
}

export function* watchGetCondoRequest(): SagaIterator {
    yield takeLatest(CONDO.REQUEST, getCondo);
}
