import React, { ComponentPropsWithoutRef } from "react";
import styled from "styled-components";

type TextElements = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "span";

export type TextProps = ComponentPropsWithoutRef<"p"> & {
  /**
   * What HTML element type to use to render the component
   */
  as?: TextElements;
};

const TextElementMapping: Record<TextElements, React.FC> = {
  h1: styled.h1``,
  h2: styled.h2``,
  h3: styled.h3``,
  h4: styled.h4``,
  h5: styled.h5``,
  h6: styled.h6``,
  p: styled.p``,
  span: styled.span``,
};

export const Text: React.FC<TextProps> = ({ as = "p", children, ...rest }) => {
  const Element = TextElementMapping[as];
  return <Element {...rest}>{children}</Element>;
};

export default Text;
