function formatMoney(x: any): string {
    if (x === "-") {
        return x;
    }
    if (x) {
        const roundedNumber = Math.round(Number(x));
        return roundedNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return "0";
}

export default formatMoney;
