import { MapConf } from "./maps.type";

export interface ContactAgentForm {
    name: string;
    phone: string;
    userEmail: string;
    content: string;
}

export interface RoomsDetail {
    roomType: string;
    roomNumber: number;
    features: string;
    roomLength: number;
    roomWidth: number;
}

export interface VirtualTourData {
    virtualTourUrl: string;
    virtualTourUrlType: string;
}

export interface RoomData {
    id: string;
    active: boolean;
    unitNumber?: string;
    streetAddress: string;
    mlsNumber: string;
    bedrooms: string;
    extraBedrooms: string;
    baths: string;
    community: string;
    city: string;
    area: string;
    postalCode: string;
    price: string;
    soldPrice: string;
    originalPrice: string;
    virtualTourUrl?: VirtualTourData;
    sqftMin: string;
    sqftMax: string;
    leaseOrSale: string;
    propertyType: string;
    description: string;
    brokerage: string;
    closingDate: string;
    style: string;
    lotDepth: string;
    lotFront: string;
    occupancy: string;
    heating: string;
    cooling: string;
    fuel: string;
    rooms: string;
    parkingSpcs: string;
    garType: string;
    garSpaces: string;
    water: string;
    lng: string;
    lat: string;
    images: string[];
    openHouseTag: boolean;
    listedByManorleadTag: boolean;
    hotTag: boolean;
    taxes: number;
    pool: string;
    yearBuilt: string;
    exterior1: string;
    exterior2?: string;
    roomsDetails: RoomsDetail[];
    daysOnManorlead: number;
    userViews: number;
    schoolBoundary: SchoolBoundaryDetail[];
    prediction: string;
    sold: boolean;
    basementOne?: string;
    basementTwo?: string;
}

export interface SchoolBoundaryDetail {
    latitude: number;
    longitude: number;
    boundaryType: string;
    id: string;
    schoolName: string;
    area: string;
    rating: number;
    ratingYear: number;
    address: string;
    website: string;
    province: string;
    municipality: string;
    phone: string;
    level: string;
    boardType: string;
    distance: number;
}

export interface CommunityStatistics {
    community: string;
    communityCode: string;
    medianListPrice: number;
    medianDaysOnMarket: number;
    numberOfNewListings: number;
    numberOfSoldListings: number;
    medianPricePerSqft: number;
    rangeStart: Date;
    rangeEnd: Date;
    medianSoldPrice: number;
}
export interface CommunityStatsProps {
    communityStatsData: {
        community?: string;
        communityCode?: string;
        medianListPrice?: number;
        medianDaysOnMarket?: number;
        numberOfNewListings?: number;
        numberOfSoldListings?: number;
        medianPricePerSqft?: number;
        rangeStart?: Date;
        rangeEnd?: Date;
        medianSoldPrice?: number;
    };
}
export interface ListingHistory {
    brokerage?: string;
    lastUpdatedTimestamp?: Date;
    lsc: string;
    price?: number;
    soldPrice?: number;
    mlsNumber: string;
    leaseOrSale: string;
    listingId: string;
}

export interface MunicipalityStatisticsData {
    community: string;
    communityCode: string;
    medianListPrice: number;
    medianSoldPrice: number;
    province: string;
    rangeEnd: string;
    rangeStart: string;
    numberOfNewListings: number;
    numberOfSoldListings: number;
    medianDaysOnMarket: number;
}

export interface ListingData {
    communityStatistics: CommunityStatistics;
    listingHistory: ListingHistory[];
    recommendations: RoomData[];
    residentialListing: RoomData[];
    soldListingsInCommunity: RoomData[];
    municipalityStatistics: MunicipalityStatisticsData;
}

export interface Developer {
    id: string;
    name: string;
}

export interface DetailType {
    id: string;
    title: string;
    description: string;
}

export interface Amenity {
    id: string;
    name: string;
    type: string;
    description?: string;
}

export interface Policy {
    id: string;
    name: string;
    description?: string;
}

export interface Plan {
    id: string;
    name: string;
    type: string;
    beds: number;
    baths: number;
    sqft: number;
    startingPrice?: number;
    available: boolean;
    imageUrls: string[];
    potentialSavings?: number;
    direction: string;
}

export interface FloorPlanType {
    [key: string]: Plan[];
}

export interface Promotion {
    promotionName: string;
    promotionSavings?: number;
    free: boolean;
}

export interface FloorPlanPromotion {
    startingPrice: number;
    beds: number;
    promotions: Promotion[];
}

export interface PromotionPrice {
    floorPlanPromotions: FloorPlanPromotion[];
    minPromotions: Promotion[];
}

export interface DevelopmentPromotion {
    description: string;
    expiryDate: number;
    id: string;
    imageUrls: string[];
    title: string;
    percentSavings: number;
    promotionType: string;
}

export interface NewDevelopmentDetail {
    id: string;
    name: string;
    longitude: number;
    latitude: number;
    phase: string;
    status: string;
    ownership: string;
    features: string;
    developers: Developer[];
    description: string;
    developmentTypes: string[];
    details: DetailType[];
    municipality: string;
    province: string;
    address: string;
    presentationAddress: string;
    postalCode: string;
    capacity: string;
    minPrice: number;
    maxPrice: number;
    minBed: number;
    maxBed: number;
    minType: string;
    maxType: string;
    minSqft: number;
    maxSqft: number;
    completionYear: number;
    completionSeason: string;
    saleYear: number;
    saleSeason: string;
    amenities: Amenity[];
    policies: Policy[];
    paymentStructures: string[];
    floorPlans: FloorPlanType;
    heroImageUrl: string;
    amenityImageUrls: string[];
    projectImageUrls: string[];
}

export interface DevelopmentViewCluster {
    avgLong: number;
    avgLat: number;
    minLat: number;
    maxLat: number;
    minLong: number;
    maxLong: number;
    count: string | number;
    developmentIds: string[];
    minPrice?: number;
}

export interface DevelopmentView {
    address: string;
    amenities: Amenity[];
    amenityImageUrls: string[];
    capacity: string;
    commonExpense: number;
    completionSeason: string;
    completionYear: number;
    description: string;
    details: DetailType[];
    developers: Developer[];
    developmentPromotions: DevelopmentPromotion[];
    developmentTypes: string[];
    features: string;
    floorPlans: FloorPlanType;
    heroImageUrl: string;
    id: string;
    latitude: number;
    longitude: number;
    maxBed: number;
    maxPrice: number;
    maxSqft: number;
    maxType: string;
    minBed: number;
    minPrice: number;
    minSqft: number;
    minType: string;
    municipality: string;
    name: string;
    ownership: string;
    paymentStructures: string[];
    phase: string;
    policies: Policy[];
    postalCode: string;
    presentationAddress: string;
    priority: string;
    projectImageUrls: string[];
    province: string;
    saleSeason: string;
    saleYear: number;
    status: DevelopmentStatus;
    tags: DevelopmentTag[];
    videoLink: string;
    community: string;
}

export interface SimpleDevelopmentView {
    id: string;
    name: string;
    longitude: number;
    latitude: number;
    developers: Developer[];
    completionYear: number;
    completionSeason: string;
    imageUrls: string[];
    DevelopmentTag: DevelopmentTag[];
    address: string;
    tags: DevelopmentTag[];
}

export enum DevelopmentTag {
    RECOMMENDED = "RECOMMENDED",
    HOTTEST = "HOTTEST",
}

export enum DevelopmentStatus {
    SELLING = "SELLING",
    REGISTRATION = "REGISTRATION",
    SOLDOUT = "SOLDOUT",
}

export interface DevelopmentQuery {
    orderBy: string;
}

export interface DevelopmentState {
    mapConf: MapConf;
    orderBy: string;
    offset?: number;
    hover?: string;
}

export interface ResidentialQuery {
    propertyType: string;
    clusters: number;
    zoom: number;
    isExclusive: boolean;
    beds: number;
    baths: number;
    minPrice: number;
    maxPrice: number;
    minSqft: number;
    maxSqft: number;
    orderBy: string;
    isLease: boolean;
}

export interface ResidentialState {
    mapConf: MapConf;
    query: ResidentialQuery;
    totalCount: number | string;
    offset?: number;
}

export interface PageResponse {
    offset: string;
    pageCount: string;
    totalCount: string;
}

export interface TempTranslation {
    name: string;
    description: string;
    features: string;
    amenities: string[];
}

export interface TempTranslations {
    ids: string[];
    details: { [key: string]: TempTranslation };
}

export interface NearestDevelopment {
    development: DevelopmentView;
    distanceKm: number;
}

export enum EducationTag {
    selling = "selling",
    registration = "registration",
    soldout = "soldout",
    exclusives = "exclusives",
    promotions = "promotions",
    move = "move",
    hot = "hot",
    developer = "developer",
}

export interface Proposal {
    addresses: string;
    capacity?: string;
    direction?: string;
    distance_km?: number;
    lat: number;
    lng: number;
    noticeDate: string;
    noticeId: string;
    propertyType: string;
    proposalText?: string;
    url?: string;
}

export type ProposalsResponse = Proposal[];
