import { Dropdown, Menu, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DownOutlined, TranslationOutlined } from "@ant-design/icons";
import { Spacer, Text } from "src/components";
import { receiveLocaleSuccess } from "src/redux/modules/Locale/actions";
import styled from "styled-components";
import local_en from "../../locale/en.json";
import local_zh from "../../locale/zh.json";
import local_pa from "../../locale/pa.json";
import local_fr from "../../locale/fr.json";
import local_ko from "../../locale/ko.json";
import local_es from "../../locale/es.json";
import local_fa from "../../locale/fa.json";
import { DownIcon } from "src/static";
import intl from "react-intl-universal";
import useGoogleAnalytics, { Category } from "src/utils/GoogleAnalytics";

type LocaleSelectorProps = {
    ismobile: boolean;
    isSelectorColorBlack?: boolean;
};

export const locales = {
    en: local_en,
    zh: local_zh,
    pa: local_pa,
    fr: local_fr,
    ko: local_ko,
    es: local_es,
    fa: local_fa,
};

export const LocaleSelector: React.FC<LocaleSelectorProps> = ({
    ismobile,
    isSelectorColorBlack = true,
}) => {
    const [lang, setLang] = useState<string>("en");
    const dispatch = useDispatch();
    const { sendEvent } = useGoogleAnalytics();

    const menu = (
        <StyledMenu>
            <StyledMenuItem key={0} onClick={() => switchLang("en")}>
                English
            </StyledMenuItem>
            <StyledMenuItem key={1} onClick={() => switchLang("zh")}>
                中文
            </StyledMenuItem>
            <StyledMenuItem key={2} onClick={() => switchLang("pa")}>
                ਪੰਜਾਬੀ
            </StyledMenuItem>
            <StyledMenuItem key={3} onClick={() => switchLang("fr")}>
                Français
            </StyledMenuItem>
            <StyledMenuItem key={4} onClick={() => switchLang("ko")}>
                한국인
            </StyledMenuItem>
            <StyledMenuItem key={5} onClick={() => switchLang("es")}>
                español
            </StyledMenuItem>
            <StyledMenuItem key={6} onClick={() => switchLang("fa")}>
                فارسی
            </StyledMenuItem>
        </StyledMenu>
    );

    const langText = (() => {
        if (lang === 'en') {
            return "ENG";
        }
        if (lang === 'zh') {
            return "中文";
        }
        if (lang === 'pa') {
            return "ਪੰਜਾਬੀ";
        }
        if (lang === 'fr') {
            return "Français";
        }
        if (lang === 'ko') {
            return "한국인";
        }
        if (lang === 'es') {
            return "español";
        }
        if (lang === 'fa') {
            return "فارسی";
        }
    })()

    const mobileButton = (
        <MobileText align="middle">
            {langText}
            <StyledDownIcon />
        </MobileText >
    );

    const desktopButton = (
        <DesktopRow $isSelectorColorBlack={isSelectorColorBlack} align="middle">
            <StyledTranslateIcon $isSelectorColorBlack={isSelectorColorBlack} />
            <Spacer width={window.innerWidth >= 1300 ? 8 : 4} />
            <DesktopText $isSelectorColorBlack={isSelectorColorBlack}>
                {langText}
            </DesktopText>
            <StyledExpandMoreTwoToneIcon $isSelectorColorBlack={isSelectorColorBlack} />
        </DesktopRow>
    );

    const switchLang = (newLang: string) => {
        setLang(newLang);
        dispatch(receiveLocaleSuccess(newLang));
        localStorage.setItem("_MN_LANG", newLang);
        if (newLang === "zh") {
            localStorage.setItem("_MN_isChinese", "true");
        } else {
            localStorage.removeItem("_MN_isChinese");
        }

        intl.init({
            currentLocale: newLang,
            locales: locales,
        });

        sendEvent(Category.DROP_DOWN, `toggle language to: ${newLang}`, "LocaleSelector");
    };

    useEffect(() => {
        setLang(localStorage.getItem("_MN_LANG") || "en");
        const url = new URLSearchParams(window.location.search);
        const lang = url.get("lang");
        if (lang && locales[lang]) {
            switchLang(lang);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Dropdown
                overlay={menu}
                trigger={["hover"]}
                overlayStyle={{ width: "100px", minWidth: "100px" }}
                align={{ offset: [-13, 0] }}
                getPopupContainer={(trigger) =>
                    trigger.parentElement ? trigger.parentElement : trigger
                }
            >
                {ismobile ? mobileButton : desktopButton}
            </Dropdown>
        </>
    );
};

const MobileText = styled(Row)`
    font-family: ${({ theme }) => theme.fontFamily.body};
    ${({ theme }) => theme.styleGroup.body}
    color: ${({ theme }) => theme.color.black};
    font-size: ${({ theme }) => theme.fontSize.bodyM}px;
    cousor: pointer;
`;

// The new text wrapper for DesktopButton
const DesktopText = styled(Text) <{
    $isSelectorColorBlack: boolean;
}>`
    font-family: ${({ theme }) => theme.fontFamily.body};
    font-size: ${({ theme }) => theme.fontSize.bodyS}px;
    font-weight: 500;
    color: ${({ $isSelectorColorBlack, theme }) =>
        $isSelectorColorBlack ? theme.color.black : theme.color.white};
`;

const StyledTranslateIcon = styled(TranslationOutlined) <{
    $isSelectorColorBlack: boolean;
}>`
    svg {
        height: 18px;
        width: 18px;
    }
    color: ${({ $isSelectorColorBlack, theme }) =>
        $isSelectorColorBlack ? theme.color.black : theme.color.white};
    &:hover {
        cursor: pointer;
        color: #ffd362;
    }
`;

const StyledExpandMoreTwoToneIcon = styled(DownOutlined) <{
    $isSelectorColorBlack: boolean;
}>`
    svg {
        height: 14px;
        width: 14px;
    }
    color: ${({ $isSelectorColorBlack, theme }) =>
        $isSelectorColorBlack ? theme.color.black : theme.color.white};
    padding: 0px 8px;
    position: relative;
    top: -2px;
`;

const StyledDownIcon = styled(DownIcon)`
    display: inline;
    margin-left: 3px;
`;

const DesktopRow = styled(Row) <{
    $isSelectorColorBlack: boolean;
}>`
    color: ${({ $isSelectorColorBlack, theme }) =>
        $isSelectorColorBlack ? theme.color.black : theme.color.white};
    padding: 0px 8px;
    &:hover ${StyledTranslateIcon},&:hover ${DesktopText},&:hover ${StyledExpandMoreTwoToneIcon} {
        cursor: pointer;
        color: #ffd362;
    }
    :hover {
        cursor: pointer;
        color: #ffd362;
    }
`;

const StyledMenu = styled(Menu)`
    border-radius: 8px;
`;

const StyledMenuItem = styled(Menu.Item)`
    padding: 8px 16px;
`;
