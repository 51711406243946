import { Nullable } from "src/utils";
import { CONDO, CondoActionType, CondoQuery } from "./types";

export const requestCondo = (query: CondoQuery): CondoActionType => ({
    type: CONDO.REQUEST,
    payload: {
        loading: true,
        query: query,
        error: null,
    },
});

export const receiveCondoSuccess = (data: any, query: Nullable<CondoQuery>): CondoActionType => ({
    type: CONDO.SUCCESS,
    payload: {
        loading: false,
        query: query,
        data: data.condoUnits,
        page: data.page,
        error: null,
    },
});

export const receiveCondoFailure = (error: string): CondoActionType => ({
    type: CONDO.FAILURE,
    payload: {
        loading: false,
        query: null,
        data: null,
        error: error,
    },
});
