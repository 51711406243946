import { Nullable } from "src/utils";

export enum LISTING_CLUSTERS {
    REQUEST = "LISTING_CLUSTERS_REQUEST",
    SUCCESS = "LISTING_CLUSTERS_SUCCESS",
    FAILURE = "LISTING_CLUSTERS_FAILURE",
    ERROR = "LISTING_CLUSTERS_ERROR",
}

export interface ListingClustersQuery {
    filters: string;
    isSold?: boolean;
}

export interface ListingClustersState {
    loading: boolean;
    data?: any;
    error?: Nullable<string>;
    query?: Nullable<ListingClustersQuery>;
}

export interface ListingClustersActionType {
    type: LISTING_CLUSTERS;
    payload: ListingClustersState;
}
