import React from "react";
import styled from "styled-components";
import { ComponentPropsWithoutRef } from "react";
/**
 * A component to hold all styles common between sections.
 */
export type SectionWrapperProps = ComponentPropsWithoutRef<"section"> & {
    id: string;
};

const SectionWrapper = styled.section<SectionWrapperProps>`
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    z-index: 1;
`;

/**
 * A HOC to wrap all sections with the SectionWrapper component
 * @param Section the section component to be wrapped
 */
export const withSectionWrapper = (SectionComponent: React.FC, sectionId: string): React.FC => () => (
    <SectionWrapper id={sectionId}>
        <SectionComponent />
    </SectionWrapper>
);

export default SectionWrapper;
