import { SCHOOL_LISTINGS, School_ListingsActionType } from "./types";

export const schoolListingsReducer = (state = {}, action: School_ListingsActionType) => {
    switch (action.type) {
        case SCHOOL_LISTINGS.REQUEST:
        case SCHOOL_LISTINGS.SUCCESS:
        case SCHOOL_LISTINGS.FAILURE:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};
