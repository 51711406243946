import { Nullable } from "src/utils";
import { PageResponse } from "src/types/listing.type";
import { CondoView } from "src/types/condo.type";

export enum CONDO {
    REQUEST = "CONDO_REQUEST",
    SUCCESS = "CONDO_SUCCESS",
    FAILURE = "CONDO_FAILURE",
    ERROR = "CONDO_ERROR",
}

export interface CondoState {
    loading: boolean;
    data?: CondoView[] | null;
    page?: PageResponse;
    error?: Nullable<string>;
    query?: Nullable<CondoQuery>;
}

export interface CondoActionType {
    type: CONDO;
    payload: CondoState;
}

export interface CondoDataType {
    condoUnits: CondoView[];
    page: {
        pageCount: string;
        offset: string;
    };
}

export interface CondoQuery {
    filters: string;
    isSold?: boolean;
}
